<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-10 py-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <loader v-if="loaders.reading.therapy" />

                <div v-else>

                    <div class="card card-body mb-3" v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">

                        <!-- Alert giorno appuntamento con paziente ancora non arrivato -->
                        <div class="alert m-0" v-if="!form.appointment.totem_presence && day_of_appointment && !appointment_expired">
                            <p class="m-0">
                                Il paziente NON si è ancora presentato
                            </p>
                            <p class="m-0">
                                <small>
                                    Non è piu possibile modificare l'appuntamento nel giorno della visita
                                </small>
                            </p>
                        </div>
                        <!-- Alert paziente in sala di attesa -->
                        <div class="alert bg_room m-0" v-if="form.appointment.totem_presence && !form.appointment.room_presence && !appointment_expired && !form.appointment.urgent">
                            <p class="m-0">
                                Il paziente è in sala d'aspetto
                            </p>
                            <p class="m-0">
                                <small>
                                    Non è piu possibile modificare l'appuntamento
                                </small>
                            </p>
                        </div>
                        <!-- Alert paziente in stanza per fare la visita -->
                        <div class="alert bg_visiting m-0" v-if="form.appointment.totem_presence && form.appointment.room_presence && !form.appointment.patient_pause && !form.appointment.appointment_closed && !appointment_expired && !form.appointment.urgent">
                            <p class="m-0">
                                Il paziente è stato chiamato nella stanza
                            </p>
                            <p class="m-0">
                                <small>
                                    Puoi impostare una terapia per questo paziente
                                </small>
                            </p>
                        </div>
                        <!-- Alert appuntamento terminato -->
                        <div class="alert bg_finish m-0" v-if="form.appointment.appointment_closed">
                            <p class="m-0">
                                La visita medica è terminata in data {{form.appointment.appointment_closed.date | data_slash}} alle {{form.appointment.appointment_closed.date | data_ora}}
                            </p>
                            <p class="m-0" v-if="form.appointment.nurses_end_time">
                               La visita infermieristica è terminata in data {{form.appointment.nurses_end_time | data_slash}} alle {{form.appointment.nurses_end_time | data_ora}}
                            </p>
                            <p class="m-0" v-if="form.appointment.therapy_nurses_end_time">
                               La terapia è terminata in data {{form.appointment.therapy_nurses_end_time | data_slash}} alle {{form.appointment.therapy_nurses_end_time | data_ora}}
                            </p>                           
                            <p class="m-0">
                                <small>
                                    ( La visita per il paziente non può più essere modificata )
                                </small>                                  
                            </p>
                        </div>
                        <!-- Alert paziente in pausa -->
                        <div class="alert bg_pause m-0" v-if="form.appointment.patient_pause && !form.appointment.appointment_closed && !appointment_expired && !form.appointment.urgent">
                            <p class="m-0">
                                Il paziente è in pausa
                            </p>
                            <p class="m-0">
                                <small>
                                    Momentaneamente il paziente non è in stanza ed è in pausa per una determinata motivazione
                                </small>
                            </p>
                        </div>
                        <!-- Alert appuntamento scaduto -->
                        <div class="alert alert-dark m-0" v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment && appointment_expired && form.appointment.totem_presence && !form.appointment.appointment_closed && !form.appointment.urgent">
                            <p class="m-0">
                                L'appuntamento del {{form.appointment.date | data_slash}} è scaduto
                            </p>
                            <p class="m-0">
                                <small>
                                    Non è possibile modificare un appuntamento scaduto che non è stato chiuso
                                </small>
                            </p>
                        </div>
                        <!-- Alert appuntamento scaduto ed il paziente non si è presentato -->
                        <div class="alert alert-dark m-0" v-if="!form.appointment.isTherapyAppointment &&  !form.appointment.isBloodTestAppointment && appointment_expired && !form.appointment.totem_presence && !form.appointment.urgent">
                            <p class="m-0">
                                Il paziente in data {{form.appointment.date | data_slash}} non si è presentato e l'appuntamento è scaduto
                            </p>
                            <p class="m-0">
                                <small>
                                    Non è possibile modificare un appuntamento scaduto per un paziente che non si è presentato
                                </small>
                            </p>
                        </div>
                        <!-- Alert appuntamento scaduto e la visita è terminata -->
                        <!--<div class="alert alert-success m-0" v-if="appointment_expired && form.appointment.totem_presence && form.appointment.appointment_closed && !form.appointment.urgent">
                            <p class="m-0">
                                L'appuntamento del {{form.appointment.date | data_slash}} è stato correttamente chiuso
                            </p>
                            <p class="m-0">
                                <small>
                                    Non è possibile modificare un appuntamento concluso
                                </small>
                            </p>
                        </div>--> <!-- ALERT GIA PRESENTE IN ALTO-->
                        <!-- Alert appuntamento urgente -->
                        <div class="alert alert-danger m-0" v-if="form.appointment.urgent && !form.appointment.appointment_closed">
                            <p class="m-0">
                                APPUNTAMENTO URGENTE
                            </p>
                            <p class="m-0">
                                <small>
                                    QUESTO E' UN APPUNTAMENTO CREATO CON URGENZA, PUOI SOLO GESTIRE LA TERAPIA E LE NOTE SUL PAZIENTE
                                </small>
                            </p>
                        </div>
                        <!-- Alert appuntamento urgente e non chiuso -->
                        <div class="alert alert-danger m-0 mt-3" v-if="form.appointment.urgent && !form.appointment.appointment_closed && appointment_expired">
                            <p class="m-0">
                                APPUNTAMENTO URGENTE <b>SCADUTO</b>
                            </p>
                            <p class="m-0">
                                <small>
                                    QUESTO E' UN APPUNTAMENTO CREATO CON URGENZA CHE NON E' STATO CORRETTAMENTE CHIUSO
                                </small>
                            </p>
                        </div>

                    </div>
                    <div class="card card-body">

                        <!-- Badges e info creatore-->
                        <div class="row">
                            <div class="col-lg-9">
                                
                                <div v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                    <span class="badge badge-success pr-2" v-if="form.appointment.therapy_programId">
                                        Programma teraupetico <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check pl-1 ml-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
                                        </svg>
                                    </span>                                  
                                    <span class="badge badge-info pr-2" v-else-if="!form.appointment.therapy_programId && !form.appointment.urgent">
                                        Appuntamento standard
                                    </span>

                                    <span class="badge badge-danger pr-2" v-else-if="form.appointment.urgent">
                                        Appuntamento urgente
                                    </span>
                                </div>

                                <div v-else-if="form.appointment.isTherapyAppointment">
                                    <span class="badge badge-danger pr-2" v-if="form.appointment.appointments_day_after.urgent">
                                        Terapia urgente
                                    </span>  
                                    <span class="badge badge-info pr-2" v-else>
                                        Terapia standard
                                    </span>                                       
                                </div>
                                <div v-else-if="form.appointment.isBloodTestAppointment">
                                    <span class="badge badge-danger pr-2" v-if="form.appointment.blood_tests.urgent">
                                        Prelievo urgente
                                    </span> 
                                    <span class="badge badge-info pr-2" v-else>
                                        Prelievo standard
                                    </span>                                            
                                </div>                          
                                
                            </div>
                            <div class="col-lg-3 text-right">
                                <p class="text-lowercase mb-0">
                                    <small class="text-muted">
                                        <i>
                                            creato da {{form.appointment.user.username}} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle pl-1 ml-1 border-left" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                            </svg>
                                        </i>
                                    </small>
                                </p>
                                <p class="text-lowercase mb-0" v-if="form.appointment.room_presence && form.appointment.appointment_medical_initializer">
                                    <small class="text-muted">
                                        <i>
                                            gestito da {{form.appointment.appointment_medical_initializer.user.username}} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle pl-1 ml-1 border-left" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                            </svg>
                                        </i>
                                    </small>
                                </p>
                                <p class="text-lowercase mb-0" v-if="form.appointment.appointment_closed">
                                    <small class="text-muted">
                                        <i>
                                            concluso dal Dott. {{form.appointment.appointment_closed.user.name}} {{form.appointment.appointment_closed.user.surname}} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle pl-1 ml-1 border-left" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                            </svg>
                                        </i>
                                    </small>
                                </p>
                            </div>
                        </div>

                        <form @submit.prevent="updateAppointment" id="infoPatient" autocomplete="off">

                            <p class="font-weight-bold m-0">Informazioni paziente</p>

                            <p class="text-muted mb-3">
                                <small>
                                    Riepilogo sulle informazioni del paziente
                                </small>
                            </p>

                            <div class="row">
                                <div v-bind:class="{
                                    'col-lg-3': form.appointment.totem_presence, 
                                    'col-lg-4': !form.appointment.totem_presence
                                }">
                                    <div class="form-group">

                                        <label for="CF">Codice fiscale</label>
                                        <input type="text" class="form-control f-family-monospace text-uppercase" id="CF" v-model="form.user.CF" maxlength="16" disabled>

                                    </div>
                                </div>
                                <div v-bind:class="{
                                    'col-lg-3': form.appointment.totem_presence, 
                                    'col-lg-4': !form.appointment.totem_presence
                                }">
                                    <div class="form-group">

                                        <label for="name">Nome</label>
                                        <input type="text" class="form-control text-uppercase" id="name" v-model="form.user.name" autocomplete="off" disabled>
                                        
                                    </div>
                                </div>
                                <div v-bind:class="{
                                    'col-lg-3': form.appointment.totem_presence, 
                                    'col-lg-4': !form.appointment.totem_presence
                                }">
                                    <div class="form-group">

                                        <label for="surname">Cognome</label>
                                        <input type="text" class="form-control text-uppercase" id="surname" v-model="form.user.surname" disabled>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-3" v-if="form.appointment.totem_presence">
                                    <div class="form-group">

                                        <label for="date_birth">Data di nascita</label>
                                        <input type="date" class="form-control" id="date_birth" v-model="form.user.date_birth" disabled>
                                        
                                    </div>
                                </div>
                            </div>

                        <!-- Campo note paziente -->
                            <div class="form-group">
                                <label for="surnotename">Note</label>
                                <textarea type="text" class="form-control" id="surnotename" :value="form.user.note" placeholder="Note sul paziente ricercato.." disabled></textarea>
                            </div>
                            <b-tabs content-class="border border-top-0 mb-3" v-if="!form.appointment.totem_presence && !form.appointment.urgent && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                <b-tab title="Patologie associate al paziente" active>
                                    <div class="container-fluid p-3">

                                        <div class="row" >
                                            <div v-for="patient_pathology of patients_pathologies" :key="patient_pathology.id" class="col-lg-4">
                                                <div class="card card-tab card-body mb-2">
                                                    <p class="text-uppercase m-0 font-weight-bold">
                                                        Patologia
                                                    </p>
                                                    <p class="text-truncate">
                                                        {{patient_pathology.pathology.title}}
                                                    </p>
                                                    <p class="text-uppercase m-0 font-weight-bold">
                                                        Terapia
                                                    </p>
                                                    <p class="text-truncate">
                                                        {{patient_pathology.id_therapy ? patient_pathology.therapy.title : "Nessuna terapia"}}
                                                    </p>
                                                    <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                        Durata appuntamento
                                                    </p>
                                                    <p class="text-truncate">
                                                        {{patient_pathology.sessions_duration}} minuti
                                                    </p>
                                                    <p class="text-uppercase m-0 font-weight-bold text-truncate">
                                                        Appuntamenti creati in precedenza
                                                    </p>
                                                    <p class="m-0 text-truncate" v-if="patient_pathology.appointment.length > 0">
                                                        {{patient_pathology.appointment.length}} appuntamenti
                                                    </p>
                                                    <p class="m-0 text-truncate text-uppercase text-success" v-else>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-bookmark-star" viewBox="0 0 16 16">
                                                            <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z"/>
                                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                                        </svg>
                                                        
                                                        <strong>
                                                            Primo appuntamento
                                                        </strong>
                                                        
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>

                            <div v-if="!form.appointment.totem_presence && !form.appointment.urgent && form.appointment.isBloodTestAppointment">

                                <div class="row" v-if="!form.appointment.isTherapyAppointment">
                                    <div class="col-lg-6 border-right-lg">

                                        <p class="font-weight-bold m-0">Informazioni visita</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Riepilogo sulle informazioni della visita
                                            </small>
                                        </p>
                                        <div class="form-group" v-if="patients_pathologies.length > 0 && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">

                                            <label for="pathologies">Seleziona una patologia</label>

                                            <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" @input="update_therapies($event.target.value)" :disabled="day_of_appointment || appointment_expired || functionDisabled(1, 'update')">
                                                <option value="" selected disabled>
                                                    Seleziona una patologia
                                                </option>
                                                <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                    {{patient_pathology.pathology.title}}
                                                </option>
                                            </select>

                                            <small class="form-text text-muted">
                                                Seleziona la patologia per l'appuntamento
                                            </small>

                                        </div>

                                        <div class="form-group" v-if="form.appointment.isBloodTestAppointment">
                                            <label for="id_blood_test_type">Seleziona tipo prelievo</label>
                                            <select class="form-control mb-2" id="id_blood_test_type" @change="onChangeBloodTestType($event.target.value)" v-model="form.appointment.blood_tests.id_blood_test_type" :disabled="form.appointment.blood_tests.totem_presence > 0">
                                                <option v-for="blood_test_type of DB.blood_test_types" :key="`bloodtesttypes_${blood_test_type.id}`" :value="blood_test_type.id">
                                                    {{blood_test_type.title}}
                                                </option>
                                            </select> 

                                            <p id="visit_type_help" class="form-text text-muted mb-0">
                                                <small>
                                                   
                                                </small>
                                            </p>

                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">

                                                        <label for="date_appointment">Data</label>

                                                        <input type="date" class="form-control" id="date_blood_test" :disabled="form.appointment.blood_tests.totem_presence > 0" v-model="form.appointment.blood_tests.date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_blood_test">
                                                        
                                                        <small class="form-text text-muted"></small>
                                                        
                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.date.required">
                                                            Data terapia richiesta
                                                        </div>
                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.date.minValue">
                                                            La data deve essere impostata da domani
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">

                                                        <label for="appointment_h_start">Orario inizio</label>

                                                        <input type="time" class="form-control" id="blood_test_h_start" :disabled="form.appointment.blood_tests.totem_presence > 0" v-model="form.appointment.blood_tests.h_start" v-bind:class="{'is-invalid': !$v.form.appointment.blood_tests.h_start.required && $v.form.appointment.blood_tests.h_start.$dirty}">
                                                        
                                                        <small class="form-text text-muted"></small>

                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.h_start.required">
                                                            Orario inizio richiesto
                                                        </div>
                                                        
                                                    </div>
                                                </div> 
                                                <!-- Campo note -->
                                                <div class="col-lg-12 mb-3 text-left">
                                                    <label for="note">Note prelievo</label>
                                                    <textarea type="text" class="form-control" rows="5" id="note_create" v-model="form.appointment.blood_tests.note_create" placeholder="Note prelievo" :disabled="form.appointment.blood_tests.totem_presence > 0"></textarea>
                                                </div>                                                                          
                                            </div>

                                  

                                        </div>               

                                        <div v-if="form.appointment.id_patient_pathology && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                            <div class="form-group">

                                                <label for="visit_types">Seleziona tipo visita</label>

                                                <select class="form-control" id="visit_types" v-on:change="readVisitType($event.target.value)" v-model="form.appointment.id_visit_type" aria-describedby="room_help">
                                                    <option value="" selected disabled>
                                                        Seleziona tipo visita
                                                    </option>
                                                    <option v-for="visit_type of visit_types.DB" :key="`room_${visit_type.id}`" :value="visit_type.id">
                                                        {{visit_type.title}} - ({{visit_type.duration}} minuti)
                                                    </option>
                                                </select>

                                                <p id="visit_type_help" class="form-text text-muted mb-0">
                                                    <small>
                                                        Seleziona il tipo di visita
                                                    </small>
                                                </p>

                                            </div>

                                            <div v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                                <div class="alert alert-danger" v-if="rooms.DB.length < 1">
                                                    <small>
                                                        Ancora nessuna stanza disponibile
                                                    </small>
                                                </div>
                                                <div class="form-group" v-else>

                                                    <label for="rooms">Seleziona una stanza</label>
                                                    <select class="form-control" id="rooms" v-on:change="readRoom($event.target.value)" v-model="form.appointment.id_room" aria-describedby="room_help">
                                                        <option value="" selected disabled>
                                                            Seleziona una stanza
                                                        </option>
                                                        <option v-for="room of rooms.DB" :key="`room_${room.id}`" :value="room.id">
                                                            {{room.number}} - {{room.title}}
                                                        </option>
                                                    </select>                                               

                                                    <p id="room_help" class="form-text text-muted mb-0">
                                                        <small>
                                                            Seleziona la stanza per l'appuntamento.
                                                        </small>
                                                    </p>

                                                </div>
                                            </div>    
                                        </div>

                                        <div v-if="!form.appointment.isBloodTestAppointment && this.form.appointment.isTherapyAppointment">
                                            <div>
                                                <div class="form-group">

                                                    <label for="visit_types">Seleziona durata</label>

                                                    <select class="form-control mb-2" id="id_duration" :disabled="form.appointment.appointments_day_after.id_nurse_closed_therapy " @change="onChangeDuration($event.target.value, 0)" v-model="DB.selected_duration">
                                                        <option value="0">Seleziona</option>
                                                        <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                            {{duration.title}} - {{duration.duration}} min.
                                                        </option>
                                                    </select> 

                                                    <p id="visit_type_help" class="form-text text-muted mb-0">
                                                        <small>
                                                            Seleziona la durata della terapia
                                                        </small>
                                                    </p>

                                                </div>

                                            </div>
                                            <div v-show="DB.selected_duration">
                                                <div class="form-group">

                                                    <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                                    <select class="form-control" id="id_chair" :disabled="form.appointment.appointments_day_after.id_nurse_closed_therapy" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                                        <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                                        <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                            {{chair.title}} {{chair.number}} - {{chair.description}}
                                                        </option>                                                
                                                    </select> 
                                                </div>    
                                            </div>

                                        </div>

                                    </div>

                                        
                                    <div class="col-lg-6" v-show="form.appointment.id_patient_pathology && !appointment_expired && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">

                                        <div v-if="therapies_by_pathology.length > 0">

                                            <p class="font-weight-bold m-0">Storico terapie</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Cronologia delle terapie associate al paziente
                                                </small>
                                            </p>

                                            <div class="row font-weight-bold">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Data
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Vecchia terapia
                                                    </p>
                                                </div>
                                                <!--<div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Motivazione
                                                    </p>
                                                </div>-->
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Utente
                                                    </p>
                                                </div>
                                                <div class="col-1"></div>
                                                <div class="col-1"></div>
                                            </div>
                                            
                                            <p v-if="form.patients_pathologies.therapy_cronologies.length < 1">
                                                <small class="text-warning font-weight-bold">
                                                    Cronologia assente..
                                                </small>
                                            </p>
                                            
                                            <div class="row" v-for="therapy_cronology of form.patients_pathologies.therapy_cronologies" :key="therapy_cronology.id">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em f-family-monospace">
                                                        {{therapy_cronology.date | data_slash}}
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.title_old_therapy ? therapy_cronology.title_old_therapy : "Nessuna terapia"}}
                                                    </p>
                                                </div>
                                                <!--<div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </p>
                                                </div>-->
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.modifier_user}}
                                                    </p>
                                                </div>
                                                <div class="col-1">
                                                    <button class="btn buttonDelete" :id="'tooltip-target-' + therapy_cronology.id">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>                                                  
                                                    </button >
                                                    <b-tooltip :target="'tooltip-target-' + therapy_cronology.id" triggers="hover">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </b-tooltip>      
                                                </div>                                                
                                                <div class="col-1">
                                                    <button class="btn buttonDelete" @click="delete_therapy(therapy_cronology.id)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                                        </svg>                                          
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="form-group">

                                                <label for="id_therapy">Terapia attualmente seguita</label>

                                                    <select id="id_therapy" class="form-control" v-model="form.patients_pathologies.id_therapy" @input="change_title_new_therapy($event.target.value)" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}" :disabled="functionDisabled(10, 'update')">
                                                        <option :value="null">
                                                            Nessuna terapia
                                                        </option>
                                                        <option v-for="therapy_by_pathology in therapies_by_pathology" v-bind:key="`therapy_by_pathology_${therapy_by_pathology.id}`" v-bind:value="therapy_by_pathology.therapy.id">
                                                            {{therapy_by_pathology.therapy.title}}
                                                        </option>
                                                    </select>

                                                    <small class="form-text text-muted">
                                                        Puoi modificare la terapia selezionandone una dalla lista
                                                    </small>

                                            </div>
                                            <div class="form-group">

                                                <label for="description_edit">Motivazioni sulla modifica</label>

                                                <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}" :disabled="functionDisabled(10, 'update')"></textarea>

                                                <small class="form-text text-muted">
                                                    Fornisci una breve spiegazione sulla terapia impostata
                                                </small>
                                                <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                                    {{validation.ko_update_therapy}}
                                                </div>
                                                <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                                    {{validation.ok_update_therapy}}
                                                </div>
                                                <div class="invalid-feedback" v-show="!$v.form.form_edit_therapy.description.maxLength">
                                                    La motivazioni devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>

                                            <button class="btn btn-ifo btn-sm" type="button" :disabled="functionDisabled(10, 'update') || loaders.updating || $v.form.form_edit_therapy.$invalid" @click="update_therapy">
                                                Modifica terapia
                                            </button>

                                        </div>

                                        <div class="alert alert-danger" v-else>
                                            <u>ATTENZIONE</u>! Per questa patologia non sono state associate delle terapie
                                        </div>

                                    </div>

                                    <div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && form.appointment.isBloodTestAppointment">
                                        <div v-if="moment(form.appointment.blood_tests.date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Invita paziente nella sala prelievo</p>

                                            <div class="form-group">

                                                <label for="patient_room_therapy_description">Note</label>
                                                <textarea class="form-control" id="patient_room_therapy_description" @input="update_patient_room_blood_test_description($event.target.value)" :disabled="form.appointment.blood_tests.room_presence > 0" :value="form.appointment.blood_tests.patient_room_blood_test_description" rows="3" maxlength="700"></textarea>
                                                
                                                <small class="form-text text-muted">
                                                    Le note sull'invio nella sala prelievo sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>  
                                            
                                            <div v-if="form.appointment.blood_tests">
                                                <div v-if="form.appointment.blood_tests.totem_presence == 0 && form.appointment.blood_tests.room_presence == 0">
                                                    <div class="alert alert-warning">
                                                        Il paziente non è ancora in sala di attesa 
                                                    </div>  
                                                </div>

                                                <div v-else-if="form.appointment.blood_tests.totem_presence == 1 && form.appointment.blood_tests.room_presence == 0">
                                                    <div class="alert bg_room effetto-lampeggia-loop">
                                                        Il paziente è in sala di attesa
                                                    </div> 
                                                    <button class="btn btn-ifo" type="button" @click="inviteForBloodTestRoom(form.appointment.blood_tests.id, update_patient_room_blood_test_description_value, form.appointment.blood_tests.urgent)" :disabled="!functionSpecialEnabled(7)"
                                                        v-if="functionSpecialEnabled(7) && !form.appointment.blood_tests.room_blood_test_called">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                            <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                        </svg>                                                      
                                                        Invita il paziente per il prelievo
                                                    </button>                                                 
                                                </div> 
                                                <div v-else-if="form.appointment.blood_tests.totem_presence == 1 && form.appointment.blood_tests.room_presence == 1 && !form.appointment.blood_tests.id_nurse_closed_blood_test">
                                                    <button class="btn btn-ifo" type="button" @click="bloodtestNurseEnd(form.appointment.blood_tests.id, form.appointment.blood_tests.patient_room_blood_test_description)" :disabled="!functionSpecialEnabled(7)"
                                                        v-if="functionSpecialEnabled(7) & form.appointment.blood_tests.room_blood_test_called">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                            <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                        </svg>  
                                                        Termina prelievo
                                                    </button>                                                
                                                </div>       
                                            </div>
                                            
                
                                        </div>             

                                    </div> 
                                    
                                    <!--<div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && form.appointment.isBloodTestAppointment && form.appointment.blood_tests.urgent">
                                        <div v-if="moment(form.appointment.blood_tests.date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                            <p class="font-weight-bold m-0">Termina prelievo</p>

                                            <div class="form-group">
                                                <label for="patient_room_blood_test_description">Note prelievo</label>
                                                <textarea class="form-control" id="patient_room_blood_test_description" @input="update_patient_room_blood_test_description($event.target.value)" :disabled="form.appointment.blood_tests.id_nurse_closed_blood_test > 0" :value="form.appointment.blood_tests.patient_room_blood_test_description" rows="3" maxlength="700"></textarea>
                                                
                                                <small class="form-text text-muted">
                                                    Le note sull'invio nella sala prelievo sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>  
                                            
                                            <div v-if="!form.appointment.blood_tests.id_nurse_closed_blood_test">
                                                <button class="btn btn-ifo" type="button" @click="bloodtestNurseEnd(form.appointment.blood_tests.id, update_patient_room_blood_test_description_value)" :disabled="!functionSpecialEnabled(7)"
                                                    v-if="functionSpecialEnabled(7)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                        <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                    </svg>  
                                                    Termina prelievo
                                                </button>                                                
                                            </div>
                                        </div>             

                                    </div> -->                                    

                                </div>

                            </div>
                            <div v-if="!form.appointment.urgent && form.appointment.isBloodTestAppointment">

                                <div class="row" v-if="!form.appointment.isTherapyAppointment && form.appointment.blood_tests.totem_presence">
                                    <div class="col-lg-6 border-right-lg">

                                        <p class="font-weight-bold m-0">Informazioni visita</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Riepilogo sulle informazioni della visita
                                            </small>
                                        </p>
                                        <div class="form-group" v-if="patients_pathologies.length > 0 && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">

                                            <label for="pathologies">Seleziona una patologia</label>

                                            <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" @input="update_therapies($event.target.value)" :disabled="day_of_appointment || appointment_expired || functionDisabled(1, 'update')">
                                                <option value="" selected disabled>
                                                    Seleziona una patologia
                                                </option>
                                                <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                    {{patient_pathology.pathology.title}}
                                                </option>
                                            </select>

                                            <small class="form-text text-muted">
                                                Seleziona la patologia per l'appuntamento
                                            </small>

                                        </div>

                                        <div class="form-group" v-if="form.appointment.isBloodTestAppointment">
                                            <label for="id_blood_test_type">Seleziona tipo prelievo</label>
                                            <select class="form-control mb-2" id="id_blood_test_type" @change="onChangeBloodTestType($event.target.value)" v-model="form.appointment.blood_tests.id_blood_test_type" :disabled="form.appointment.blood_tests.totem_presence > 0">
                                                <option value="0">Seleziona</option>
                                                <option v-for="blood_test_type of DB.blood_test_types" :key="`bloodtesttypes_${blood_test_type.id}`" :value="blood_test_type.id">
                                                    {{blood_test_type.title}}
                                                </option>
                                            </select> 

                                            <p id="visit_type_help" class="form-text text-muted mb-0">
                                                <small>
                                                
                                                </small>
                                            </p>

                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">

                                                        <label for="date_appointment">Data</label>

                                                        <input type="date" class="form-control" id="date_blood_test" :disabled="form.appointment.blood_tests.totem_presence > 0" v-model="form.appointment.blood_tests.date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_blood_test">
                                                        
                                                        <small class="form-text text-muted"></small>
                                                        
                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.date.required">
                                                            Data terapia richiesta
                                                        </div>
                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.date.minValue">
                                                            La data deve essere impostata da domani
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">

                                                        <label for="appointment_h_start">Orario inizio</label>

                                                        <input type="time" class="form-control" id="blood_test_h_start" :disabled="form.appointment.blood_tests.totem_presence > 0" v-model="form.appointment.blood_tests.h_start" v-bind:class="{'is-invalid': !$v.form.appointment.blood_tests.h_start.required && $v.form.appointment.blood_tests.h_start.$dirty}">
                                                        
                                                        <small class="form-text text-muted"></small>

                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.blood_tests.h_start.required">
                                                            Orario inizio richiesto
                                                        </div>
                                                        
                                                    </div>
                                                </div> 
                                                <!-- Campo note -->
                                                <div class="col-lg-12 mb-3 text-left">
                                                    <label for="note">Note prelievo</label>
                                                    <textarea type="text" class="form-control" rows="5" id="note_create" v-model="form.appointment.blood_tests.note_create" placeholder="Note prelievo" :disabled="form.appointment.blood_tests.totem_presence > 0"></textarea>
                                                </div>                                                                          
                                            </div>

                                

                                        </div>               

                                        <div v-if="form.appointment.id_patient_pathology && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                            <div class="form-group">

                                                <label for="visit_types">Seleziona tipo visita</label>

                                                <select class="form-control" id="visit_types" v-on:change="readVisitType($event.target.value)" v-model="form.appointment.id_visit_type" aria-describedby="room_help">
                                                    <option value="" selected disabled>
                                                        Seleziona tipo visita
                                                    </option>
                                                    <option v-for="visit_type of visit_types.DB" :key="`room_${visit_type.id}`" :value="visit_type.id">
                                                        {{visit_type.title}} - ({{visit_type.duration}} minuti)
                                                    </option>
                                                </select>

                                                <p id="visit_type_help" class="form-text text-muted mb-0">
                                                    <small>
                                                        Seleziona il tipo di visita
                                                    </small>
                                                </p>

                                            </div>

                                            <div v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                                <div class="alert alert-danger" v-if="rooms.DB.length < 1">
                                                    <small>
                                                        Ancora nessuna stanza disponibile
                                                    </small>
                                                </div>
                                                <div class="form-group" v-else>

                                                    <label for="rooms">Seleziona una stanza</label>
                                                    <select class="form-control" id="rooms" v-on:change="readRoom($event.target.value)" v-model="form.appointment.id_room" aria-describedby="room_help">
                                                        <option value="" selected disabled>
                                                            Seleziona una stanza
                                                        </option>
                                                        <option v-for="room of rooms.DB" :key="`room_${room.id}`" :value="room.id">
                                                            {{room.number}} - {{room.title}}
                                                        </option>
                                                    </select>                                               

                                                    <p id="room_help" class="form-text text-muted mb-0">
                                                        <small>
                                                            Seleziona la stanza per l'appuntamento.
                                                        </small>
                                                    </p>

                                                </div>
                                            </div>    
                                        </div>

                                        <div v-if="!form.appointment.isBloodTestAppointment && this.form.appointment.isTherapyAppointment">
                                            <div>
                                                <div class="form-group">

                                                    <label for="visit_types">Seleziona durata</label>

                                                    <select class="form-control mb-2" id="id_duration" :disabled="form.appointment.appointments_day_after.id_nurse_closed_therapy " @change="onChangeDuration($event.target.value, 0)" v-model="DB.selected_duration">
                                                        <option value="0">Seleziona</option>
                                                        <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                            {{duration.title}} - {{duration.duration}} min.
                                                        </option>
                                                    </select> 

                                                    <p id="visit_type_help" class="form-text text-muted mb-0">
                                                        <small>
                                                            Seleziona la durata della terapia
                                                        </small>
                                                    </p>

                                                </div>

                                            </div>
                                            <div v-show="DB.selected_duration">
                                                <div class="form-group">

                                                    <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                                    <select class="form-control" id="id_chair" :disabled="form.appointment.appointments_day_after.id_nurse_closed_therapy" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                                        <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                                        <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                            {{chair.title}} {{chair.number}} - {{chair.description}}
                                                        </option>                                                
                                                    </select> 
                                                </div>    
                                            </div>

                                        </div>

                                    </div>

                                        
                                    <div class="col-lg-6" v-show="form.appointment.id_patient_pathology && !appointment_expired && !form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">

                                        <div v-if="therapies_by_pathology.length > 0">

                                            <p class="font-weight-bold m-0">Storico terapie</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Cronologia delle terapie associate al paziente
                                                </small>
                                            </p>

                                            <div class="row font-weight-bold">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Data
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Vecchia terapia
                                                    </p>
                                                </div>
                                                <!--<div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Motivazione
                                                    </p>
                                                </div>-->
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Utente
                                                    </p>
                                                </div>
                                                <div class="col-1"></div>
                                                <div class="col-1"></div>
                                            </div>
                                            
                                            <p v-if="form.patients_pathologies.therapy_cronologies.length < 1">
                                                <small class="text-warning font-weight-bold">
                                                    Cronologia assente..
                                                </small>
                                            </p>
                                            
                                            <div class="row" v-for="therapy_cronology of form.patients_pathologies.therapy_cronologies" :key="therapy_cronology.id">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em f-family-monospace">
                                                        {{therapy_cronology.date | data_slash}}
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.title_old_therapy ? therapy_cronology.title_old_therapy : "Nessuna terapia"}}
                                                    </p>
                                                </div>
                                                <!--<div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </p>
                                                </div>-->
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.modifier_user}}
                                                    </p>
                                                </div>
                                                <div class="col-1">
                                                    <button class="btn buttonDelete" :id="'tooltip-target-' + therapy_cronology.id">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>                                                  
                                                    </button >
                                                    <b-tooltip :target="'tooltip-target-' + therapy_cronology.id" triggers="hover">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </b-tooltip>      
                                                </div>                                                
                                                <div class="col-1">
                                                    <button class="btn buttonDelete" @click="delete_therapy(therapy_cronology.id)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                                        </svg>                                          
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="form-group">

                                                <label for="id_therapy">Terapia attualmente seguita</label>

                                                    <select id="id_therapy" class="form-control" v-model="form.patients_pathologies.id_therapy" @input="change_title_new_therapy($event.target.value)" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}" :disabled="functionDisabled(10, 'update')">
                                                        <option :value="null">
                                                            Nessuna terapia
                                                        </option>
                                                        <option v-for="therapy_by_pathology in therapies_by_pathology" v-bind:key="`therapy_by_pathology_${therapy_by_pathology.id}`" v-bind:value="therapy_by_pathology.therapy.id">
                                                            {{therapy_by_pathology.therapy.title}}
                                                        </option>
                                                    </select>

                                                    <small class="form-text text-muted">
                                                        Puoi modificare la terapia selezionandone una dalla lista
                                                    </small>

                                            </div>
                                            <div class="form-group">

                                                <label for="description_edit">Motivazioni sulla modifica</label>

                                                <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}" :disabled="functionDisabled(10, 'update')"></textarea>

                                                <small class="form-text text-muted">
                                                    Fornisci una breve spiegazione sulla terapia impostata
                                                </small>
                                                <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                                    {{validation.ko_update_therapy}}
                                                </div>
                                                <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                                    {{validation.ok_update_therapy}}
                                                </div>
                                                <div class="invalid-feedback" v-show="!$v.form.form_edit_therapy.description.maxLength">
                                                    La motivazioni devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>

                                            <button class="btn btn-ifo btn-sm" type="button" :disabled="functionDisabled(10, 'update') || loaders.updating || $v.form.form_edit_therapy.$invalid" @click="update_therapy">
                                                Modifica terapia
                                            </button>

                                        </div>

                                        <div class="alert alert-danger" v-else>
                                            <u>ATTENZIONE</u>! Per questa patologia non sono state associate delle terapie
                                        </div>

                                    </div>

                                    <div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && form.appointment.isBloodTestAppointment">
                                        <div v-if="moment(form.appointment.blood_tests.date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Invita paziente nella sala prelievo</p>

                                            <div class="form-group">

                                                <label for="patient_room_therapy_description">Note</label>
                                                <textarea class="form-control" id="patient_room_therapy_description" @input="update_patient_room_blood_test_description($event.target.value)" :disabled="form.appointment.blood_tests.room_presence > 0" :value="form.appointment.blood_tests.patient_room_blood_test_description" rows="3" maxlength="700"></textarea>
                                                
                                                <small class="form-text text-muted">
                                                    Le note sull'invio nella sala prelievo sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>  
                                            
                                            <div v-if="form.appointment.blood_tests">
                                                <div v-if="form.appointment.blood_tests.totem_presence == 0 && form.appointment.blood_tests.room_presence == 0">
                                                    <div class="alert alert-warning">
                                                        Il paziente non è ancora in sala di attesa 
                                                    </div>  
                                                </div>

                                                <div v-else-if="form.appointment.blood_tests.totem_presence == 1 && form.appointment.blood_tests.room_presence == 0">
                                                    <div class="alert bg_room effetto-lampeggia-loop">
                                                        Il paziente è in sala di attesa
                                                    </div> 
                                                    <button class="btn btn-ifo" type="button" @click="inviteForBloodTestRoom(form.appointment.blood_tests.id, update_patient_room_blood_test_description_value, form.appointment.blood_tests.urgent)" :disabled="!functionSpecialEnabled(7)"
                                                        v-if="functionSpecialEnabled(7) && !form.appointment.blood_tests.room_blood_test_called">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                            <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                        </svg>                                                      
                                                        Invita il paziente per il prelievo
                                                    </button>                                                 
                                                </div> 
                                                <div v-else-if="form.appointment.blood_tests.totem_presence == 1 && form.appointment.blood_tests.room_presence == 1 && !form.appointment.blood_tests.id_nurse_closed_blood_test">
                                                    <button class="btn btn-ifo" type="button" @click="bloodtestNurseEnd(form.appointment.blood_tests.id, form.appointment.blood_tests.patient_room_blood_test_description)" :disabled="!functionSpecialEnabled(7)"
                                                        v-if="functionSpecialEnabled(7) & form.appointment.blood_tests.room_blood_test_called">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                            <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                        </svg>  
                                                        Termina prelievo
                                                    </button>                                                
                                                </div>       
                                            </div>
                                            

                                        </div> 
                                        
                                        <!-- Semaforo-->
                                        <div class="px-3 col-lg-12" v-if="form.appointment.isBloodTestAppointment && !form.appointment.urgent && !form.appointment.blood_tests.urgent && form.appointment.blood_tests.nurse_closed_blood_test_date">
                                            <p class="font-weight-bold m-0">Tempistiche</p>
                                            <hr>
                                            <div class="col-lg-12 text-center">

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo di attesa nella sala di aspetto
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{bloodtest_time_finish_waiting}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div> 

                                            </div>
                                            <div class="mt-4 col-lg-12 text-center">

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo trascorso dalla data appuntamento alla chiamata nella stanza
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace" >
                                                        {{bloodtest_time_finish_waiting_room}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div>                                                  

                                            </div>                        
                                        </div>                                        

                                    </div> 
                                    
                                    <!--<div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && form.appointment.isBloodTestAppointment && form.appointment.blood_tests.urgent">
                                        <div v-if="moment(form.appointment.blood_tests.date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                            <p class="font-weight-bold m-0">Termina prelievo</p>

                                            <div class="form-group">
                                                <label for="patient_room_blood_test_description">Note prelievo</label>
                                                <textarea class="form-control" id="patient_room_blood_test_description" @input="update_patient_room_blood_test_description($event.target.value)" :disabled="form.appointment.blood_tests.id_nurse_closed_blood_test > 0" :value="form.appointment.blood_tests.patient_room_blood_test_description" rows="3" maxlength="700"></textarea>
                                                
                                                <small class="form-text text-muted">
                                                    Le note sull'invio nella sala prelievo sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>  
                                            
                                            <div v-if="!form.appointment.blood_tests.id_nurse_closed_blood_test">
                                                <button class="btn btn-ifo" type="button" @click="bloodtestNurseEnd(form.appointment.blood_tests.id, update_patient_room_blood_test_description_value)" :disabled="!functionSpecialEnabled(7)"
                                                    v-if="functionSpecialEnabled(7)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                        <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                                                    </svg>  
                                                    Termina prelievo
                                                </button>                                                
                                            </div>
                                        </div>             

                                    </div> -->                                    

                                </div>

                            </div> 
                            <div v-else-if="!form.appointment.urgent && form.appointment.isTherapyAppointment">

                                <div class="row" v-if="form.appointment.appointments_day_after.totem_presence">
                                    <!-- Form di modifica-->
                                     
                                    <div class="col-lg-6" v-bind:class="{'col-lg-12': form.appointment.appointments_day_after.urgent || !form.appointment.appointments_day_after.therapy_end_time}">

                                        <p class="font-weight-bold m-0">Informazioni visita</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Riepilogo sulle informazioni della visita
                                            </small>
                                        </p>
                                        
                                        <div class="row">
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="actual_room">Stanza</label>
                                                    <input type="text" class="form-control font-weight-bold" id="actual_room" :value="form.user.number" disabled>
                                                </div>
                                            </div>
                                            <!--v-show="!form.appointment.urgent"-->
                                            <div class="col-lg-6 col-xl-4" v-show="form.appointment.totem_number">
                                                <div class="form-group text-truncate">
                                                    <label for="totem_number">Codice identificativo</label>
                                                    <input type="text" class="form-control font-weight-bold bg-danger text-white" id="totem_number" :value="form.appointment.totem_number" disabled>
                                                </div>
                                            </div>  
                                        </div>
                                        <div class="row">                                            
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="date_appointment">Data appuntamento</label>
                                                    <input type="date" class="form-control" id="date_appointment" v-model="form.appointment.date" disabled>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="time_appointment">Inizio appuntamento</label>
                                                    <input type="time" v-model="form.appointment.h_start" class="form-control" id="time_appointment" disabled>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xl-4" v-if="!form.appointment.urgent">
                                                <div class="form-group m-0 text-truncate">

                                                    <label for="time_appointment">Orario arrivo</label>
                                                    <input type="time" v-model="form.appointment.totem_presence_arrival" class="form-control font-weight-bold" id="time_appointment" disabled>

                                                    <small class="form-text text-muted effetto-lampeggia" v-show="form.appointment.totem_presence_arrival && !form.appointment.appointment_closed">
                                                        {{time_difference}}
                                                    </small>

                                                </div>
                                            </div>
                                        </div>  
                                            
                                        <div class="form-group">

                                            <label for="pathologies">Patologia seguita</label>

                                            <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" disabled>
                                                <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                    {{patient_pathology.pathology ? patient_pathology.pathology.title : "-"}}
                                                </option>
                                            </select>                                 

                                        </div>

                                        <hr>
                                    
                                        <div v-if="therapies_by_pathology.length > 0">

                                            <p class="font-weight-bold m-0">Gestione della terapia</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Gestisci e controlla la cronologia delle terapie associate al paziente
                                                </small>
                                            </p>

                                            <div class="row font-weight-bold">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Data
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Vecchia terapia
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Motivazione
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Utente
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <p v-if="form.patients_pathologies.therapy_cronologies.length < 1">
                                                <small class="text-warning font-weight-bold">
                                                    Ancora nessuna terapia associata..
                                                </small>
                                            </p>
                                            
                                            <div class="row" v-for="therapy_cronology of form.patients_pathologies.therapy_cronologies" :key="`therapy_cronology_${therapy_cronology.id}`">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em f-family-monospace">
                                                        {{therapy_cronology.date | data_slash}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.title_old_therapy ? therapy_cronology.title_old_therapy : "Nessuna terapia"}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.modifier_user}}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="form-group">

                                                <label for="id_therapy">Terapia attualmente seguita</label>

                                                <select id="id_therapy" class="form-control" v-model="form.patients_pathologies.id_therapy" @input="change_title_new_therapy($event.target.value)" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}">
                                                    <option :value="null">
                                                        Nessuna terapia
                                                    </option>
                                                    <option v-for="therapy_by_pathology in therapies_by_pathology" v-bind:key="`therapy_by_pathology_${therapy_by_pathology.id}`" v-bind:value="therapy_by_pathology.therapy.id">
                                                        {{therapy_by_pathology.therapy.title}}
                                                    </option>
                                                </select>

                                                <small class="form-text text-muted">
                                                    Puoi modificare la terapia selezionandone una dalla lista
                                                </small>

                                            </div>
                                            <div class="form-group">

                                                <label for="description_edit">Motivazioni sulla modifica</label>

                                                <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}"></textarea>

                                                <small class="form-text text-muted">
                                                    Fornisci una breve spiegazione sulla terapia impostata
                                                </small>
                                                <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                                    {{validation.ko_update_therapy}}
                                                </div>
                                                <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                                    {{validation.ok_update_therapy}}
                                                </div>
                                                <div class="invalid-feedback" v-show="!$v.form.form_edit_therapy.description.maxLength">
                                                    La motivazioni devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>

                                            <button class="btn btn-ifo btn-sm" type="button" :disabled="functionDisabled(10, 'update') || loaders.updating || $v.form.form_edit_therapy.$invalid" @click="update_therapy">
                                                Modifica terapia
                                            </button>

                                        </div>

                                        <div class="alert alert-danger" v-else>
                                            <u>ATTENZIONE</u>! Per questa patologia non sono state associate delle terapie
                                        </div>

                                        <!-- Campo note -->
                                        <div class="mb-3">
                                            <hr>
                                            <p class="font-weight-bold mb-2">Note appuntamento</p>
                                            <div class="form-group">
                                                <textarea type="text" class="form-control" rows="5" id="note_create" v-model="form.appointment.note_create" placeholder="Note appuntamento" disabled></textarea>
                                            </div>      
                                        </div>                                         

                                        <!-- Note sulla visita -->
                                        <div v-if="form.appointment.room_presence">

                                            <hr>

                                            <p class="font-weight-bold m-0">Gestione visita</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Aggiungi ulteriori informazioni sulla visita
                                                </small>
                                            </p>

                                            <div class="form-group">

                                                <label for="appointment_closed_note">
                                                    Note sulla visita
                                                </label>

                                                <textarea class="form-control" id="appointment_closed_note" maxlength="700" v-model.trim="$v.form.closing.note.$model" rows="3" v-bind:class="{'is-invalid': !$v.form.closing.note.maxLength}" :disabled="form.appointment.appointment_closed || appointment_expired"></textarea>
                                                
                                                <small class="form-text text-muted" v-show="form.closing.note.length > 0">
                                                    {{form.closing.note.length}}/700 Caratteri
                                                </small>
                                                <small class="form-text text-muted">
                                                    Al click della bottone "Termina visita" queste note sulla visita verranno storicizzate
                                                </small>
                                                
                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>

                                            <div class="alert alert-success" v-if="form.appointment.appointment_closed">
                                                Visita medica conclusa dal Dott. {{form.appointment.appointment_closed.user.name}} {{form.appointment.appointment_closed.user.surname}}
                                            </div>

                                        </div>
                                        <div v-show="validation.ko" class="alert alert-danger text-center">
                                            {{validation.ko}}
                                        </div>
                                        <div class="alert alert-warning" v-if="form.appointment.patient_pause">
                                            Non puoi terminare una visita quando il paziente è in pausa
                                        </div>

                                        <div v-if="form.appointment.urgent">
                                            <div class="text-center">
                                                <button class="btn btn-ifo" type="button" :disabled="loaders.closing || loaders.deleting || $v.form.closing.$invalid" @click="termina_visita" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(2) && !appointment_expired && !form.appointment.appointment_closed && !form.appointment.patient_pause">
                                                
                                                    <span v-show="!loaders.closing">
                                                        Termina visita
                                                    </span>

                                                    <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                                </button>
                                            </div>                                              
                                        </div>

                                        <div v-else>

                                            <button class="btn btn-ifo" type="button" :disabled="loaders.closing || loaders.deleting || $v.form.closing.$invalid" @click="termina_visita" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(2) && !appointment_expired && !form.appointment.appointment_closed && !form.appointment.patient_pause">
                                            
                                                <span v-show="!loaders.closing">
                                                    Termina visita
                                                </span>

                                                <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                            </button>
                                        </div>


                                        <!-- Note sulla visita -->
                                        <div v-if="form.appointment.room_presence && form.appointment.sent_nurses && !form.appointment.taking_charge_start_time">

                                            <hr>

                                            <p class="font-weight-bold m-0">Gestione visita infermieristica</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Aggiungi ulteriori informazioni sulla visita
                                                </small>
                                            </p>

                                            <div class="form-group">

                                                <label for="note_closed_nurses">
                                                    Note sulla visita infermieristica
                                                </label>

                                                <textarea class="form-control" id="note_closed_nurses" maxlength="700" v-model.trim="$v.form.appointment.note_closed_nurses.$model" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.note_closed_nurses.maxLength}" :disabled="form.appointment.nurses_end_time || form.appointment.sent_verify  || form.appointment.taking_charge_start_time || appointment_expired"></textarea>
                                                
                                            <!--<small class="form-text text-muted" v-show="form.appointment.note_closed_nurses.length > 0">
                                                    {{form.appointment.note_closed_nurses.length}}/700 Caratteri
                                                </small>--> 
                                                <small class="form-text text-muted">
                                                    Al click della bottone "Termina visita" queste note sulla visita verranno storicizzate
                                                </small>
                                                
                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>
                                            <div v-show="validation.ko" class="alert alert-danger text-center">
                                                {{validation.ko}}
                                            </div>
                                            <div class="alert alert-warning" v-if="form.appointment.patient_pause  && form.appointment.sent_nurses">
                                                Non puoi terminare una visita quando il paziente è in pausa
                                            </div>

                                            <div class="alert alert-success" v-if="form.appointment.id_nurse_closed">
                                                Visita infermieristica conclusa dal Dott. {{form.appointment.nurseUser}} 
                                            </div>                                            

                                            <button class="btn btn-ifo" type="button" :disabled="loaders.closing_nurses || $v.form.closing.$invalid" @click="termina_visita_infermieristica" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(15) && !appointment_expired && form.appointment.appointment_closed && !form.appointment.patient_pause && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.sent_verify && !form.appointment.taking_charge_start_time">

                                                <span v-show="!loaders.closing_nurses">
                                                    Termina visita
                                                </span>

                                                <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                            </button>
                                        </div>
                                    </div>
                                    <!-- Semaforo-->
                                    <div class="px-3 border-left-lg col-lg-6" v-if="!form.appointment.appointments_day_after.urgent && form.appointment.appointments_day_after.therapy_end_time">
                                        <p class="font-weight-bold m-0">Tempistiche</p>
                                        <hr>
                                        <div class="col-lg-12 text-center">

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Tempo di attesa nella sala di aspetto
                                                </small>
                                            </p>

                                            <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                <h1 class="mb-1 f-family-monospace">
                                                    {{therapy_time_finish_waiting}}
                                                </h1>
                                                <p class="text-center">

                                                    <b class="text-uppercase">
                                                        Minuti
                                                    </b>

                                                </p>
                                            </div> 

                                        </div>
                                        <div class="mt-4 col-lg-12 text-center">

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Tempo trascorso dalla data appuntamento alla chiamata nella stanza
                                                </small>
                                            </p>

                                            <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                <h1 class="mb-1 f-family-monospace" >
                                                    {{therapy_time_finish_waiting_room}}
                                                </h1>
                                                <p class="text-center">

                                                    <b class="text-uppercase">
                                                        Minuti
                                                    </b>

                                                </p>
                                            </div>                                                  

                                        </div>                        
                                    </div>


                                    <div class="px-3 border-left-lg" v-if="!form.appointment.urgent && !form.appointment.isTherapyAppointment && !form.appointment.isBloodtestAppointment" v-bind:class="{'col-lg-6': !form.appointment.room_presence, 'col-lg-3 border-right-lg': form.appointment.room_presence}">
                                        <div class="d-flex h-100" v-if="form.appointment.totem_presence && !form.appointment.room_presence && !appointment_expired">
                                            <div class="m-auto">

                                                <div class="d-flex">

                                                    <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" :style="time_delay.type == 'ore' ? `border-color: var(--wait)` : `border-color: var(--pause)`">
                                                        <h1 class="effetto-lampeggia mb-1">
                                                            {{time_delay.ago}}
                                                        </h1>
                                                        <p class="effetto-lampeggia text-center">

                                                            <b class="text-uppercase" :style="time_delay.type == 'ore' ? `color: var(--wait)` : `color: var(--pause)`">
                                                                {{time_delay.type}}
                                                            </b>

                                                            <br>

                                                            <small class="text-muted">
                                                                <i>
                                                                    Attesa..
                                                                </i>
                                                            </small>

                                                        </p>
                                                    </div>

                                                </div>

                                                <p class="text-muted text-center">
                                                    <i>
                                                        <small>
                                                            Il paziente è in sala di aspetto, quando sei pronto puoi invitarlo
                                                        </small>
                                                    </i>
                                                </p>

                                            </div>
                                        </div>
                                        <div v-if="form.appointment.room_presence">

                                            <p class="font-weight-bold m-0">Stato della visita</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Semaforo che indica lo stato della visita
                                                </small>
                                            </p>

                                            <div class="semaforo">
                                                <div class="luce d-flex" :style="form.appointment.room_presence && !form.appointment.patient_pause && !form.appointment.appointment_closed ? `background-color: var(--wait)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.room_presence && ! form.appointment.patient_pause && !form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                    </svg>
                                                </div>
                                                <div class="luce d-flex" :style="form.appointment.patient_pause  && !form.appointment.appointment_closed ? `background-color: var(--pause)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.patient_pause && !form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                    </svg>
                                                </div>
                                                <div class="luce d-flex" :style="form.appointment.appointment_closed ? `background-color: var(--finish)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <!--<div class="d-flex mt-3" v-if="!appointment_expired">

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" :style="time_delay.type == 'ore' ? `border-color: var(--wait)` : `border-color: var(--pause)`">
                                                    <h1 class="effetto-lampeggia mb-1">
                                                        {{time_delay.ago}}
                                                    </h1>
                                                    <p class="effetto-lampeggia text-center">
                                                        <small class="text-muted">
                                                            <i>
                                                                Durata minuti
                                                            </i>
                                                        </small>
                                                    </p>
                                                </div>

                                            </div>-->

                                            <div class="mt-3" v-if="form.appointment.appointment_closed">

                                                <p class="font-weight-bold m-0">Tempistiche</p>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Durata della visita
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_duration}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo di attesa nella sala di aspetto
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_waiting}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div> 
                                                

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo trascorso dalla data appuntamento alla chiamata nella stanza
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_waiting_room}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div>                                                  

                                            </div>

                                            <div class="d-flex justify-content-around" v-if="form.appointment.appointment_closed">
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Totem
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.totem_presence_arrival}}
                                                        </small>
                                                    </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Stanza
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.room_presence_arrival | data_ora}}
                                                        </small>
                                                    </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Termine
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.appointment_closed.date | data_ora}}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-if="!form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && form.appointment.taking_charge_start_time">
                                                <hr>
                                                <p class="font-weight-bold m-0">Stato preparazione farmaco</p>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Semaforo che indica lo stato del farmaco
                                                    </small>
                                                </p>

                                                <div class="semaforo">
                                                    <div class="luce d-flex" :style="form.appointment.room_presence && form.appointment.taking_charge_start_time && !form.appointment.preparation_pharma_start_time ? `background-color: var(--wait)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.room_presence && form.appointment.taking_charge_start_time&& !form.appointment.preparation_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                    <div class="luce d-flex" :style="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma_start_time ? `background-color: var(--pause)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                    <div class="luce d-flex" :style="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && form.appointment.prepared_pharma_start_time ? `background-color: var(--finish)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && form.appointment.prepared_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                </div>
                                                <div class="alert alert-danger mt-2" v-show="form.appointment.taking_charge_start_time && !form.appointment.preparation_pharma_start_time">
                                                Presa in carico
                                                </div>
                                                <div class="alert alert-warning effetto-lampeggia-loop  mt-2" v-show="form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma">
                                                    In preparazione
                                                </div>                                         
                                            </div>
                                        </div>   
                                    </div>
                                    <!-- Imposta paziente in pausa-->
                                    <div class="col-lg-3" v-if="!form.appointment.urgent && form.appointment.room_presence">

                                        <p class="font-weight-bold m-0">Gestione pausa</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi impostare un paziente in pausa e richiamarne un'altro in stanza
                                            </small>
                                        </p>

                                        <div class="alert alert-warning">
                                            Puoi impostare il paziente in pausa massimo 1 volta
                                        </div>

                                        <div class="form-group">

                                            <label for="patient_pause_description">Motivazioni pausa</label>

                                            <textarea class="form-control" id="patient_pause_description" v-model.trim="$v.form.appointment.patient_pause_description.$model" rows="3"  maxlength="700" v-bind:class="{'is-invalid': !$v.form.appointment.patient_pause_description.maxLength}" :disabled="form.appointment.appointment_closed || form.appointment.pause_start_time || appointment_expired "></textarea>
                                            
                                            <!--<small class="form-text text-muted" v-show="form.appointment.patient_pause_description.length > 0">
                                                {{form.appointment.patient_pause_description.length}}/700 Caratteri
                                            </small>-->
                                            <small class="form-text text-muted">
                                                Le motivazioni sulla pausa sono facoltative
                                            </small>

                                            <div class="invalid-feedback">
                                                La spiegazione deve essere inferiore a 700 caratteri
                                            </div>

                                        </div>
                                        <input type="hidden" class="form-control font-weight-bold" id="therapy_start_time" :value="form.appointment.therapy_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="therapy_end_time" :value="form.appointment.therapy_end_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="sent_pharmacist_start_time" :value="form.appointment.sent_pharmacist_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="preparation_pharma_start_time" :value="form.appointment.preparation_pharma_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="prepared_pharma_start_time" :value="form.appointment.prepared_pharma_start_time" disabled>
                                        
                                        <div class="form-group text-truncate mb-1" v-if="form.appointment.pause_start_time">
                                            <label for="pause_start_time">Inizio pausa</label>
                                            <input type="time" class="form-control font-weight-bold" id="pause_start_time" :value="form.appointment.pause_start_time" disabled>
                                            <small class="form-text text-muted">L'orario viene impostato automaticamente</small>
                                        </div>
                                    
                                        <div class="form-group text-truncate" v-if="form.appointment.pause_end_time">
                                            <label for="pause_end_time">Termine pausa</label>
                                            <input type="time" class="form-control font-weight-bold" id="pause_end_time" :value="form.appointment.pause_end_time" disabled>
                                            <small class="form-text text-muted">L'orario viene impostato automaticamente</small>
                                        </div>
                                        

                                        <div class="alert alert-warning" v-show="form.appointment.patient_pause && !form.appointment.patient_pause_back">
                                            Il paziente non è ancora tornato in sala di attesa
                                        </div>
                                        <div class="alert bg_room effetto-lampeggia-loop" v-show="form.appointment.patient_pause && form.appointment.patient_pause_back">
                                            Il paziente è tornato in sala di attesa
                                        </div>

                                        <button class="btn btn-ifo" type="button" @click="pausePatient" :disabled="
                                            form.appointment.totem_presence && !form.appointment.room_presence ||
                                            form.appointment.appointment_closed ||
                                            $v.form.appointment.patient_pause_description.$invalid ||
                                            appointment_expired ||
                                            !functionSpecialEnabled(3)"
                                            v-if="!form.appointment.pause_start_time">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                </svg> Imposta il paziente in pausa
                                        </button>

                                        <button class="btn btn-ifo" type="button" @click="check_if_patient_is_present('back_from_pause_patient')" :disabled="form.appointment.appointment_closed || !form.appointment.patient_pause_back" v-show="form.appointment.patient_pause">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                            </svg> Richiama il paziente in stanza
                                        </button>
                                        <!--AGGIUNGERE QUI PAZIENTE FUORI ROMA -->
                                        <div v-if="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.taking_charge_start_time && !form.appointment.patient_pause && form.appointment.sent_nurses && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed">
                                            <hr /> 
                                            <p class="font-weight-bold m-0">Farmaco presente in reparto</p>

                                            <div class="form-group">

                                                <label for="medicine_ready_description">Note farmaco presente in reparto</label>

                                                <textarea class="form-control" id="medicine_ready_description" v-model.trim="$v.form.appointment.medicine_ready_description.$model" rows="3"  maxlength="700" v-bind:class="{'is-invalid': !$v.form.appointment.medicine_ready_description.maxLength}" :disabled="!functionSpecialEnabled(15) || form.appointment.medicine_ready_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.medicine_ready_description.length > 0">
                                                    {{form.appointment.medicine_ready_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="medicineReady(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.verify_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(15)" v-if="!form.appointment.medicine_ready_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                                                
                                                Notifica farmaco presente in reparto
                                            </button>    

                                            <div class="alert alert-success" v-if="form.appointment.id_sent_pharmacy">
                                                Farmaco presente notificato dall'infermiere {{form.appointment.senderPharma}} 
                                            </div>                                                                                 
                                        </div>  

                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.medicine_ready_start_time">
                                            <hr /> 
                                            <p class="font-weight-bold m-0">Invio alla farmacia</p>

                                            <div class="form-group">

                                                <label for="taking_charge_description">Note invio per la farmacia</label>

                                                <textarea class="form-control" id="taking_charge_description" v-model.trim="$v.form.appointment.taking_charge_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.taking_charge_description.maxLength}" :disabled="!functionSpecialEnabled(15) || form.appointment.taking_charge_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.taking_charge_description.length > 0">
                                                    {{form.appointment.taking_charge_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="takingCharge(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.taking_charge_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(15)" v-if="!form.appointment.taking_charge_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                                                
                                                Invia alla farmacia
                                            </button>    

                                            <div class="alert alert-success" v-if="form.appointment.id_sent_pharmacy">
                                                Inviato alla farmacia dall'infermiere {{form.appointment.senderPharma}} 
                                            </div>                                                                                 
                                        </div>  
                                        
                                        
                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && form.appointment.taking_charge_start_time && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && functionSpecialEnabled(13) && !form.appointment.medicine_ready_start_time">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Presa in carico</p>

                                            <div class="form-group">

                                                <label for="preparation_pharma_description">Note invio per la preparazione</label>

                                                <textarea class="form-control" id="preparation_pharma_description" v-model.trim="$v.form.appointment.preparation_pharma_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.preparation_pharma_description.maxLength}" :disabled="!functionSpecialEnabled(13) || form.appointment.preparation_pharma_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.preparation_pharma_description.length > 0">
                                                    {{form.appointment.preparation_pharma_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="preparationPharmacyTherapy(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.preparation_pharma_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(13)" v-if="!form.appointment.preparation_pharma_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>                                               
                                                Invia alla preparazione
                                            </button> 

                                            <div class="alert alert-success" v-if="form.appointment.id_taking_charge_user">
                                                Presa in carico da {{form.appointment.takeChargeUser}} 
                                            </div>                                                                                   
                                        </div>
                                        
                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1  && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.medicine_ready_start_time">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Chiusura farmaco</p>

                                            <div class="form-group">

                                                <label for="prepared_pharma_description">Note chiusura farmaco</label>

                                                <textarea class="form-control" id="prepared_pharma_description" v-model.trim="$v.form.appointment.prepared_pharma_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.prepared_pharma_description.maxLength}" :disabled="!functionSpecialEnabled(14) || form.appointment.prepared_pharma_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.prepared_pharma_description.length > 0">
                                                    {{form.appointment.prepared_pharma_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                                                                    
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="preparedPharmacyTherapy(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.prepared_pharma_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(14)" v-if="!form.appointment.prepared_pharma_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>

                                                Farmaco consegnato
                                            </button>       

                                            <div class="alert alert-success" v-if="form.appointment.id_closer_pharma">
                                                Farmaco chiuso da {{form.appointment.closerPharma}} 
                                            </div>                                                                                 
                                        </div>

                                    </div>
                                </div>

                            </div>                                                       
                            <div v-else-if="form.appointment.totem_presence || form.appointment.urgent">

                                <div class="row">
                                    <!-- Form di modifica-->
                                    <div class="col-lg-6" v-bind:class="{'col-lg-12': form.appointment.urgent}">

                                        <p class="font-weight-bold m-0">Informazioni visita</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Riepilogo sulle informazioni della visita
                                            </small>
                                        </p>
                                        
                                        <div class="row">
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="actual_room">Stanza</label>
                                                    <input type="text" class="form-control font-weight-bold" id="actual_room" :value="form.user.number" disabled>
                                                </div>
                                            </div>
                                            <!--v-show="!form.appointment.urgent"-->
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="totem_number">Codice identificativo</label>
                                                    <input type="text" class="form-control font-weight-bold bg-danger text-white" id="totem_number" :value="form.appointment.totem_number" disabled>
                                                </div>
                                            </div>  
                                        </div>
                                        <div class="row">                                            
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="date_appointment">Data appuntamento</label>
                                                    <input type="date" class="form-control" id="date_appointment" v-model="form.appointment.date" disabled>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xl-4">
                                                <div class="form-group text-truncate">
                                                    <label for="time_appointment">Inizio appuntamento</label>
                                                    <input type="time" v-model="form.appointment.h_start" class="form-control" id="time_appointment" disabled>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xl-4" v-if="!form.appointment.urgent">
                                                <div class="form-group m-0 text-truncate">

                                                    <label for="time_appointment">Orario arrivo</label>
                                                    <input type="time" v-model="form.appointment.totem_presence_arrival" class="form-control font-weight-bold" id="time_appointment" disabled>

                                                    <small class="form-text text-muted effetto-lampeggia" v-show="form.appointment.totem_presence_arrival && !form.appointment.appointment_closed">
                                                        {{time_difference}}
                                                    </small>

                                                </div>
                                            </div>
                                        </div>  
                                            
                                        <div class="form-group">

                                            <label for="pathologies">Patologia seguita</label>

                                            <select class="form-control" id="pathologies" v-model="form.appointment.id_patient_pathology" disabled>
                                                <option v-for="patient_pathology of patients_pathologies" :key="`pathology_${patient_pathology.id}`" :value="patient_pathology.id">
                                                    {{patient_pathology.pathology.title}}
                                                </option>
                                            </select>                                 

                                        </div>

                                        <hr>
                                    
                                        <div v-if="therapies_by_pathology.length > 0">

                                            <p class="font-weight-bold m-0">Gestione della terapia</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Gestisci e controlla la cronologia delle terapie associate al paziente
                                                </small>
                                            </p>

                                            <div class="row font-weight-bold">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Data
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Vecchia terapia
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Motivazione
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em border-bottom">
                                                        Utente
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <p v-if="form.patients_pathologies.therapy_cronologies.length < 1">
                                                <small class="text-warning font-weight-bold">
                                                    Ancora nessuna terapia associata..
                                                </small>
                                            </p>
                                            
                                            <div class="row" v-for="therapy_cronology of form.patients_pathologies.therapy_cronologies" :key="`therapy_cronology_${therapy_cronology.id}`">
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em f-family-monospace">
                                                        {{therapy_cronology.date | data_slash}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.title_old_therapy ? therapy_cronology.title_old_therapy : "Nessuna terapia"}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.description ? therapy_cronology.description : "Nessuna spiegazione"}}
                                                    </p>
                                                </div>
                                                <div class="col-3">
                                                    <p class="m-0 f-size-0_8-em text-truncate">
                                                        {{therapy_cronology.modifier_user}}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="form-group">

                                                <label for="id_therapy">Terapia attualmente seguita</label>

                                                <select id="id_therapy" class="form-control" v-model="form.patients_pathologies.id_therapy" @input="change_title_new_therapy($event.target.value)" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}">
                                                    <option :value="null">
                                                        Nessuna terapia
                                                    </option>
                                                    <option v-for="therapy_by_pathology in therapies_by_pathology" v-bind:key="`therapy_by_pathology_${therapy_by_pathology.id}`" v-bind:value="therapy_by_pathology.therapy.id">
                                                        {{therapy_by_pathology.therapy.title}}
                                                    </option>
                                                </select>

                                                <small class="form-text text-muted">
                                                    Puoi modificare la terapia selezionandone una dalla lista
                                                </small>

                                            </div>
                                            <div class="form-group">

                                                <label for="description_edit">Motivazioni sulla modifica</label>

                                                <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': validation.ko_update_therapy || !$v.form.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}"></textarea>

                                                <small class="form-text text-muted">
                                                    Fornisci una breve spiegazione sulla terapia impostata
                                                </small>
                                                <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                                    {{validation.ko_update_therapy}}
                                                </div>
                                                <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                                    {{validation.ok_update_therapy}}
                                                </div>
                                                <div class="invalid-feedback" v-show="!$v.form.form_edit_therapy.description.maxLength">
                                                    La motivazioni devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>

                                            <button class="btn btn-ifo btn-sm" type="button" :disabled="functionDisabled(10, 'update') || loaders.updating || $v.form.form_edit_therapy.$invalid" @click="update_therapy">
                                                Modifica terapia
                                            </button>

                                        </div>

                                        <div class="alert alert-danger" v-else>
                                            <u>ATTENZIONE</u>! Per questa patologia non sono state associate delle terapie
                                        </div>

                                        <!-- Campo note -->
                                        <div class="mb-3">
                                            <hr>
                                            <p class="font-weight-bold mb-2">Note appuntamento</p>
                                            <div class="form-group">
                                                <textarea type="text" class="form-control" rows="5" id="note_create" v-model="form.appointment.note_create" placeholder="Note appuntamento" disabled></textarea>
                                            </div>      
                                        </div>                                         

                                        <!-- Note sulla visita -->
                                        <div v-if="form.appointment.room_presence">

                                            <hr>

                                            <p class="font-weight-bold m-0">Gestione visita</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Aggiungi ulteriori informazioni sulla visita
                                                </small>
                                            </p>

                                            <div class="form-group">

                                                <label for="appointment_closed_note">
                                                    Note sulla visita
                                                </label>

                                                <textarea class="form-control" id="appointment_closed_note" maxlength="700" v-model.trim="$v.form.closing.note.$model" rows="3" v-bind:class="{'is-invalid': !$v.form.closing.note.maxLength}" :disabled="form.appointment.appointment_closed || appointment_expired"></textarea>
                                                
                                                <small class="form-text text-muted" v-show="form.closing.note.length > 0">
                                                    {{form.closing.note.length}}/700 Caratteri
                                                </small>
                                                <small class="form-text text-muted">
                                                    Al click della bottone "Termina visita" queste note sulla visita verranno storicizzate
                                                </small>
                                                
                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>

                                            <div class="alert alert-success" v-if="form.appointment.appointment_closed">
                                                Visita medica conclusa dal Dott. {{form.appointment.appointment_closed.user.name}} {{form.appointment.appointment_closed.user.surname}}
                                            </div>

                                        </div>
                                        <div v-show="validation.ko" class="alert alert-danger text-center">
                                            {{validation.ko}}
                                        </div>
                                        <div class="alert alert-warning" v-if="form.appointment.patient_pause">
                                            Non puoi terminare una visita quando il paziente è in pausa
                                        </div>

                                        <div v-if="form.appointment.urgent">
                                            <div class="text-center">
                                                <button class="btn btn-ifo" type="button" :disabled="loaders.closing || loaders.deleting || $v.form.closing.$invalid" @click="termina_visita" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(2) && !appointment_expired && !form.appointment.appointment_closed && !form.appointment.patient_pause">
                                                
                                                    <span v-show="!loaders.closing">
                                                        Termina visita
                                                    </span>

                                                    <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                                </button>
                                            </div>                                              
                                        </div>

                                        <div v-else>

                                            <button class="btn btn-ifo" type="button" :disabled="loaders.closing || loaders.deleting || $v.form.closing.$invalid" @click="termina_visita" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(2) && !appointment_expired && !form.appointment.appointment_closed && !form.appointment.patient_pause">
                                            
                                                <span v-show="!loaders.closing">
                                                    Termina visita
                                                </span>

                                                <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                            </button>
                                        </div>


                                        <!-- Note sulla visita -->
                                        <div v-if="form.appointment.room_presence && form.appointment.sent_nurses && !form.appointment.taking_charge_start_time">

                                            <hr>

                                            <p class="font-weight-bold m-0">Gestione visita infermieristica</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Aggiungi ulteriori informazioni sulla visita
                                                </small>
                                            </p>

                                            <div class="form-group">

                                                <label for="note_closed_nurses">
                                                    Note sulla visita infermieristica
                                                </label>

                                                <textarea class="form-control" id="note_closed_nurses" maxlength="700" v-model.trim="$v.form.appointment.note_closed_nurses.$model" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.note_closed_nurses.maxLength}" :disabled="form.appointment.nurses_end_time || form.appointment.sent_verify  || form.appointment.taking_charge_start_time || appointment_expired"></textarea>
                                                
                                               <!--<small class="form-text text-muted" v-show="form.appointment.note_closed_nurses.length > 0">
                                                    {{form.appointment.note_closed_nurses.length}}/700 Caratteri
                                                </small>--> 
                                                <small class="form-text text-muted">
                                                    Al click della bottone "Termina visita" queste note sulla visita verranno storicizzate
                                                </small>
                                                
                                                <div class="invalid-feedback">
                                                    La spiegazione deve essere inferiore a 700 caratteri
                                                </div>

                                            </div>
                                            <div v-show="validation.ko" class="alert alert-danger text-center">
                                                {{validation.ko}}
                                            </div>
                                            <div class="alert alert-warning" v-if="form.appointment.patient_pause  && form.appointment.sent_nurses">
                                                Non puoi terminare una visita quando il paziente è in pausa
                                            </div>

                                            <div class="alert alert-success" v-if="form.appointment.id_nurse_closed">
                                                Visita infermieristica conclusa dal Dott. {{form.appointment.nurseUser}} 
                                            </div>                                            

                                            <button class="btn btn-ifo" type="button" :disabled="loaders.closing_nurses || $v.form.closing.$invalid" @click="termina_visita_infermieristica" v-if="(form.appointment.room_presence || form.appointment.urgent) && functionSpecialEnabled(15) && !appointment_expired && form.appointment.appointment_closed && !form.appointment.patient_pause && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.sent_verify && !form.appointment.taking_charge_start_time">

                                                <span v-show="!loaders.closing_nurses">
                                                    Termina visita
                                                </span>

                                                <div v-show="loaders.closing" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                            </button>
                                        </div>
                                    </div>


                                    <div class="px-3 border-left-lg" v-if="!form.appointment.urgent && !form.appointment.isTherapyAppointment && !form.appointment.isBloodtestAppointment" v-bind:class="{'col-lg-6': !form.appointment.room_presence, 'col-lg-3 border-right-lg': form.appointment.room_presence}">
                                        <div class="d-flex h-100" v-if="form.appointment.totem_presence && !form.appointment.room_presence && !appointment_expired">
                                            <div class="m-auto">

                                                <div class="d-flex">

                                                    <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" :style="time_delay.type == 'ore' ? `border-color: var(--wait)` : `border-color: var(--pause)`">
                                                        <h1 class="effetto-lampeggia mb-1">
                                                            {{time_delay.ago}}
                                                        </h1>
                                                        <p class="effetto-lampeggia text-center">

                                                            <b class="text-uppercase" :style="time_delay.type == 'ore' ? `color: var(--wait)` : `color: var(--pause)`">
                                                                {{time_delay.type}}
                                                            </b>

                                                            <br>

                                                            <small class="text-muted">
                                                                <i>
                                                                    Attesa..
                                                                </i>
                                                            </small>

                                                        </p>
                                                    </div>

                                                </div>

                                                <p class="text-muted text-center">
                                                    <i>
                                                        <small>
                                                            Il paziente è in sala di aspetto, quando sei pronto puoi invitarlo
                                                        </small>
                                                    </i>
                                                </p>

                                            </div>
                                        </div>
                                        <div v-if="form.appointment.room_presence">

                                            <p class="font-weight-bold m-0">Stato della visita</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Semaforo che indica lo stato della visita
                                                </small>
                                            </p>

                                            <div class="semaforo">
                                                <div class="luce d-flex" :style="form.appointment.room_presence && !form.appointment.patient_pause && !form.appointment.appointment_closed ? `background-color: var(--wait)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.room_presence && ! form.appointment.patient_pause && !form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                    </svg>
                                                </div>
                                                <div class="luce d-flex" :style="form.appointment.patient_pause  && !form.appointment.appointment_closed ? `background-color: var(--pause)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.patient_pause && !form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                    </svg>
                                                </div>
                                                <div class="luce d-flex" :style="form.appointment.appointment_closed ? `background-color: var(--finish)` : null">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check-circle m-auto" viewBox="0 0 16 16" v-show="form.appointment.appointment_closed">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <!--<div class="d-flex mt-3" v-if="!appointment_expired">

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" :style="time_delay.type == 'ore' ? `border-color: var(--wait)` : `border-color: var(--pause)`">
                                                    <h1 class="effetto-lampeggia mb-1">
                                                        {{time_delay.ago}}
                                                    </h1>
                                                    <p class="effetto-lampeggia text-center">
                                                        <small class="text-muted">
                                                            <i>
                                                                Durata minuti
                                                            </i>
                                                        </small>
                                                    </p>
                                                </div>

                                            </div>-->

                                            <div class="mt-3" v-if="form.appointment.appointment_closed">

                                                <p class="font-weight-bold m-0">Tempistiche</p>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Durata della visita
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_duration}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo di attesa nella sala di aspetto
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_waiting}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div> 
                                                
 
                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Tempo trascorso dalla data appuntamento alla chiamata nella stanza
                                                    </small>
                                                </p>

                                                <div class="mezzo_tondo m-auto d-flex flex-column justify-content-around align-items-center" style="border-color: var(--finish)">
                                                    <h1 class="mb-1 f-family-monospace">
                                                        {{time_finish_waiting_room}}
                                                    </h1>
                                                    <p class="text-center">

                                                        <b class="text-uppercase">
                                                            Minuti
                                                        </b>

                                                    </p>
                                                </div>                                                  

                                            </div>

                                            <div class="d-flex justify-content-around" v-if="form.appointment.appointment_closed">
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Totem
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.totem_presence_arrival}}
                                                        </small>
                                                    </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Stanza
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.room_presence_arrival | data_ora}}
                                                        </small>
                                                    </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="m-0 text-muted">
                                                        <small>
                                                            Termine
                                                        </small>
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg>
                                                    <p class="m-0 text-muted f-family-monospace">
                                                        <small>
                                                            {{form.appointment.appointment_closed.date | data_ora}}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-if="!form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && form.appointment.taking_charge_start_time">
                                                <hr>
                                                <p class="font-weight-bold m-0">Stato preparazione farmaco</p>

                                                <p class="text-muted mb-3">
                                                    <small>
                                                        Semaforo che indica lo stato del farmaco
                                                    </small>
                                                </p>

                                                <div class="semaforo">
                                                    <div class="luce d-flex" :style="form.appointment.room_presence && form.appointment.taking_charge_start_time && !form.appointment.preparation_pharma_start_time ? `background-color: var(--wait)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.room_presence && form.appointment.taking_charge_start_time&& !form.appointment.preparation_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                    <div class="luce d-flex" :style="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma_start_time ? `background-color: var(--pause)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                    <div class="luce d-flex" :style="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && form.appointment.prepared_pharma_start_time ? `background-color: var(--finish)` : null">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="color:#FFF" class="m-auto" v-show="form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && form.appointment.prepared_pharma_start_time" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>     
                                                    </div>
                                                </div>
                                                <div class="alert alert-danger mt-2" v-show="form.appointment.taking_charge_start_time && !form.appointment.preparation_pharma_start_time">
                                                   Presa in carico
                                                </div>
                                                <div class="alert alert-warning effetto-lampeggia-loop  mt-2" v-show="form.appointment.preparation_pharma_start_time && !form.appointment.prepared_pharma">
                                                    In preparazione
                                                </div>                                         
                                            </div>
                                        </div>   
                                    </div>
                                    <!-- Imposta paziente in pausa-->
                                    <div class="col-lg-3" v-if="!form.appointment.urgent && form.appointment.room_presence">

                                        <p class="font-weight-bold m-0">Gestione pausa</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi impostare un paziente in pausa e richiamarne un'altro in stanza
                                            </small>
                                        </p>

                                        <div class="alert alert-warning">
                                            Puoi impostare il paziente in pausa massimo 1 volta
                                        </div>

                                        <div class="form-group">

                                            <label for="patient_pause_description">Motivazioni pausa</label>

                                            <textarea class="form-control" id="patient_pause_description" v-model.trim="$v.form.appointment.patient_pause_description.$model" rows="3"  maxlength="700" v-bind:class="{'is-invalid': !$v.form.appointment.patient_pause_description.maxLength}" :disabled="form.appointment.appointment_closed || form.appointment.pause_start_time || appointment_expired "></textarea>
                                            
                                            <!--<small class="form-text text-muted" v-show="form.appointment.patient_pause_description.length > 0">
                                                {{form.appointment.patient_pause_description.length}}/700 Caratteri
                                            </small>-->
                                            <small class="form-text text-muted">
                                                Le motivazioni sulla pausa sono facoltative
                                            </small>

                                            <div class="invalid-feedback">
                                                La spiegazione deve essere inferiore a 700 caratteri
                                            </div>

                                        </div>
                                        <input type="hidden" class="form-control font-weight-bold" id="therapy_start_time" :value="form.appointment.therapy_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="therapy_end_time" :value="form.appointment.therapy_end_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="sent_pharmacist_start_time" :value="form.appointment.sent_pharmacist_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="preparation_pharma_start_time" :value="form.appointment.preparation_pharma_start_time" disabled>
                                        <input type="hidden" class="form-control font-weight-bold" id="prepared_pharma_start_time" :value="form.appointment.prepared_pharma_start_time" disabled>
                                        
                                        <div class="form-group text-truncate mb-1" v-if="form.appointment.pause_start_time">
                                            <label for="pause_start_time">Inizio pausa</label>
                                            <input type="time" class="form-control font-weight-bold" id="pause_start_time" :value="form.appointment.pause_start_time" disabled>
                                            <small class="form-text text-muted">L'orario viene impostato automaticamente</small>
                                        </div>
                                    
                                        <div class="form-group text-truncate" v-if="form.appointment.pause_end_time">
                                            <label for="pause_end_time">Termine pausa</label>
                                            <input type="time" class="form-control font-weight-bold" id="pause_end_time" :value="form.appointment.pause_end_time" disabled>
                                            <small class="form-text text-muted">L'orario viene impostato automaticamente</small>
                                        </div>
                                        

                                        <div class="alert alert-warning" v-show="form.appointment.patient_pause && !form.appointment.patient_pause_back">
                                            Il paziente non è ancora tornato in sala di attesa
                                        </div>
                                        <div class="alert bg_room effetto-lampeggia-loop" v-show="form.appointment.patient_pause && form.appointment.patient_pause_back">
                                            Il paziente è tornato in sala di attesa
                                        </div>

                                        <button class="btn btn-ifo" type="button" @click="pausePatient" :disabled="
                                            form.appointment.totem_presence && !form.appointment.room_presence ||
                                            form.appointment.appointment_closed ||
                                            $v.form.appointment.patient_pause_description.$invalid ||
                                            appointment_expired ||
                                            !functionSpecialEnabled(3)"
                                            v-if="!form.appointment.pause_start_time">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                </svg> Imposta il paziente in pausa
                                        </button>

                                        <button class="btn btn-ifo" type="button" @click="check_if_patient_is_present('back_from_pause_patient')" :disabled="form.appointment.appointment_closed || !form.appointment.patient_pause_back" v-show="form.appointment.patient_pause">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                            </svg> Richiama il paziente in stanza
                                        </button>
                                        <!--AGGIUNGERE QUI PAZIENTE FUORI ROMA -->
                                        <div v-if="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.taking_charge_start_time && !form.appointment.patient_pause && form.appointment.sent_nurses && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed">
                                            <hr /> 
                                            <p class="font-weight-bold m-0">Farmaco presente in reparto</p>

                                            <div class="form-group">

                                                <label for="medicine_ready_description">Note farmaco presente in reparto</label>

                                                <textarea class="form-control" id="medicine_ready_description" v-model.trim="$v.form.appointment.medicine_ready_description.$model" rows="3"  maxlength="700" v-bind:class="{'is-invalid': !$v.form.appointment.medicine_ready_description.maxLength}" :disabled="!functionSpecialEnabled(15) || form.appointment.medicine_ready_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.medicine_ready_description.length > 0">
                                                    {{form.appointment.medicine_ready_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="medicineReady(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.verify_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(15)" v-if="!form.appointment.medicine_ready_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                                                
                                                Notifica farmaco presente in reparto
                                            </button>    

                                            <div class="alert alert-success" v-if="form.appointment.id_sent_pharmacy">
                                                Farmaco presente notificato dall'infermiere {{form.appointment.senderPharma}} 
                                            </div>                                                                                 
                                        </div>  

                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.medicine_ready_start_time">
                                            <hr /> 
                                            <p class="font-weight-bold m-0">Invio alla farmacia</p>

                                            <div class="form-group">

                                                <label for="taking_charge_description">Note invio per la farmacia</label>

                                                <textarea class="form-control" id="taking_charge_description" v-model.trim="$v.form.appointment.taking_charge_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.taking_charge_description.maxLength}" :disabled="!functionSpecialEnabled(15) || form.appointment.taking_charge_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.taking_charge_description.length > 0">
                                                    {{form.appointment.taking_charge_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="takingCharge(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.taking_charge_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(15)" v-if="!form.appointment.taking_charge_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                                                
                                                Invia alla farmacia
                                            </button>    

                                            <div class="alert alert-success" v-if="form.appointment.id_sent_pharmacy">
                                                Inviato alla farmacia dall'infermiere {{form.appointment.senderPharma}} 
                                            </div>                                                                                 
                                        </div>  
                                        
                                        
                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1 && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && form.appointment.taking_charge_start_time && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && functionSpecialEnabled(13) && !form.appointment.medicine_ready_start_time">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Presa in carico</p>

                                            <div class="form-group">

                                                <label for="preparation_pharma_description">Note invio per la preparazione</label>

                                                <textarea class="form-control" id="preparation_pharma_description" v-model.trim="$v.form.appointment.preparation_pharma_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.preparation_pharma_description.maxLength}" :disabled="!functionSpecialEnabled(13) || form.appointment.preparation_pharma_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.preparation_pharma_description.length > 0">
                                                    {{form.appointment.preparation_pharma_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>

                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                       
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="preparationPharmacyTherapy(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.preparation_pharma_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(13)" v-if="!form.appointment.preparation_pharma_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>                                               
                                                Invia alla preparazione
                                            </button> 

                                            <div class="alert alert-success" v-if="form.appointment.id_taking_charge_user">
                                                Presa in carico da {{form.appointment.takeChargeUser}} 
                                            </div>                                                                                   
                                        </div>
                                        
                                        <div v-show="!form.appointment.isTherapyAppointment && form.appointment.outside == 1  && form.appointment.totem_presence && !form.appointment.patient_pause && form.appointment.sent_nurses && form.appointment.taking_charge_start_time && form.appointment.preparation_pharma_start_time && !form.appointment.nurses_end_time && !form.appointment.id_nurse_closed && !form.appointment.medicine_ready_start_time">
                                            <hr />  
                                            <p class="font-weight-bold m-0">Chiusura farmaco</p>

                                            <div class="form-group">

                                                <label for="prepared_pharma_description">Note chiusura farmaco</label>

                                                <textarea class="form-control" id="prepared_pharma_description" v-model.trim="$v.form.appointment.prepared_pharma_description.$model"  maxlength="700" rows="3" v-bind:class="{'is-invalid': !$v.form.appointment.prepared_pharma_description.maxLength}" :disabled="!functionSpecialEnabled(14) || form.appointment.prepared_pharma_start_time || appointment_expired"></textarea>
                                                
                                                <!--<small class="form-text text-muted" v-show="form.appointment.prepared_pharma_description.length > 0">
                                                    {{form.appointment.prepared_pharma_description.length}}/700 Caratteri
                                                </small>-->
                                                <small class="form-text text-muted">
                                                    Le note sono facoltative
                                                </small>
  
                                                <div class="invalid-feedback">
                                                    Le note devono essere inferiori a 700 caratteri
                                                </div>

                                            </div>                                                                                    
                                            
                                            <button class="btn btn-ifo" style="width:100%" type="button" @click="preparedPharmacyTherapy(0, '')" :disabled="
                                                form.appointment.totem_presence && !form.appointment.room_presence ||
                                                $v.form.appointment.prepared_pharma_description.$invalid ||
                                                appointment_expired ||
                                                !functionSpecialEnabled(14)" v-if="!form.appointment.prepared_pharma_start_time && form.appointment.appointment_closed && !appointment_expired"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>

                                                Farmaco consegnato
                                            </button>       

                                            <div class="alert alert-success" v-if="form.appointment.id_closer_pharma">
                                                Farmaco chiuso da {{form.appointment.closerPharma}} 
                                            </div>                                                                                 
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        
                            
                            <!-- Se il paziente non si è ancora presentato e l'appuntamento non è scaduto e non è urgente-->
                            <div v-if="!form.appointment.totem_presence && !appointment_expired && !form.appointment.urgent">
                                <div v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                                    <p class="font-weight-bold m-0">Agenda ed informazioni settimanali</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Per creare un appuntamento clicca il giorno desiderato.
                                        </small>
                                    </p>
                                    
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" @click="updateYearPrev();" class="btnCreateUpdateCal">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                </svg>                                   
                                            </button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button type="button" @click="updateYearNext();" class="btnCreateUpdateCalN">                                 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>                                         
                                            </button>
                                        </div>
                                    </div>                                

                                    <template v-if="this.classCal == 2">
                                        <div class="container-cal"></div>
                                    </template>  
                                    <loader v-if="loaders.reading.rooms" />

                                    <vue-cal v-if="!loaders.reading.rooms" 
                                        locale                = "it"
                                        ref                   = "vuecal"
                                        :min-date             = "calendar.config.minDate"
                                        :max-date             = "calendar.config.maxDate"
                                        :events               = getEventsByRoomSelected(true)
                                        :time-from            = "calendar.config.timeFrom"
                                        :time-to              = "calendar.config.timeTo"
                                        :time-step            = "calendar.config.timeStep"
                                        :hide-weekdays        = "calendar.config.hideWeekDays"
                                        :selected-date        = "calendar.config.selectedDate"
                                        :drag-to-create-event = "false"
                                        :transitions          = "false"
                                        :disable-views        = "['years', 'days']"
                                        :special-hours        = getRoomAvailibilities   
                                        class                 = "mb-3 card-calendar createUpdateCal"                     
                                        @cell-click           = "setAppointmentParams($event)"
                                    />                              

                                    <div class="alert alert-warning" v-show="validation.conflict">
                                        {{validation.conflict}}
                                    </div>

                                    <div v-if="!day_of_appointment">

                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="form-group">

                                                    <label for="date_appointment">Data appuntamento</label>

                                                    <input type="date" class="form-control" id="date_appointment" :min="min_date_appointment" :max="calendar.config.maxDate" ref="data_appointment" v-model="$v.form.appointment.date.$model" v-bind:class="{'is-invalid': (!$v.form.appointment.date.required || !$v.form.appointment.date.minValue) && $v.form.appointment.date.$dirty}" @input="updateCheckRoomMaxVisits" @change="controlla_data">
                                                    
                                                    <small class="form-text text-muted">Clicca nel calendario il giorno richiesto</small>
                                                
                                                    <div class="invalid-feedback" v-show="!$v.form.appointment.date.required && $v.form.appointment.date.$dirty">
                                                        Data appuntamento richiesta
                                                    </div>
                                                    <div class="invalid-feedback" v-show="!$v.form.appointment.date.minValue && $v.form.appointment.date.$dirty">
                                                        La data deve essere impostato da domani
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">

                                                    <label for="appointment_h_start">Orario inizio appuntamento</label>

                                                    <input type="time" v-model="$v.form.appointment.h_start.$model" @input="updateTimeEndAppointment" class="form-control" id="appointment_h_start" v-bind:class="{'is-invalid': !$v.form.appointment.h_start.required && $v.form.appointment.h_start.$dirty}">
                                                    
                                                    <small class="form-text text-muted">Imposta un orario in base alle disponibilità</small>

                                                    <div class="invalid-feedback" v-show="!$v.form.appointment.h_start.required && $v.form.appointment.h_start.$dirty">
                                                        Orario inizio richiesto
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">

                                                    <label for="appointment_h_end">Orario termine appuntamento</label>

                                                    <input type="time" v-model="$v.form.appointment.h_end.$model" class="form-control" id="appointment_h_end" :disabled="this.functionSpecialEnabled(1) == false" v-bind:class="{'is-invalid': !$v.form.appointment.h_end.required}">

                                                    <small class="form-text text-muted" v-show="!this.functionSpecialEnabled(1)">L'orario termine viene impostato automaticamente in base alla durata terapia</small>
                                                    
                                                    <div class="invalid-feedback" v-show="!$v.form.appointment.h_end.required">
                                                        Orario termine richiesto
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                               
                                        </div>
  
                                    </div> 
                                    <!-- Campo note -->
                                    <div class="mb-3">
                                        <label for="note">Note appuntamento</label>
                                        <textarea type="text" class="form-control" rows="5" id="note_create" @input="updateNoteAppValue($event.target.value)" v-model="form.appointment.note_create" placeholder="Note appuntamento"></textarea>
                                    </div> 
                                    <button class="btn btn-ifo mt-2" style="width:20%" type="button"  @click="updateNoteApp(update_note_app_value)" 
                                    :disabled="!functionSpecialEnabled(15)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
                                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
                                        </svg>                            
                                        
                                        Modifica note
                                    </button>                                                                      
                                    <hr /> 
                                    <div class="row">
                                        <div class="col-lg-6">                           
                                            <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Puoi avvisare il paziente tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                                </small>
                                            </p>

                                            <div class="row" v-if="req_activation_therapy_program">
                                                <div class="col-lg-8">
                                                    <div class="alert alert-danger">
                                                        Con il programma teraupetico attivo, il paziente verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" v-model="notify.phone" :disabled="!form.user.phone">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                    <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email" :disabled="!form.user.email">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">                           
                                            <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Puoi avvisare il caregiver tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                                </small>
                                            </p>

                                            <div class="row" v-if="req_activation_therapy_program">
                                                <div class="col-lg-8">
                                                    <div class="alert alert-danger">
                                                        Con il programma teraupetico attivo, il caregiver verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" v-model="notify.phonecg" :disabled="!form.user.phonecg">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                    <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg" :disabled="!form.user.emailcg">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                                </div>
                                            </div>
                                        </div>                                
                                    </div>       

                                    <!--<div class="form-group mb-0">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="notify_phone" v-model="notify.phone" :disabled="!form.user.phone">
                                            <label class="custom-control-label" for="notify_phone">Notifica il paziente al numero <b><u>{{form.user.phone}}</u></b></label>
                                        </div>
                                    </div>-->

                                    <!--<div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="notify_email" v-model="notify.email" :disabled="!form.user.email">
                                            <label class="custom-control-label" for="notify_email">Notifica il paziente al'indirizzo email <b><u>{{form.user.email}}</u></b></label>
                                        </div>
                                    </div>-->
                                
                                    <div v-if="validation.ko != 'Terapia eliminata con successo!'">
                                        <div v-show="validation.ko" class="alert alert-danger text-center">
                                            {{validation.ko}}
                                        </div>
                                    </div>

                                    <button v-if="!functionDisabled(1, 'update')" @click="updateAppointment" class="btn btn-ifo" :disabled="loaders.updating || disabled == 1">
                                            
                                        <span v-show="!loaders.updating">
                                            Modifica
                                        </span>

                                        <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                    <button v-if="!functionDisabled(1, 'delete')" class="btn btn-danger ml-3" type="button" :disabled="loaders.deleting" v-b-modal.modal_delete_appointment>
                                            
                                        <span v-show="!loaders.deleting">
                                            Elimina
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                </div>

                            </div>

                            <div class="row" v-if="form.appointment.appointment_therapies.length > 0">
                                <div class="col-lg-12">
                                    <hr />
                                    <p class="font-weight-bold m-1">
                                        Tutte le terapie
                                    </p>
                                </div> 
                            </div>
                            <div class="row" v-show="DB.selected_duration > 0 && DB.selected_duration_chair == 0  && shownCal">

                                <div class="col-6">
                                    <button type="button" @click="updateYearPrev3();" class="btnCreateUpdateCalTherapy">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>                                   
                                    </button>
                                </div>
                                <div class="col-6 text-right paddRight">
                                    <button type="button" @click="updateYearNext3();" class="btnCreateUpdateCalTherapy">                                 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg>                                         
                                    </button>
                                </div>
                            </div>                                                        

                            <vue-cal 
                            v-show="DB.selected_duration > 0 && DB.selected_duration_chair == 0 && shownCal"  
                            locale                = "it"
                            ref                   = "vuecal_chairs_03"
                            :min-date             = "minDateToday"
                            :max-date             = "maxDate"
                            :events               = "events"
                            :time-from            = "timeFrom"
                            :time-to              = "timeTo"
                            :time-step            = "timeStep"
                            :hide-weekdays        = "calendar.config.hideWeekDays"
                            :selected-date        = "calendar.config.selectedDate"
                            :split-days           = "splitDays"
                            :sticky-split-labels  = "stickySplitLabels"
                            :drag-to-create-event = "false"
                            :transitions          = "true"
                            :min-cell-width        ="minCellWidth"
                            :min-split-width      = "minSplitWidth"                                                                
                            :disable-views        = "['years', 'year', 'month', 'months', 'week', 'weeks']"
                            class                 = "mt-2 card-calendar-chairs createUpdateCal"                     
                            @cell-click           = "setChairsDate($event)"
                            />

                            <div class="row">
                                <div class="col-lg-12" v-for="(app_therapy, counter) in form.appointment.appointment_therapies" v-bind:key="counter">
                                    <!--form.appointment.appointments_day_after.id != app_therapy.id-->                                
                                    <div class="alert alert-default mt-1" v-if="app_therapy.chairs">
                                        <div class="row">
                                            <div class="col-lg-9 text-left font-weight-bold f-size-1-em">
                                                {{app_therapy.chairs.title}} {{app_therapy.chairs.number}} assegnata da {{app_therapy.user.name}} {{app_therapy.user.surname}}
                                                - terapia <span class="text-danger font-weight-bold">{{ app_therapy.urgent ? "URGENTE" : "" }}</span> il {{moment(app_therapy.date).format('DD/MM/YYYY')}} dalle {{moment(app_therapy.h_start).format('HH:mm')}} alle {{moment(app_therapy.h_end).format('HH:mm')}} 
                                            </div>
                                            <div class="col-lg-3  text-right" v-show="!app_therapy.urgent"> 
                                                <button class="btnNoBg" type="button" @click="openUrl(app_therapy.id, 0, 0)" :disabled="!functionSpecialEnabled(11)" v-if="form.appointment.isTherapyAppointment && form.appointment.dateChair">                                                
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>                                                        
                                                </button>                                                                                                   
                                                <button class="btnNoBg" type="button" v-b-modal.modal_delete_therapy @click="itemToDelete=app_therapy.id" :disabled="!functionSpecialEnabled(11)" v-if="moment(app_therapy.date).isSameOrAfter(moment().format('YYYY-MM-DD')) && !app_therapy.totem_presence">                                                
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                                </button>    
                                            </div>     
                                        </div> 
                                        <div class="row mt-2">
                                            <!-- Campo note -->
                                            <div class="col-lg-12 mb-3 text-left">
                                                <label for="note">Note terapia</label>
                                                <textarea type="text" class="form-control" rows="5" id="note_create" @input="updateTherapyAppNote($event.target.value)" v-bind:value="form.appointment.appointment_therapies[counter].note_create" placeholder="Note terapia" :disabled="form.appointment.appointment_therapies[counter].room_presence || !functionSpecialEnabled(15)"></textarea>
                                               
                                               <button class="btn btn-ifo mt-2" style="width:20%" type="button"  @click="updateTherapyApp(update_therapy_app_value, app_therapy.id)" 
                                               :disabled="form.appointment.appointment_therapies[counter].room_presence || !functionSpecialEnabled(15)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
                                                        <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
                                                    </svg>                            
                                                    
                                                    Modifica note
                                                </button>                                              
                                            </div>                                                 
                                        </div>    
                                        <div class="row">
                                            <div class="col-lg-6 text-left" v-if="form.appointment.appointment_therapies[counter].outside != 1 && !form.appointment.appointment_therapies[counter].taking_charge_start_time">
                                                <!-- FARMACO PER TERAPIA GIORNO DOPO CON VISITA -->
                                                <hr /> 
                                                <p class="font-weight-bold m-0">Farmaco presente in reparto</p>
                                                <div class="form-group">

                                                    <label for="medicine_ready_description">Note farmaco presente in reparto</label>
                                                    <textarea class="form-control" id="medicine_ready_description"  @input="update_medicine_ready_description($event.target.value)" v-bind:value="form.appointment.appointment_therapies[counter].medicine_ready_description" rows="3" maxlength="700" :disabled="!functionSpecialEnabled(15) || form.appointment.appointment_therapies[counter].medicine_ready_start_time || moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') < moment().format('YYYYMMDD') "></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sono facoltative
                                                    </small>

                                                    <div class="invalid-feedback">
                                                        Le note devono essere inferiori a 700 caratteri
                                                    </div>

                                                </div>                                       
                                                
                                                <button class="btn btn-ifo" style="width:100%" type="button" @click="medicineReady(app_therapy.id, update_medicine_ready_description_value)" :disabled="
                                                    form.appointment.appointment_therapies[counter].room_presence ||
                                                    !functionSpecialEnabled(15)" v-if="!form.appointment.appointment_therapies[counter].medicine_ready_start_time && moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') >= moment().format('YYYYMMDD')"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                        <path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/>
                                                    </svg>
                                                    
                                                    Notifica farmaco presente in reparto
                                                </button>    

                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_sent_pharmacy">
                                                    Farmaco presente notificato da {{form.appointment.appointment_therapies[counter].senderPharma}} 
                                                </div>                                                                                 
                                            </div>    
                                            <div class="col-lg-6 text-left" v-if="form.appointment.appointment_therapies[counter].outside != 1 && !form.appointment.appointment_therapies[counter].medicine_ready_start_time">

                                                <!-- FARMACO IN CARICO PER TERAPIA GIORNO DOPO CON VISITA -->
                                                <hr /> 
                                                <p class="font-weight-bold m-0">Invio alla farmacia</p>

                                                <div class="form-group">

                                                    <label for="taking_charge_description">Note invio per la farmacia</label>

                                                    <textarea class="form-control" id="taking_charge_description" @input="update_taking_charge_description($event.target.value)" v-bind:value="form.appointment.appointment_therapies[counter].taking_charge_description" rows="3" maxlength="700"  :disabled="!functionSpecialEnabled(15) || form.appointment.appointment_therapies[counter].taking_charge_start_time  || moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') < moment().format('YYYYMMDD') "></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sono facoltative
                                                    </small>

                                                    <div class="invalid-feedback">
                                                        Le note devono essere inferiori a 700 caratteri
                                                    </div>

                                                </div>                                       
                                                
                                                <button class="btn btn-ifo" style="width:100%" type="button" @click="takingCharge(app_therapy.id, update_taking_charge_description_value)" :disabled="
                                                    form.appointment.appointment_therapies[counter].room_presence ||
                                                    !functionSpecialEnabled(15)" v-if="!form.appointment.appointment_therapies[counter].taking_charge_start_time  && moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') >= moment().format('YYYYMMDD')"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512">
                                                        <path d="M224 24V80H168c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V176h56c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H320V24c0-13.3-10.7-24-24-24H248c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h16 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H288 272 193.7c-29.1 0-57.3 9.9-80 28L68.8 384H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H192 352.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0 0 0c-.3 0-.6 0-.9 0z"/>
                                                    </svg>
                                                    
                                                    Invia alla farmacia
                                                </button>    

                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_sent_pharmacy">
                                                    Inviato alla farmacia da {{form.appointment.appointment_therapies[counter].senderPharma}} 
                                                </div>                                                                                 
                                            </div> 

                                            <!-- FARMACO IN PREPARAZIONE PER TERAPIA GIORNO DOPO CON VISITA -->
                                            <div class="col-lg-6 text-left" v-if="form.appointment.appointment_therapies[counter].outside != 1 && form.appointment.appointment_therapies[counter].taking_charge_start_time && functionSpecialEnabled(13) && !form.appointment.appointment_therapies[counter].medicine_ready_start_time"> 
                                                <hr />  
                                                <p class="font-weight-bold m-0">Presa in carico</p>

                                                <div class="form-group">

                                                    <label for="preparation_pharma_description">Note invio per la preparazione</label>

                                                    <textarea class="form-control" id="preparation_pharma_description" @input="update_preparation_pharma_description($event.target.value)" v-bind:value="form.appointment.appointment_therapies[counter].preparation_pharma_description" rows="3" maxlength="700" :disabled="!functionSpecialEnabled(13) || form.appointment.appointment_therapies[counter].preparation_pharma_start_time"></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sono facoltative
                                                    </small>

                                                    <div class="invalid-feedback">
                                                        Le note devono essere inferiori a 700 caratteri
                                                    </div>

                                                </div>  
                                                                                                
                                                <button class="btn btn-ifo" style="width:100%" type="button" @click="preparationPharmacyTherapy(app_therapy.id, update_preparation_pharma_description_value)" :disabled="
                                                    form.appointment.appointment_therapies[counter].room_presence ||
                                                    !functionSpecialEnabled(13)" v-if="!form.appointment.appointment_therapies[counter].preparation_pharma_start_time"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504.3 11.1C493.3-1.6 474.5-3.7 461 6.2L252.3 160H397.3L502.6 54.6c11.8-11.8 12.6-30.8 1.6-43.5zM32 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c0 82.5 43.4 147.7 123.9 176.2c-11.1 13.9-19.4 30.3-23.9 48.1C127.6 497.4 142.3 512 160 512H352c17.7 0 32.4-14.6 28.1-31.7c-4.5-17.8-12.8-34.1-23.9-48.1C436.6 403.7 480 338.5 480 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z"/></svg>                                               
                                                    Invia alla preparazione
                                                </button> 

                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_taking_charge_user">
                                                    Presa in carico da {{form.appointment.appointment_therapies[counter].takeChargeUser}} 
                                                </div>                                                                                   
                                            </div>

                                            <!-- FARMACO CHIUSURA PER TERAPIA GIORNO DOPO CON VISITA -->
                                            <div class="col-lg-6 text-left" v-if="form.appointment.appointment_therapies[counter].outside != 1 && form.appointment.appointment_therapies[counter].taking_charge_start_time && form.appointment.appointment_therapies[counter].preparation_pharma_start_time && !form.appointment.appointment_therapies[counter].medicine_ready_start_time"> 
                                                <hr />  
                                                <p class="font-weight-bold m-0">Chiusura farmaco</p>

                                                <div class="form-group">

                                                    <label for="prepared_pharma_description">Note chiusura farmaco</label>

                                                    <textarea class="form-control" id="prepared_pharma_description" @input="update_prepared_pharma_description($event.target.value)" v-bind:value="form.appointment.appointment_therapies[counter].prepared_pharma_description" rows="3" maxlength="700" :disabled="!functionSpecialEnabled(14) || form.appointment.appointment_therapies[counter].prepared_pharma_start_time"></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sono facoltative
                                                    </small>
    
                                                    <div class="invalid-feedback">
                                                        Le note devono essere inferiori a 700 caratteri
                                                    </div>

                                                </div>                                                                                    
                                                
                                                <button class="btn btn-ifo" style="width:100%" type="button" @click="preparedPharmacyTherapy(app_therapy.id, update_prepared_pharma_description_value)" :disabled="
                                                    form.appointment.appointment_therapies[counter].room_presence ||
                                                    !functionSpecialEnabled(14)" v-if="!form.appointment.appointment_therapies[counter].prepared_pharma_start_time"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M222.6 43.2l-.1 4.8H288c53 0 96 43 96 96s-43 96-96 96H248V160h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H248 220l-4.5 144H256c53 0 96 43 96 96s-43 96-96 96H240V384h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H213l-3.1 99.5L208.5 495l0 1c-.3 8.9-7.6 16-16.5 16s-16.2-7.1-16.5-16l0-1-1-31H136c-22.1 0-40-17.9-40-40s17.9-40 40-40h36l-1-32H152c-53 0-96-43-96-96c0-47.6 34.6-87.1 80-94.7V256c0 8.8 7.2 16 16 16h16.5L164 128H136 122.6c-9 18.9-28.3 32-50.6 32H56c-30.9 0-56-25.1-56-56S25.1 48 56 48h8 8 89.5l-.1-4.8L161 32c0-.7 0-1.3 0-1.9c.5-16.6 14.1-30 31-30s30.5 13.4 31 30c0 .6 0 1.3 0 1.9l-.4 11.2zM64 112a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>

                                                    Farmaco consegnato
                                                </button>       

                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_closer_pharma">
                                                    Farmaco chiuso da {{form.appointment.appointment_therapies[counter].closerPharma}} 
                                                </div>                                                                                 
                                            </div>




                                            <!--INVITO PAZIENTE IN TERAPIA DAY AFTER OK  -->
                                            <div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && ((form.appointment.appointment_therapies[counter].prepared_pharma || form.appointment.appointment_therapies[counter].medicine_ready_start_time) || (form.appointment.appointment_therapies[counter].outside == 1 && form.appointment.prepared_pharma || form.appointment.medicine_ready_start_time)) && moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                                <hr />  
                                                <p class="font-weight-bold m-0">Invita paziente in terapia</p>

                                                <div class="form-group">

                                                    <label for="patient_room_therapy_description">Note terapia</label>

                                                    <textarea class="form-control" id="patient_room_therapy_description" @input="update_patient_room_therapy_description($event.target.value)" :value="form.appointment.appointment_therapies[counter].patient_room_therapy_description" rows="3" maxlength="700" :disabled="form.appointment.appointment_therapies[counter].therapy_start_time"></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sull'invio in terapia sono facoltative
                                                    </small>

                                                    <div class="invalid-feedback">
                                                        La spiegazione deve essere inferiore a 700 caratteri
                                                    </div>

                                                </div>  
                                                
                                                <div v-if="form.appointment.appointments_day_after">
                                                    <div v-if="form.appointment.appointment_therapies[counter].totem_presence == 0 && form.appointment.appointment_therapies[counter].room_presence == 0">
                                                        <div class="alert alert-warning">
                                                            Il paziente non è ancora in sala di attesa 
                                                        </div>  
                                                    </div>                                                   
                                                    <!--<div v-if="!form.appointment.appointment_therapies[counter].outside == 1 && form.appointment.appointment_therapies[counter].totem_presence == 0 && form.appointment.appointment_therapies[counter].room_presence == 0">
                                                        <div class="alert alert-warning">
                                                            Il paziente non è ancora in sala di attesa 
                                                        </div>  
                                                    </div>-->

                                                    <div v-else-if="form.appointment.appointment_therapies[counter].totem_presence == 1 && form.appointment.appointment_therapies[counter].room_presence == 0">
                                                        <div class="alert bg_room effetto-lampeggia-loop">
                                                            Il paziente è in sala di attesa
                                                        </div> 
                                                        <button class="btn btn-ifo" type="button" @click="pausePatientTherapy(app_therapy.id, update_patient_room_therapy_description_value, form.appointment.appointment_therapies[counter].urgent)" :disabled="(!form.appointment.appointments_day_after.id_chair && DB.selected_duration_chair == 0) || !functionSpecialEnabled(7)"
                                                            v-if="functionSpecialEnabled(7) && !form.appointment.appointment_therapies[counter].room_therapy_called && !form.appointment.appointment_therapies[counter].therapy_start_time">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512">
                                                                <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/>
                                                            </svg>
                                                            Invita il paziente in terapia
                                                        </button>                                                 
                                                    </div>
                                                    <div v-else-if="form.appointment.appointment_therapies[counter].outside == 1">
                                                        <div v-if="!form.appointment.appointment_therapies[counter].therapy_start_time">
                                                            <div class="alert bg_room effetto-lampeggia-loop">
                                                                Il paziente è in sala di attesa
                                                            </div> 
                                                            <button class="btn btn-ifo" type="button" @click="pausePatientTherapy(app_therapy.id, update_patient_room_therapy_description_value, form.appointment.appointment_therapies[counter].urgent)" :disabled="(!form.appointment.appointments_day_after.id_chair && DB.selected_duration_chair == 0) || !functionSpecialEnabled(7)"
                                                                v-if="functionSpecialEnabled(7) && !form.appointment.appointment_therapies[counter].room_therapy_called && !form.appointment.appointment_therapies[counter].therapy_start_time">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512">
                                                                    <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/>
                                                                </svg>
                                                                Invita il paziente in terapia
                                                            </button> 
                                                        </div>    
                                                        <div v-if="form.appointment.appointment_therapies[counter].room_presence == 1 && !form.appointment.appointment_therapies[counter].therapy_end_time">
                                                            <button class="btn btn-ifo" type="button" @click="therapyNurseEnd(app_therapy.id, '')" :disabled="(!form.appointment.appointment_therapies[counter].id_chair && DB.selected_duration_chair == 0) || !functionSpecialEnabled(7)"
                                                                v-if="functionSpecialEnabled(7) & form.appointment.appointment_therapies[counter].room_therapy_called && form.appointment.appointment_therapies[counter].therapy_start_time  && !form.appointment.therapy_nurses_end_time">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>

                                                                Termina terapia
                                                            </button>
                                                        </div>                                                                                                         
                                                    </div>                                                   
                                                    <div v-else-if="form.appointment.appointment_therapies[counter].totem_presence == 1 && form.appointment.appointment_therapies[counter].room_presence == 1 && !form.appointment.appointment_therapies[counter].therapy_end_time">
                                                        <button class="btn btn-ifo" type="button" @click="therapyNurseEnd(app_therapy.id, '')" :disabled="(!form.appointment.appointment_therapies[counter].id_chair && DB.selected_duration_chair == 0) || !functionSpecialEnabled(7)"
                                                            v-if="functionSpecialEnabled(7) & form.appointment.appointment_therapies[counter].room_therapy_called && form.appointment.appointment_therapies[counter].therapy_start_time  && !form.appointment.therapy_nurses_end_time">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>

                                                            Termina terapia
                                                        </button>
                                                    </div>        
                                                </div>
                                                
                                                
                                                
                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_nurse_closed_therapy">
                                                    Terapia terminata dall'infermiere {{form.appointment.appointment_therapies[counter].nurseUser}} 
                                                </div>         

                                            </div>   
                                            <!--TERMINA TERAPIA DAY AFTER URGENTE OK  -->
                                            <!--<div class="col-lg-6 text-left" v-if="functionSpecialEnabled(7) && form.appointment.appointment_therapies[counter].urgent && ((form.appointment.appointment_therapies[counter].prepared_pharma || form.appointment.appointment_therapies[counter].medicine_ready_start_time) || (form.appointment.appointment_therapies[counter].outside == 1 && form.appointment.prepared_pharma || form.appointment.medicine_ready_start_time)) && moment(form.appointment.appointment_therapies[counter].date).format('YYYYMMDD') == moment().format('YYYYMMDD')">
                                                <hr />  
                                                <p class="font-weight-bold m-0">Termina terapia</p>

                                                <div class="form-group">

                                                    <label for="patient_room_therapy_description">Note terapia</label>

                                                    <textarea class="form-control" id="patient_room_therapy_description" @input="update_patient_room_therapy_description($event.target.value)" :value="form.appointment.appointment_therapies[counter].patient_room_therapy_description" rows="3" maxlength="700" :disabled="form.appointment.appointment_therapies[counter].therapy_end_time"></textarea>
                                                    
                                                    <small class="form-text text-muted">
                                                        Le note sull'invio in terapia sono facoltative
                                                    </small>

                                                    <div class="invalid-feedback">
                                                        La spiegazione deve essere inferiore a 700 caratteri
                                                    </div>

                                                </div>  
                                                
                                                <div v-if="form.appointment.appointments_day_after">
                                                    <div v-if="form.appointment.appointment_therapies[counter].urgent">
                                                        <button class="btn btn-ifo" type="button" @click="therapyNurseEnd(app_therapy.id, update_patient_room_therapy_description_value)" :disabled="(!form.appointment.appointment_therapies[counter].id_chair && DB.selected_duration_chair == 0) || !functionSpecialEnabled(7)"
                                                            v-if="functionSpecialEnabled(7) && !form.appointment.appointment_therapies[counter].therapy_end_time">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512"><path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>

                                                           Termina terapia
                                                        </button>
                                                    </div>        
                                                </div>
                                                
                                                
                                                
                                                <div class="alert alert-success" v-if="form.appointment.appointment_therapies[counter].id_nurse_closed_therapy">
                                                    Terapia terminata dall'infermiere {{form.appointment.appointment_therapies[counter].nurseUser}} 
                                                </div>         

                                            </div> -->

                                       
                                        </div> 
                                        
                                    </div>
                                    <div class="alert alert-default mt-1" v-else-if="!app_therapy.chairs">
                                        <div class="row">
                                            <div class="col-lg-9 text-left text-danger font-weight-bold">                                           
                                                Terapia il {{moment(app_therapy.date).format('DD/MM/YYYY')}} dalle {{moment(app_therapy.h_start).format('HH:mm')}} alle {{moment(app_therapy.h_end).format('HH:mm')}} 
                                                - Poltrona non ancora assegnata
                                            </div> 
                                            <div class="col-lg-3  text-right">                                                     
                                                <button class="btnNoBg" type="button" @click="deleteTherapyDate(app_therapy.id)" :disabled="!functionSpecialEnabled(11)" v-if="moment(app_therapy.date).isSameOrAfter(moment().format('YYYY-MM-DD'))">                                                
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                                </button>    
                                            </div> 
                                        </div>    
                                        <div class="row mt-2">    
                                            <div class="col-lg-3">
                                                <label for="id_duration">Durata terapia:</label> 
                                                <select class="form-control mb-2" id="id_duration" @change="onChangeDuration($event.target.value, 1)" v-model="DB.selected_duration">
                                                    <option value="0">Seleziona</option>
                                                    <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                        {{duration.title}} - {{duration.duration}} min.
                                                    </option>
                                                </select> 
                                            </div>  
                                            <div class="col-lg-3">
                                                <p class="m-0">
                                                    <label for="date_appointment_day_after">Giorno terapia</label>
                                                </p> 

                                                <input type="date" class="form-control" id="date_appointment_day_after" @blur="readChairs" v-model="form.appointment.appointment_day_after_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_appointment_day_after" disabled="disabled" readonly="readonly">
                                            </div>    

                                            <div class="col-lg-3">
                                                <p class="m-0">
                                                    <label for="appointment_h_start">Orario terapia</label>
                                                </p>
                                                <input type="time" class="form-control" id="appointment_day_after_h_start" @blur="readChairs" v-model="form.appointment.appointment_day_after_time" disabled="disabled" readonly="readonly">
                                            </div>   
                                            <div class="col-lg-3" v-if="DB.selected_duration_chair == 0">
                                                <div class="form-group">

                                                    <label for="chair_selected">Poltrona</label>

                                                    <input type="text" class="form-control" id="chair_selected" v-model="chair_selected" disabled readonly>
                                                    
                                                </div>
                                            </div>                              

                                            <div class="col-lg-12 text-left">
                                                
                                                <p v-if="form.appointment.therapyAppChairName != ''" v-show="DB.selected_duration > 0" class="font-weight-bold m-1">
                                                    Modifica poltrona     
                                                </p>

                                                <p v-else class="font-weight-bold m-0 mt-1" v-show="DB.selected_duration > 0">Seleziona poltrona</p>      

                                                <div class="form-group">

                                                    <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                                    <select class="form-control" id="id_chair" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                                        <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                                        <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                            {{chair.title}} {{chair.number}} - {{chair.description}}
                                                        </option>                                                
                                                    </select> 

                                                    <div v-show="DB.selected_duration > 0 && DB.selected_duration_chair == 0" class="mt-1">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <label for="id_chair" v-show="DB.selected_duration > 0">oppure seleziona poltrona dal calendario</label> 
                                                            </div>                                                                  
                                                        </div> 
                                                        
                                                    </div>                                                        
                                                </div>
                                            </div>
                                        </div> 

                                        <div class="row">
                                            <div class="col-lg-12 mt-3 text-left">
                                                <button class="btn btn-ifo" style="width:15%" type="button" @click="saveNewTherapyDate('', '', 0, '', '', 0)" :disabled="DB.selected_duration_chair == 0 && DB.selected_duration_chair_ == 0 && form.appointment.appointment_day_after_time != '' && form.appointment.appointment_day_after_date != '' || !functionSpecialEnabled(11)">                                                
                                                    Modifica terapia
                                                </button>
                                            </div> 
                                        </div>                                       
                                    </div>
                                                                                     
                                </div> 
                            </div>

                            <!--TERAPIA E POLTRONA: QUANDO TERAPIA E' ASSOCIATA A VISITA OK-->
                            <div v-if="!form.appointment.isTherapyAppointment && form.appointment.outside != 1 && !form.appointment.isBloodTestAppointment"> 
                                <div :style="moment(form.appointment.date).format('YYYYMMDD') == moment().format('YYYYMMDD') ? 'display:none' : ''">
                                    <hr />
                                    <div class="row mt-2 mb-2">
                                        <div class="col-lg-12">                  
                                            <p class="font-weight-bold m-0">Aggiungi nuova terapia</p>
                                        </div>
                                        <div class="col-lg-12" v-if="form.appointment.therapyAppChairName == '' && form.appointment.dateChair != ''">
                                            <div class="alert alert-warning mt-1">
                                                <div class="row">
                                                    <div class="col-lg-9 text-left">                                             
                                                        Terapia il {{form.appointment.dateChair}} dalle {{form.appointment.dateChairStart}} alle {{form.appointment.dateChairEnd}} - 
                                                        Poltrona non ancora assegnata
                                                    </div>
        
                                                    <div class="col-lg-3  text-right" v-if="moment(form.appointment.appointments_day_after.date).format('YYYYMMDD') >= moment().format('YYYYMMDD')">   
                                                        <button class="btnNoBg" type="button" @click="deleteTherapyDate(0)" :disabled="!functionSpecialEnabled(11)" v-if="form.appointment.dateChair">                                                
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                                        </button>    
                                                    </div> 
                                                </div>                                            
                                            </div>                                                   
                                        </div>                                                      
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label for="date_appointment">Data</label>

                                                <input type="date" class="form-control" id="date_appointment_day_after" @blur="readChairs" v-model="form.appointment.appointment_day_after_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_appointment_day_after">

                                            </div> 
                                        </div>                                  
                                        <div class="col-lg-4"  v-if="form.appointment.appointment_day_after_date">
                                            <div class="form-group">

                                                <label for="visit_types">Seleziona durata</label>
                                                <select class="form-control mb-2" id="id_duration" @change="onChangeDuration($event.target.value, 0)" v-model="DB.selected_duration">
                                                    <option value="0">Seleziona</option>
                                                    <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                        {{duration.title}} - {{duration.duration}} min.
                                                    </option>
                                                </select> 
                                            </div>
                                        </div> 
                                        <div class="col-lg-4 mt-1" v-if="form.appointment.appointment_day_after_date">
                                            <div class="mt-4">
                                                <button class="btn btn-success" type="button" v-b-modal.calendar_therapy_first>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512">
                                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                                    </svg>
                                                    Visualizza disponibilità poltrone                                        
                                                </button>
                                            </div>
                                        </div>                             
                                        <div class="col-lg-4" v-if="form.appointment.appointment_day_after_date && DB.selected_duration">
                                        
                                            <div class="form-group">
                                                <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona:</label> 
                                                <select class="form-control" id="id_chair" v-model="form.appointment.id_chair">
                                                    <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                        {{chair.title}} {{chair.number}} - {{chair.description}}
                                                    </option>                                                
                                                </select> 
                                                <input type="hidden" class="form-control" id="chair_selected" v-model="chair_selected" disabled readonly >
                                            </div> 
                                            <div class="row">
                                                <div class="col-lg-6" v-if="form.appointment.appointment_day_after_date && !form.appointment.urgent && DB.selected_duration">
                                                    <div class="form-group">

                                                        <label for="appointment_h_start">Orario inizio</label>

                                                        <input type="time" class="form-control" id="appointment_day_after_h_start" @blur="readChairsTime" v-model="form.appointment.appointment_day_after_time" v-bind:class="{'is-invalid': !$v.form.appointment.appointment_day_after_time.required && $v.form.appointment.appointment_day_after_time.$dirty}">
                                                        
                                                        <div class="invalid-feedback" v-show="!$v.form.appointment.appointment_day_after_time.required && $v.form.appointment.appointment_day_after_time.$dirty">
                                                            Orario inizio richiesto
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-6" v-if="form.appointment.appointment_day_after_date && !form.appointment.urgent && form.appointment.appointment_day_after_time && DB.selected_duration">
                                                    <div class="form-group">

                                                        <label for="appointment_day_after_h_end">Orario fine</label>
                                                        <input type="time" class="form-control" id="appointment_day_after_h_end" v-model="appointment_day_after_time_end" readonly disabled >
                                                        
                                                    </div>
                                                </div>                                 
                                            </div> 
                                        </div>
                                        <!-- Campo note -->
                                        <div class="col-lg-8 mb-3" v-if="form.appointment.appointment_day_after_date && DB.selected_duration">
                                            <label for="note">Note terapia</label>
                                            <textarea type="text" class="form-control" rows="5" id="note_create" v-model="appointment_day_after_note_create" placeholder="Note terapia"></textarea>
                                        </div>                                
                                    </div>      
              
                                    <!--<div class="row">    
                                        <div class="col-lg-3">
                                            <label for="id_duration">Durata terapia:</label> 
                                            <select class="form-control mb-2" id="id_duration" @change="onChangeDuration($event.target.value, 0)" v-model="DB.selected_duration">
                                                <option value="0">Seleziona</option>
                                                <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                    {{duration.title}} - {{duration.duration}} min.
                                                </option>
                                            </select> 
                                        </div> 
                                         
                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <p class="m-0 mt-1">
                                                <label for="date_appointment_day_after">Giorno terapia</label>
                                            </p> 

                                            <input type="date" class="form-control" id="date_appointment_day_after" @blur="readChairs" v-model="form.appointment.appointment_day_after_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_appointment_day_after" readonly disabled>
                                        </div>    

                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <p class="m-0">
                                                <label for="appointment_h_start">Orario terapia</label>
                                            </p>
                                            <input type="time" class="form-control" id="appointment_day_after_h_start" @blur="readChairsTime" v-model="form.appointment.appointment_day_after_time">
                                        </div>  

                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <div class="form-group">

                                                <label for="appointment_day_after_h_end">Orario fine</label>

                                                <input type="time" class="form-control" id="appointment_day_after_h_end" v-model="appointment_day_after_time_end">
                                                
                                            </div>
                                        </div>

                                        <div class="col-lg-3" v-if="DB.selected_duration_chair == 0 && DB.selected_duration > 0">
                                            <div class="form-group">

                                                <label for="chair_selected">Poltrona</label>

                                                <input type="text" class="form-control" id="chair_selected" v-model="chair_selected" disabled readonly>
                                                
                                            </div>
                                        </div>                              

                                        <div class="col-lg-12">
                                            
                                            <p v-if="form.appointment.therapyAppChairName != ''" v-show="DB.selected_duration > 0" class="font-weight-bold m-1">
                                                Modifica poltrona     
                                            </p>

                                            <p v-else class="font-weight-bold m-0 mt-1" v-show="DB.selected_duration > 0">Seleziona poltrona</p>      

                                            <div class="form-group">

                                                <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                                <select class="form-control" id="id_chair" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                                    <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                                    <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                        {{chair.title}} {{chair.number}} - {{chair.description}}
                                                    </option>                                                
                                                </select> 

                                                <div v-if="DB.selected_duration > 0 && DB.selected_duration_chair == 0" class="mt-1">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label for="id_chair" v-show="DB.selected_duration > 0">oppure seleziona poltrona dal calendario</label> 
                                                        </div>                                                                  
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-6">
                                                            <button type="button" @click="$refs.vuecal_chairs_01.previous(); updateYear();" class="btnCreateUpdateCalTherapy">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                                </svg>                                   
                                                            </button>
                                                        </div>
                                                        <div class="col-6 text-right paddRight">
                                                            <button type="button" @click="$refs.vuecal_chairs_01.next(); updateYear();" class="btnCreateUpdateCalTherapy">                                 
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                                </svg>                                         
                                                            </button>
                                                        </div>
                                                    </div>                                            
                                                    <vue-cal   
                                                        locale                = "it"
                                                        ref                   = "vuecal_chairs_01"
                                                        :min-date             = "minDate"
                                                        :max-date             = "maxDate"
                                                        :events               = "events"
                                                        :time-from            = "timeFrom"
                                                        :time-to              = "timeTo"
                                                        :time-step            = "timeStep"
                                                        :hide-weekdays        = "calendar.config.hideWeekDays"
                                                        :selected-date        = "calendar.config.selectedDate"
                                                        :split-days           = "splitDays"
                                                        :sticky-split-labels  = "stickySplitLabels"
                                                        :drag-to-create-event = "false"
                                                        :transitions          = "true"
                                                        :min-cell-width        ="minCellWidth"
                                                        :min-split-width      = "minSplitWidth"                                                                
                                                        :disable-views        = "['years', 'year', 'month', 'months', 'week', 'weeks']"
                                                        class                 = "mt-2 card-calendar-chairs createUpdateCal"                     
                                                        @cell-click           = "setChairsDate($event)"
                                                    />
                                                </div>                                                        
                                            </div>
                                        </div>
                                    </div> -->   
                                </div>        
                            </div> 

                            <div v-if="form.appointment.isTherapyAppointment"> 
                                <!--!form.appointment.appointments_day_after.id_nurse_closed_therapy-->
                                <div v-if="!form.appointment.appointments_day_after.id_nurse_closed_therapy">
                                    <hr />
                                    <div class="row mt-2 mb-2">
                                        <div class="col-lg-12">                  
                                            <p class="font-weight-bold m-0">Aggiungi nuova terapia</p>
                                        </div>
                                        <div class="col-lg-12" v-if="form.appointment.therapyAppChairName == '' && form.appointment.dateChair != ''">
                                            <div class="alert alert-warning mt-1">
                                                <div class="row">
                                                    <div class="col-lg-9 text-left">                                             
                                                        Terapia il {{form.appointment.dateChair}} dalle {{form.appointment.dateChairStart}} alle {{form.appointment.dateChairEnd}} - 
                                                        Poltrona non ancora assegnata
                                                    </div>
        
                                                    <div class="col-lg-3  text-right" v-if="moment(form.appointment.appointments_day_after.date).format('YYYYMMDD') >= moment().format('YYYYMMDD')">   
                                                        <button class="btnNoBg" type="button" @click="deleteTherapyDate(0)" :disabled="!functionSpecialEnabled(11)" v-if="form.appointment.dateChair">                                                
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                                                        </button>    
                                                    </div> 
                                                </div>                                            
                                            </div>                                                   
                                        </div>                                                      
                                    </div>
                                    <div class="row">    
                                        <div class="col-lg-3">
                                            <label for="id_duration">Durata terapia:</label> 
                                            <select class="form-control mb-2" id="id_duration" @change="onChangeDuration($event.target.value, 0)" v-model="DB.selected_duration">
                                                <option value="0">Seleziona</option>
                                                <option v-for="duration of DB.durations" :key="`durations_${duration.id}`" :value="duration.id">
                                                    {{duration.title}} - {{duration.duration}} min.
                                                </option>
                                            </select> 
                                        </div>  
                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <p class="m-0 mt-1">
                                                <label for="date_appointment_day_after">Giorno terapia</label>
                                            </p> 

                                            <input type="date" class="form-control" id="date_appointment_day_after" @blur="readChairs" v-model="form.appointment.appointment_day_after_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_appointment_day_after" readonly disabled>
                                        </div>    

                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <p class="m-0">
                                                <label for="appointment_h_start">Orario terapia</label>
                                            </p>
                                            <input type="time" class="form-control" id="appointment_day_after_h_start" @blur="readChairsTime" v-model="form.appointment.appointment_day_after_time">
                                        </div>   
                                        <div class="col-lg-2" v-if="DB.selected_duration > 0">
                                            <div class="form-group">

                                                <label for="appointment_day_after_h_end">Orario fine</label>

                                                <input type="time" class="form-control" id="appointment_day_after_h_end" v-model="appointment_day_after_time_end">
                                                
                                            </div>
                                        </div>                                        
                                        <div class="col-lg-3" v-if="DB.selected_duration_chair == 0 && DB.selected_duration > 0">
                                            <div class="form-group">

                                                <label for="chair_selected">Poltrona</label>

                                                <input type="text" class="form-control" id="chair_selected" v-model="chair_selected" disabled readonly>
                                                
                                            </div>
                                        </div>                              

                                        <div class="col-lg-12">
                                            
                                            <p v-if="form.appointment.therapyAppChairName != ''" v-show="DB.selected_duration > 0" class="font-weight-bold m-1">
                                                Modifica poltrona     
                                            </p>

                                            <p v-else class="font-weight-bold m-0 mt-1" v-show="DB.selected_duration > 0">Seleziona poltrona</p>      

                                            <div class="form-group">

                                                <label for="id_chair" v-show="DB.selected_duration > 0">Poltrona in base alla durata:</label> 
                                                <select class="form-control" id="id_chair" @change="onChangeChair($event)" v-model="DB.selected_duration_chair" v-show="DB.selected_duration > 0">
                                                    <option value="0">{{DB.chairs.length > 0 ? "Seleziona" : "Nessuna poltrona trovata"}}</option>
                                                    <option v-show="DB.chairs.length > 0" v-for="chair of DB.chairs" :key="`durations_${chair.id}`" :value="chair.id">
                                                        {{chair.title}} {{chair.number}} - {{chair.description}}
                                                    </option>                                                
                                                </select> 

                                                <div v-if="DB.selected_duration > 0 && DB.selected_duration_chair == 0" class="mt-1">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label for="id_chair" v-show="DB.selected_duration > 0">oppure seleziona poltrona dal calendario</label> 
                                                        </div>                                                                  
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-6">
                                                            <button type="button" @click="updateYearPrev1();" class="btnCreateUpdateCalTherapy">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                                </svg>                                   
                                                            </button>
                                                        </div>
                                                        <div class="col-6 text-right paddRight">
                                                            <button type="button" @click="updateYearNext1();" class="btnCreateUpdateCalTherapy">                                 
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                                </svg>                                         
                                                            </button>
                                                        </div>
                                                    </div>                                            
                                                    <vue-cal   
                                                        locale                = "it"
                                                        ref                   = "vuecal_chairs_01"
                                                        :min-date             = "minDate"
                                                        :max-date             = "maxDate"
                                                        :events               = "events"
                                                        :time-from            = "timeFrom"
                                                        :time-to              = "timeTo"
                                                        :time-step            = "timeStep"
                                                        :hide-weekdays        = "calendar.config.hideWeekDays"
                                                        :selected-date        = "calendar.config.selectedDate"
                                                        :split-days           = "splitDays"
                                                        :sticky-split-labels  = "stickySplitLabels"
                                                        :drag-to-create-event = "false"
                                                        :transitions          = "true"
                                                        :min-cell-width        ="minCellWidth"
                                                        :min-split-width      = "minSplitWidth"                                                                
                                                        :disable-views        = "['years', 'year', 'month', 'months', 'week', 'weeks']"
                                                        class                 = "mt-2 card-calendar-chairs createUpdateCal"                     
                                                        @cell-click           = "setChairsDate($event)"
                                                    />
                                                </div>                                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row text-left" v-if="DB.selected_duration > 0 ">

                                        <div class="col-lg-6">                           
                                            <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Puoi avvisare il paziente tramite SMS o email sulla creazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                                                </small>
                                            </p>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" v-model="notify.phone_therapy" :disabled="!form.user.phone">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                    <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email_therapy" :disabled="!form.user.email">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">                           
                                            <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                            <p class="text-muted mb-3">
                                                <small>
                                                    Puoi avvisare il caregiver tramite SMS o email sulla creazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                                                </small>
                                            </p>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" v-model="notify.phonecg_therapy" :disabled="!form.user.phonecg">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                    <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-8">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg_therapy" :disabled="!form.user.emailcg">
                                                            </div>
                                                        </div>

                                                        <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                        
                                                    </div>
                                                    
                                                    <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                                </div>
                                            </div>
                                        </div>                                
                                    </div>                              
                                    <div class="row">
                                        <div class="col-lg-12 mt-3 text-left" v-if="DB.selected_duration > 0 && chair_selected != '' ">
                                            <button class="btn btn-ifo" style="width:15%" type="button" @click="saveNewTherapyDate('', '', 0, '', '', 1)">                                                
                                                Aggiungi terapia
                                            </button>
                                        </div> 
                                    </div>                                      
                                </div>        
                            </div>                                  
                            
                            <div class="row text-left" v-if="DB.selected_duration > 0  && form.appointment.appointment_day_after_date != '' && form.appointment.appointment_day_after_time != '' && !form.appointment.isTherapyAppointment && form.appointment.outside != 1 && moment(form.appointment.date).format('YYYYMMDD') != moment().format('YYYYMMDD') && functionSpecialEnabled(11)">

                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il paziente tramite SMS o email sulla creazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phone_therapy" :disabled="!form.user.phone">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email_therapy" :disabled="!form.user.email">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">                           
                                    <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                    <p class="text-muted mb-3">
                                        <small>
                                            Puoi avvisare il caregiver tramite SMS o email sulla creazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                                        </small>
                                    </p>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" v-model="notify.phonecg_therapy" :disabled="!form.user.phonecg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                            <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-8">

                                            <div class="input-group">

                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg_therapy" :disabled="!form.user.emailcg">
                                                    </div>
                                                </div>

                                                <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                
                                            </div>
                                            
                                            <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                        </div>
                                    </div>
                                </div>                                
                            </div>    

                            <div class="row" v-if="DB.selected_duration > 0 && form.appointment.appointment_day_after_date != '' && form.appointment.appointment_day_after_time != '' && !form.appointment.isTherapyAppointment && form.appointment.outside != 1 && moment(form.appointment.date).format('YYYYMMDD') != moment().format('YYYYMMDD') && functionSpecialEnabled(11)">
                                <div class="col-lg-12 mt-3 text-left">
                                    <button class="btn btn-ifo" style="width:15%" type="button" @click="saveNewTherapyDate('', '', 0, '', '', 1)">                                                
                                        Aggiungi terapia
                                    </button>
                                </div> 
                            </div>


                            <div v-if="form.appointment.isBloodTestAppointment && form.appointment.blood_tests.totem_presence == 0 && !form.appointment.blood_tests.urgent">
                                <hr /> 
                                <div class="row">
                                    <div class="col-lg-6">                           
                                        <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi avvisare il paziente tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                            </small>
                                        </p>

                                        <div class="row" v-if="req_activation_therapy_program">
                                            <div class="col-lg-8">
                                                <div class="alert alert-danger">
                                                    Con il programma teraupetico attivo, il paziente verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" v-model="notify.phone_blood_test" :disabled="!form.user.phone">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email_blood_test" :disabled="!form.user.email">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">                           
                                        <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi avvisare il caregiver tramite SMS o email sulla creazione dell'appuntamento, seleziona la modalità che preferisci tra quelle che seguono.
                                            </small>
                                        </p>

                                        <div class="row" v-if="req_activation_therapy_program">
                                            <div class="col-lg-8">
                                                <div class="alert alert-danger">
                                                    Con il programma teraupetico attivo, il caregiver verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" v-model="notify.phonecg_blood_test" :disabled="!form.user.phonecg">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg_blood_test" :disabled="!form.user.emailcg">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                            </div>
                                        </div>
                                    </div>                                
                                </div>       


                                <button v-if="!functionDisabled(1, 'update')" @click="updateBloodTest" type="button" class="btn btn-ifo" :disabled="loaders.updating || disabled == 1">
                                
                                    <span v-show="!loaders.updating">
                                        Modifica
                                    </span>

                                    <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                </button>

                                <button v-if="!functionDisabled(1, 'delete')" class="btn btn-danger ml-3" type="button" :disabled="loaders.deleting" v-b-modal.modal_delete_blood_test @click="itemToDeleteBT=form.appointment.blood_tests.id">
                                        
                                    <span v-show="!loaders.deleting">
                                        Elimina
                                    </span>

                                    <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                </button> 
                            </div>  
                            

                            <!--PRELIEVO CON VISITA-->
                            <div class="row" v-if="form.appointment.appointment_blood_tests.length > 0">
                                <div class="col-lg-12">
                                    <hr />
                                    <p class="font-weight-bold m-1">
                                        Tutti i prelievi
                                    </p>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-lg-12" v-for="(app_blood_test, counter) in form.appointment.appointment_blood_tests" v-bind:key="counter">
                                    <!--form.appointment.appointments_day_after.id != app_therapy.id-->
                                    <div class="alert alert-default mt-1">
                                        <div class="row">
                                            <div class="col-lg-9 text-left font-weight-bold f-size-1-em">
                                                TIPO PRELIEVO: {{app_blood_test.blood_tests_type}}
                                                - 
                                                GIORNO {{moment(app_blood_test.date).format('DD/MM/YYYY')}} DALLE ORE {{moment(app_blood_test.h_start).format('HH:mm')}} 
                                            </div>
                                            
                                            <div class="col-lg-3  text-right"> 
                                                <button class="btnNoBg" type="button" @click="openUrl(0, app_blood_test.id, app_blood_test.id_appointment)" :disabled="!functionSpecialEnabled(11)">                                                
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>                                                        
                                                </button>                                                                                                       
                                            </div>     
                                        </div>
									</div>
								</div>
							</div>	

                            <div v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment"> 
                                <hr />
                                <div class="row mt-2 mb-2">
                                    <div class="col-lg-12 mb-2">                  
                                        <p class="font-weight-bold m-0">Aggiungi prelievo</p>
                                    </div>
                                </div>    
                                <div class="row mt-2 mb-2">    
                                    <div class="col-lg-4">
                                        <label for="id_blood_test_type"> Seleziona il tipo di prelievo</label>                                 
                                        <select class="form-control mb-2" id="id_blood_test_type" @change="onChangeBloodTestTypeU($event.target.value)" v-model="DB.blood_test_type">
                                            <option value="0">Seleziona</option>
                                            <option v-for="blood_test_type of DB.blood_test_types" :key="`bloodtesttypes_${blood_test_type.id}`" :value="blood_test_type.id">
                                                {{blood_test_type.title}}
                                            </option>
                                        </select> 

                                        <p id="visit_type_help" class="form-text text-muted mb-0">
                                            <small>
                                                
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-lg-4" v-show="DB.blood_test_type > 0">
                                        <div class="form-group">

                                            <label for="date_appointment">Data</label>

                                            <input type="date" class="form-control" id="date_blood_test" v-model="form.appointment.blood_test_date" :min="calendar.config.minDate" :max="calendar.config.maxDate" ref="date_blood_test">
                                            
                                            <small class="form-text text-muted"></small>
                                            
                                            <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_date.required && $v.form.appointment.blood_test_date.$dirty">
                                                Data terapia richiesta
                                            </div>
                                            <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_date.minValue && $v.form.appointment.blood_test_date.$dirty">
                                                La data deve essere impostata da domani
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-4" v-show="DB.blood_test_type > 0">
                                        <div class="form-group">

                                            <label for="appointment_h_start">Orario inizio</label>

                                            <input type="time" class="form-control" id="blood_test_h_start" v-model="form.appointment.blood_test_time" v-bind:class="{'is-invalid': !$v.form.appointment.blood_test_time.required && $v.form.appointment.blood_test_time.$dirty}">
                                            
                                            <small class="form-text text-muted"></small>

                                            <div class="invalid-feedback" v-show="!$v.form.appointment.blood_test_time.required && $v.form.appointment.blood_test_time.$dirty">
                                                Orario inizio richiesto
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row" v-show="DB.blood_test_type > 0 && form.appointment.blood_test_time && form.appointment.blood_test_date">
                                    <div class="col-lg-6">                           
                                        <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi avvisare il paziente tramite SMS o email sulla creazione del prelievo, seleziona la modalità che preferisci tra quelle che seguono.
                                            </small>
                                        </p>

                                        <div class="row" v-if="req_activation_therapy_program">
                                            <div class="col-lg-8">
                                                <div class="alert alert-danger">
                                                    Con il programma teraupetico attivo, il paziente verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" v-model="notify.phone_blood_test_u" :disabled="!form.user.phone">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email_blood_test_u" :disabled="!form.user.email">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">                           
                                        <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                                        <p class="text-muted mb-3">
                                            <small>
                                                Puoi avvisare il caregiver tramite SMS o email sulla creazione del prelievo, seleziona la modalità che preferisci tra quelle che seguono.
                                            </small>
                                        </p>

                                        <div class="row" v-if="req_activation_therapy_program">
                                            <div class="col-lg-8">
                                                <div class="alert alert-danger">
                                                    Con il programma teraupetico attivo, il caregiver verrà avvisato solo per la prima visita {{form.appointment.date | data_slash}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" v-model="notify.phonecg_blood_test_u" :disabled="!form.user.phonecg">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-8">

                                                <div class="input-group">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg_blood_test_u" :disabled="!form.user.emailcg">
                                                        </div>
                                                    </div>

                                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                                    
                                                </div>
                                                
                                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                                            </div>
                                        </div>
                                    </div>                                
                                </div>       
    
                                <button class="btn btn-ifo" type="button" @click="createBloodTest" :disabled="!DB.blood_test_type > 0 || !form.appointment.blood_test_time || !form.appointment.blood_test_date">
                                        
                                    <span v-show="!loaders.creating">
                                        Aggiungi
                                    </span>

                                    <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                </button>
                                

                            </div>                              

                        </form>

                    </div>

                </div>
                
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item" v-if="!form.appointment.isTherapyAppointment && !form.appointment.isBloodTestAppointment">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': form.appointment.room_presence || !form.appointment.totem_presence || !functionSpecialEnabled(3)}" @click="check_if_patient_is_present('invite_patient')">
                            <span class="d-none d-md-block">Invita paziente</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                            </svg>
                        </a>
                    </li>               
                    <li class="nav-item" v-if="!appointment_expired">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': form.appointment.totem_presence || !form.user.phone || loaders.sending.SMS || form.appointment.urgent}" v-b-modal.modal_send_SMS>
                            
                            <span class="d-none d-md-block" v-show="!loaders.sending.SMS">Notifica SMS paziente</span> <svg v-show="!loaders.sending.SMS" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                            </svg>

                            <div v-show="loaders.sending.SMS" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                    <li class="nav-item" v-if="caregiver_phone">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': form.appointment.totem_presence || !form.user.phonecg || loaders.sending.SMSCG || form.appointment.urgent}" v-b-modal.modal_send_SMSCG>
                            
                            <span class="d-none d-md-block" v-show="!loaders.sending.SMSCG">Notifica SMS caregiver</span> <svg v-show="!loaders.sending.SMSCG" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                            </svg>

                            <div v-show="loaders.sending.SMSCG" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>                   
                    <li class="nav-item" v-if="!appointment_expired">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': form.appointment.totem_presence || !form.user.email || loaders.sending.mail || form.appointment.urgent}" v-b-modal.modal_send_mail>
                            
                            <span class="d-none d-md-block" v-show="!loaders.sending.mail">Notifica mail paziente</span> <svg v-show="!loaders.sending.mail" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z"/>
                            </svg>

                            <div v-show="loaders.sending.mail" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                    <li class="nav-item" v-if="caregiver_email">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': form.appointment.totem_presence || !form.user.emailcg || loaders.sending.mailCG || form.appointment.urgent}" v-b-modal.modal_send_mailCG>
                            
                            <span class="d-none d-md-block" v-show="!loaders.sending.mailCG">Notifica mail caregiver</span> <svg v-show="!loaders.sending.mailCG" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z"/>
                            </svg>

                            <div v-show="loaders.sending.mailCG" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li> 
                    <li class="nav-item">
                        <router-link to="/user/agenda?vm=0&p=c" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Calendar view</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                            </svg>
                        </router-link>      
                    </li>                        
                    <li class="nav-item">
                        <router-link to="/user/agenda?vm=1&p=s" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Standard view</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-view-stacked ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                            </svg>
                        </router-link>      
                    </li>        
                    <li class="nav-item">
                        <router-link to="/user/agenda?listPharma=1&p=f" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Lista farmacia</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capsule-pill ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M11.02 5.364a3 3 0 0 0-4.242-4.243L1.121 6.778a3 3 0 1 0 4.243 4.243l5.657-5.657Zm-6.413-.657 2.878-2.879a2 2 0 1 1 2.829 2.829L7.435 7.536 4.607 4.707ZM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-.5 1.042a3 3 0 0 0 0 5.917V9.042Zm1 5.917a3 3 0 0 0 0-5.917v5.917Z"/>
                            </svg>                           
                        </router-link>  
                    </li> 
                    <li class="nav-item">
                        <router-link to="/user/agenda?vm=2&p=h" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Disponibilità poltrone</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-md-2 mt-md-1" viewBox="0 0 640 512">
                                <path d="M64 160C64 89.3 121.3 32 192 32H448c70.7 0 128 57.3 128 128v33.6c-36.5 7.4-64 39.7-64 78.4v48H128V272c0-38.7-27.5-71-64-78.4V160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48V448c0 17.7-14.3 32-32 32H576c-17.7 0-32-14.3-32-32H96c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3v48 32h32H512h32V320 272z"/>
                            </svg>     
                        </router-link>      
                    </li> 
                    <li class="nav-item">
                        <router-link to="/user/agenda?vm=3&p=b" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block">Sala prelievo</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-md-2 mt-md-1" viewBox="0 0 512 512">
                                <path d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"/>
                            </svg>         
                        </router-link>      
                    </li>                                                                                
                </ul>
            </div>

        </div>
        <b-modal id="calendar_therapy_first" size="lg" hide-footer hide-header centered>                                        
            <div class="row">
                <div class="col-6">
                    <button type="button" @click="updateYearPrev0();" class="btnCreateUpdateCalTherapy">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>                                   
                    </button>
                </div>
                <div class="col-6 text-right paddRight">
                    <button type="button" @click="updateYearNext0();" class="btnCreateUpdateCalTherapy">                                 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>                                         
                    </button>
                </div>
            </div>            
            <vue-cal   
                locale                = "it"
                ref                   = "vuecal_chairs"
                :min-date             = "minDate"
                :max-date             = "maxDate"
                :events               = "events"
                :time-from            = "timeFrom"
                :time-to              = "timeTo"
                :time-step            = "timeStep"
                :hide-weekdays        = "calendar.config.hideWeekDays"
                :hideWeekends         = true
                :selected-date        = "calendar.config.selectedDate"
                :split-days           = "splitDays"
                :sticky-split-labels  = "stickySplitLabels"
                :drag-to-create-event = "false"
                :transitions          = "false"
                :min-cell-width        ="minCellWidth"
                :min-split-width      = "minSplitWidth"                                     
                :disable-views        = "['years', 'year', 'month', 'months', 'week', 'weeks']"
                class                 = "mt-2 card-calendar-chairs createUpdateCal"                         
                
            />
            <!--@cell-click           = "setChairsDateCycle($event, itemToInsert)"-->
        </b-modal>        

        <b-modal id="modal_delete_appointment" size="sm" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler eliminare questo appuntamento?</p>

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_delete_appointment')">
                    No
                </button>
                <button class="btn btn-ifo ml-3 w-25" @click="deleteAppointment">
                    Si
                </button>

            </div>

        </b-modal>
        <b-modal id="modal_delete_therapy" size="lg" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler eliminare questa terapia?</p>

                <div class="row text-left">

                    <div class="col-lg-6">                           
                        <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                        <p class="text-muted mb-3">
                            <small>
                                Puoi avvisare il paziente tramite SMS o email sull'eliminazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                            </small>
                        </p>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" v-model="notify.phone" :disabled="!form.user.phone">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email" :disabled="!form.user.email">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">                           
                        <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                        <p class="text-muted mb-3">
                            <small>
                                Puoi avvisare il caregiver tramite SMS o email sulla creazione della terapia, seleziona la modalità che preferisci tra quelle che seguono.
                            </small>
                        </p>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" v-model="notify.phonecg" :disabled="!form.user.phonecg">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg" :disabled="!form.user.emailcg">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                            </div>
                        </div>
                    </div>                                
                </div>
                

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_delete_therapy')">
                    No
                </button>

                <button class="btn btn-ifo ml-3 w-25" @click="deleteTherapyDate(itemToDelete)">
                    Si
                </button>

            </div>

        </b-modal>   
        <b-modal id="modal_delete_blood_test" size="lg" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler eliminare questo prelievo?</p>

                <div class="row text-left">

                    <div class="col-lg-6">                           
                        <p class="font-weight-bold m-0">Notifiche per il paziente</p>

                        <p class="text-muted mb-3">
                            <small>
                                Puoi avvisare il paziente tramite SMS o email sull'eliminazione del prelievo, seleziona la modalità che preferisci tra quelle che seguono.
                            </small>
                        </p>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" v-model="notify.phone_blood_test" :disabled="!form.user.phone">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phone" v-model="form.user.phone">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.email_blood_test" :disabled="!form.user.email">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.email" v-model="form.user.email">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">                           
                        <p class="font-weight-bold m-0">Notifiche per il caregiver</p>

                        <p class="text-muted mb-3">
                            <small>
                                Puoi avvisare il caregiver tramite SMS o email sull'eliminazione del prelievo, seleziona la modalità che preferisci tra quelle che seguono.
                            </small>
                        </p>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" v-model="notify.phonecg_blood_test" :disabled="!form.user.phonecg">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" :disabled="!form.user.phonecg" v-model="form.user.phonecg">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare il numero</small>
                                <small class="form-text text-muted"><b>Non digitare</b> +39 e non digitare spazi</small>

                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-8">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" aria-label="Checkbox for following text input" v-model="notify.emailcg_blood_test" :disabled="!form.user.emailcg">
                                        </div>
                                    </div>

                                    <input type="text" class="form-control f-family-monospace" aria-label="Text input with checkbox" :disabled="!form.user.emailcg" v-model="form.user.emailcg">
                                    
                                </div>
                                
                                <small class="form-text text-muted">Se necessario puoi modificare l'indirizzo</small>

                            </div>
                        </div>
                    </div>                                
                </div>
                

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_delete_blood_test')">
                    No
                </button>

                <button class="btn btn-ifo ml-3 w-25" @click="deleteBloodtest(itemToDeleteBT)">
                    Si
                </button>

            </div>

        </b-modal>        

        <b-modal id="modal_send_SMS" size="sm" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler inviare una notifica SMS riepilogativa dell'appuntamento a questo numero?</p>

                <input type="text" class="form-control form-control-lg f-family-monospace text-center" id="CF" v-model="form.user.phone">
                <small class="form-text text-muted text-left"><b>Non digitare</b> +39 e non digitare spazi</small>

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_send_SMS')">
                    No
                </button>
                <button class="btn btn-ifo ml-3 w-25" @click="send_notify_SMS" :disabled="loaders.sending.SMS">
                
                    <span v-show="!loaders.sending.SMS">
                        Si
                    </span>

                    <div v-show="loaders.sending.SMS" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                </button>

            </div>

        </b-modal>
        <b-modal id="modal_send_SMSCG" size="sm" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler inviare una notifica SMS riepilogativa dell'appuntamento a questo numero?</p>

                <input type="text" class="form-control form-control-lg f-family-monospace text-center" id="CF" v-model="form.user.phonecg">
                <small class="form-text text-muted text-left"><b>Non digitare</b> +39 e non digitare spazi</small>

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_send_SMSCG')">
                    No
                </button>
                <button class="btn btn-ifo ml-3 w-25" @click="send_notify_SMSCG" :disabled="loaders.sending.SMSCG">
                
                    <span v-show="!loaders.sending.SMSCG">
                        Si
                    </span>

                    <div v-show="loaders.sending.SMSCG" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                </button>

            </div>

        </b-modal>        
        <b-modal id="modal_send_mail" size="sm" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler inviare una notifica mail riepilogativa dell'appuntamento a questo indirizzo?</p>

                <input type="email" class="form-control form-control-lg f-family-monospace text-center" id="CF" v-model="form.user.email">

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_send_mail')">
                    No
                </button>
                <button class="btn btn-ifo ml-3 w-25" @click="send_notify_mail" :disabled="loaders.sending.mail">
                    
                    <span v-show="!loaders.sending.mail">
                        Si
                    </span>

                    <div v-show="loaders.sending.mail" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                </button>

            </div>

        </b-modal>
        <b-modal id="modal_send_mailCG" size="sm" hide-footer hide-header centered>

            <div class="text-center">

                <p>Sicuro di voler inviare una notifica mail riepilogativa dell'appuntamento a questo indirizzo?</p>

                <input type="email" class="form-control form-control-lg f-family-monospace text-center" id="mailcg" v-model="form.user.emailcg">

                <hr class="w-25">

                <button class="btn btn-secondary w-25" @click="$bvModal.hide('modal_send_mailCG')">
                    No
                </button>
                <button class="btn btn-ifo ml-3 w-25" @click="send_notify_mailCG" :disabled="loaders.sending.mailCG">
                    
                    <span v-show="!loaders.sending.mailCG">
                        Si
                    </span>

                    <div v-show="loaders.sending.mailCG" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                </button>

            </div>

        </b-modal>       
        <b-modal id="modal_alert_patient_presence_with_pause" size="sm" hide-footer hide-header centered>

            <p>Nella stanza <strong class="text-uppercase"></strong> risulta già presente il paziente <strong>{{patient_room_presence.patient}}</strong>.</p>
            
            <div class="alert alert-danger">
                Non puoi ripristinare lo stato del paziente {{patient_room_presence.patient}} "in attesa" perché ha già effettuato una pausa.
            </div>

            <button class="btn btn-secondary btn-block" @click="clear_patient_room_presence('modal_alert_patient_presence_with_pause')">
                Chiudi
            </button>
            <button class="btn btn-ifo btn-block mb-1 mb-xl-0" @click="patient_room_presence.requester == 'invite_patient' ? invitePatient(false, 'modal_alert_patient_presence_with_pause') : backPatient(false, 'modal_alert_patient_presence_with_pause')">
                Visita entrambi
            </button>

        </b-modal>
        <b-modal id="modal_alert_patient_presence_with_therapy" size="sm" hide-footer hide-header centered>

            <p>Nella stanza <strong class="text-uppercase"></strong> risulta già presente il paziente <strong>{{patient_room_presence.patient}}</strong>.</p>

            <div class="alert alert-danger">
                Non puoi ripristinare lo stato del paziente {{patient_room_presence.patient}} "in attesa" perché ha già effettuato una terapia.
            </div>

            <button class="btn btn-secondary btn-block" @click="clear_patient_room_presence('modal_alert_patient_presence_with_therapy')">
                Chiudi
            </button>
            <button class="btn btn-ifo btn-block mb-1 mb-xl-0" @click="patient_room_presence.requester == 'invite_patient' ? invitePatient(false, 'modal_alert_patient_presence_with_therapy') : backPatientTherapy(false, 'modal_alert_patient_presence_with_therapy')">
                Visita entrambi
            </button>

        </b-modal>       
        <b-modal id="modal_alert_patient_presence" size="md" hide-footer hide-header centered>

            <div class="text-center">

                <p>Nella stanza <strong class="text-uppercase"></strong> risulta già presente il paziente <strong>{{patient_room_presence.patient}}</strong>.</p>
                
                <div class="alert alert-danger">Continuando ripristinerai lo stato di <strong>{{patient_room_presence.patient}}</strong> su "in attesa"</div>

                <p>Sicuro di voler continuare?</p>

                <hr class="w-25">

                <div class="row">
                    <div class="col-xl-6">
                        <button class="btn btn-secondary btn-block mb-1" @click="$bvModal.hide('modal_alert_patient_presence')">
                            No
                        </button>
                    </div>
                    <div class="col-xl-6">
                        <button class="btn btn-ifo btn-block mb-1 mb-xl-0" @click="patient_room_presence.requester == 'invite_patient' ? invitePatient(true) : backPatient(true)">
                            <span>
                                Si
                            </span>
                        </button>
                        
                    </div>
                    <div class="col-xl-6">
                        <button class="btn btn-ifo btn-block mb-1 mb-xl-0" @click="patient_room_presence.requester == 'invite_patient' ? invitePatient(false, 'modal_alert_patient_presence') : backPatient(false, 'modal_alert_patient_presence')">
                            Visita entrambi
                        </button>
                    </div>
                    <div class="col-xl-6">
                        <button class="btn btn-ifo btn-block mb-1" @click="go_to_appointment(patient_room_presence.id_appointment)" disabled>
                            {{patient_room_presence.patient}}
                        </button>
                    </div>
                </div>
                
            </div>

        </b-modal>
        
    </div>
</template>

<script>

    import VueCal from 'vue-cal'
    import moment from 'moment'
    import store  from '@/store'

    import 'vue-cal/dist/i18n/it.js'
    import 'vue-cal/dist/vuecal.css'


    //const today = new Date()
    var arr = new Array();  
    var objroom = new Array(); 
    var list2 = new Object(); 
    var list = new Array();     
    var availibilities = [];
    var newObj = {}; 
    var days_holiday_obj = {}
    var list2Holiday = new Object(); 
    var listHoliday = new Array();            
    var objroomRoomAvailable = [];
    var arrRoomAvailable = []; 
    var objroomMaxVisit = [];
    var arrMaxVisit = [];    
    
    import {
        required,
        maxLength
    } from "vuelidate/lib/validators"

    export default {
        components: {
            VueCal
        },
        data() {
            return {
                shownCal: false,
                stickySplitLabels: false,
                minCellWidth: 100,
                minSplitWidth: 100,  
                minDateToday: moment().format("YYYY-MM-DD"),
                minDate: new Date().addDays(1),
                maxDate:      null,
                timeFrom:     7*60,
                timeTo:       20*60,
                timeStep:     30,             
                splitDays: [],
                events: [],               
                show_therapy_start: 0,
                selected_duration: 0,
                edit_therapy: 0,
                deleteRow: 0,
                yearCal: new Date().getFullYear(),
                classCal: 1,  
                openTherapy: false,
                appointment_day_after_time_end: "", 
                chair_selected: "",
                chair_selected_id: "",
                chair_selected_02: "",
                chair_selected_02_id: "",
                outside: 2,
                update_note_app_value: "",
                update_therapy_app_value: "",
                update_taking_charge_description_value: "",
                update_medicine_ready_description_value: "",
                Update_preparation_pharma_description_value: "",
                update_prepared_pharma_description_value: "",
                update_patient_room_therapy_description_value: "",
                update_patient_room_blood_test_description_value: "",
                duration: [],
                DB: {
                    blood_test_types: [],
                    blood_test_type: 0, 
                    durations: [],
                    chairs: [],
                    chairsNewDate: [],
                    selected_duration: 0,
                    selected_duration_chair: 0,
                    selected_duration_chair_: 0,
                    detail_chair: []
                },                     
                pathologies: {
                    DB: []
                },
                rooms: {
                    DB: []
                },
                room: {
                    DB: []
                },   
                visit_types: {
                    DB: []
                }, 
                visit_type: {},                                   
                appointments: {
                    DB: []
                },
                appointments_patient: {
                    DB: []
                },  
                old: {
                    date:                      "",
                    h_start:                   ""
                },                                 
                form: {
                    chair_therapies: [{
                        selected_duration: '',
                        appointment_day_after_date: '',
                        appointment_day_after_time: '',
                        selected_duration_chair: '',
                        selected_duration_chair_: '',
                        chair_selected: '',
                        detail_chair: []
                    }],
                    user: {
                        number:     null,
                        CF:         null,
                        name:       null,
                        surname:    null,
                        date_birth: null,
                        phone:      null,
                        email:      null,
                        phonecg:    null,
                        emailcg:    null,
                        outside:    2                        
                    },
                    appointment: {
                        date:                      "",
                        h_start:                   "",
                        h_end:                     "",
                        id_patient:                null,
                        id_room:                   null,
                        id_visit_type:             0,
                        id_patient_pathology:      null,
                        totem_presence:            false,
                        room_presence:             false,
                        patient_pause:             false,
                        patient_pause_back:        false,
                        patient_pause_description: "",
                        urgent:                    false,
                        note_create:               null,
                        user: {
                            username: "",
                            name: "",
                            surname: ""
                        },
                        appointments_day_after: {
                            id: "",
                            date: "",
                            h_start: "",
                            h_end: "",
                            id_chair:                       0,
                            id_user_sel_chair:              null,
                            user_sel_chair_date:            null,
                            verify_description:             "",  
                            taking_charge_description: "",  
                            taking_charge_start_time: null,
                            preparation_pharma_description: "",  
                            prepared_pharma_description: "",  
                            id_taking_charge_user: null,
                            id_closer_pharma: null,
                            id_sent_pharmacy: null,
                            medicine_ready_description: "",  
                            medicine_ready_start_time: null,
                            sent_pharmacist: null,
                            sent_pharmacist_start_time: null,
                            preparation_pharma: null,
                            preparation_pharma_start_time: null,
                            prepared_pharma: null,
                            prepared_pharma_start_time: null,
                            pharmacist_therapy_description: "",
                            senderPharma: ""                                                       
                        },  
                        blood_tests: {
                            id: "",
                            date: "",
                            h_start: "",
                            id_user_sel_blood_test:                 null,
                            user_sel_blood_test_date:               null,
                            id_blood_test_type:                     0,
                            room_presence:                          false,
                            totem_presence:                         false,
                            urgent:                                 false,
                            id_nurse_closed_blood_test:             null,
                            nurse_closed_blood_test_date:           null,
                            patient_room_blood_test_description:    null,
                            note_create:                            null
                        },                                               
                        pause_start_time:               false,
                        patient_room_therapy:           false,
                        patient_room_therapy_description: "",
                        patient_therapy_back:           false,
                        therapy_start_time:             false,
                        room_therapy_called:            false,
                        pharmacist_therapy_description: "",
                        sent_pharmacist:                false,
                        sent_pharmacist_start_time:     false,
                        preparation_pharma:             false,
                        preparation_pharma_start_time:  false,
                        prepared_pharma:                false,
                        prepared_pharma_start_time:     false,
                        sent_nurses:                    false,
                        nurses_description:             "",
                        nurses_start_time:              false,
                        nurses_end_time:                false,
                        id_nurse_closed:                null,
                        therapy_nurses_end_time:        false,
                        deleteTherapy:                  0,
                        note_closed_nurses:             "",
                        sent_verify:                    false,
                        verify_description:             "",
                        verify_start_time:              false,
                        verify_end_time:                false,
                        taking_charge_description:      "",
                        taking_charge_start_time:       false,   
                        preparation_pharma_description: "",
                        prepared_pharma_description:    "",
                        nurseUser:                      "", 
                        id_nurse_closed_therapy:        null,
                        id_taking_charge_user:          null,
                        takeChargeUser:                 "",
                        id_closer_pharma:               null,
                        closerPharma:                   "",
                        id_sent_pharmacy:               null,
                        senderPharma:                   "",  
                        totem_number:                   null,
                        medicine_ready_description:     "",
                        medicine_ready_start_time:      false,
                        selected_duration:              0,
                        id_chair:                       0,
                        id_user_sel_chair:              null,
                        user_sel_chair_date:            null,
                        senderChair:                    "",
                        duration_chair:                 0,
                        chairName:                      "",
                        outside:                        2,
                        dateChair:                      "",
                        dateChairStart:                 "",
                        dateChairEnd:                   "",
                        appointment_day_after_date:     "",
                        appointment_day_after_time:     "",
                        appointment_day_after_note_create: "",
                        appointment_blood_tests:          [{
                            id: "",
                            date: "",
                            h_start: "",
                            id_blood_test_type: 0,
                            id_appointment: 0                                                 
                        }],
                        appointment_therapies:          [{
                            id: "",
                            date: "",
                            h_start: "",
                            h_end: "",
                            id_chair: 0,
                            id_user_sel_chair: null,
                            user_sel_chair_date: null,
                            verify_description: "",  
                            taking_charge_description: "",  
                            taking_charge_start_time: null,
                            preparation_pharma_description: "",  
                            prepared_pharma_description: "",  
                            id_taking_charge_user: null,
                            id_closer_pharma: null,
                            id_sent_pharmacy: null,
                            medicine_ready_description: "",  
                            medicine_ready_start_time: null,
                            sent_pharmacist: null,
                            sent_pharmacist_start_time: null,
                            preparation_pharma: null,
                            preparation_pharma_start_time: null,
                            prepared_pharma: null,
                            prepared_pharma_start_time: null,
                            pharmacist_therapy_description: "",
                            outside: 2,
                            note_create: null                                                       
                        }],
                        isTherapyAppointment:           false,
                        isBloodTestAppointment:         false,
                        appointment_day_after_id_chair: 0,
                        blood_test_date: "",
                        blood_test_h_start: ""
                    },
                    pathology: {
                        title: null
                    },
                    period: null,
                    room:   null,
                    visit_type:   null,
                    patients_pathologies: {
                        id_therapy: null
                    },
                    closing: {
                        note: ""
                    },
                    form_edit_therapy: {
                        id:                   0,
                        description:          null,
                        id_patient_pathology: null,
                        id_therapy:           null,
                        title_old_therapy:    null,
                        therapy_on_select:    null,
                        modifier_user:        this.$store.state.auth.username
                    }
                },
                search: {
                    names:    [],
                    surnames: [],
                    CFs:      []
                },
                validation: {
                    ko: false,
                    ko_update_therapy: false,
                    ok_update_therapy: false,
                    conflict:          false
                },
                loaders: {
                    updating: false,
                    deleting: false,
                    reading: {
                        therapy: true,
                        rooms: false
                    },
                    closing: false,
                    closing_nurses: false,
                    sending: {
                        SMS: false,
                        mail: false,
                        SMSCG: false,
                        mailCG: false                       
                    },
                    update_therapy: false,
                    delete_therapy: false
                },
                chart: {
                    options: {
                        legend: {
                            display: false
                        }
                    },
                    dataCollection: {
                        labels: [
                            'Appuntamenti effettuati',
                            'Appuntamenti da effettuare'
                        ],
                        datasets: [
                            {
                                data: [0, 0],
                                backgroundColor: [
                                    '#5c1b24',
                                    '#343a40'
                                ]
                            }
                        ]
                    },
                    myLegend: {
                        effettuate:   0,
                        daEffettuare: 0
                    },
                    update: 0
                    
                },
                patients_therapies:  [],
                patients_pathologies: [],
                therapy: null,
                calendar: {
                    config: {
                        minDate:      moment().add(1, "days").format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     20,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [6,7],
                        selectedDate: null,
                        selectedDate_: null,
                        specialHours: []  
                    }
                },
                notify: {
                    phone: false,
                    email: false,
                    phonecg: false,
                    emailcg: false,
                    phone_therapy: false,
                    email_therapy: false,
                    phonecg_therapy: false,
                    emailcg_therapy: false,
                    phone_blood_test : false,
                    email_blood_test : false,
                    phonecg_blood_test : false,
                    emailcg_blood_test : false,
                    phone_blood_test_u : false,
                    email_blood_test_u : false,
                    phonecg_blood_test_u : false,
                    emailcg_blood_test_u : false                                                                                                                        
                },
                time_delay: {
                    ago: "",
                    type: ""
                },
                therapies: {
                    DB: []
                },
                min_date_appointment: moment().add(1, "days").format("YYYY-MM-DD"),
                patient_room_presence: false
            }
        },
        validations() {
            return {
                disabled: 0,
                form: {
                    appointment: {
                        patient_pause_description: {
                            maxLength: maxLength(700)
                        },
                        blood_test_date: {
                            required,
                            minValue: value => value > moment().format("YYYY-MM-DD")
                        },
                        blood_test_time: {
                            required
                        },                    
                        date: {
                            required,
                            minValue: value => moment(value).isSameOrAfter(moment().format('YYYY-MM-DD'))
                        },
                        h_start: {
                            required
                        },
                        h_end: {
                            required
                        },
                        appointment_day_after_date: {
                            required
                        },
                        appointment_day_after_time: {
                            required
                        },                        
                        patient_room_therapy_description: {
                            maxLength: maxLength(700)
                        },
                        pharmacist_therapy_description: {
                            maxLength: maxLength(700)
                        },
                        nurses_description: {
                            maxLength: maxLength(700)
                        },
                        note_closed_nurses: {
                            maxLength: maxLength(700)
                        },
                        appointments_day_after: {
                            verify_description: {
                                maxLength: maxLength(700)
                            }, 
                            medicine_ready_description: {
                                maxLength: maxLength(700)
                            },
                            taking_charge_description: {
                                maxLength: maxLength(700)
                            }, 
                            preparation_pharma_description: {
                                maxLength: maxLength(700)
                            },  
                            prepared_pharma_description: {
                                maxLength: maxLength(700)
                            },
                            patient_room_therapy_description: {
                                maxLength: maxLength(700)
                            },                            
                        },
                        blood_tests: {
                            date: {
                                required,
                                minValue: value => moment(value).isSameOrAfter(moment().format('YYYY-MM-DD'))
                            },
                            h_start: {
                                required
                            },
                        },
                        verify_description: {
                            maxLength: maxLength(700)
                        }, 
                        taking_charge_description: {
                            maxLength: maxLength(700)
                        },
                        preparation_pharma_description: {
                            maxLength: maxLength(700)
                        } ,
                        prepared_pharma_description: {
                            maxLength: maxLength(700)
                        },
                        medicine_ready_description: {
                            maxLength: maxLength(700)
                        }                                                                                                                                                                                  
                    },
                    closing: {
                        note: {
                            maxLength: maxLength(700)
                        }
                    },
                    form_edit_therapy: {
                        description: {
                            maxLength: maxLength(700)
                        }
                    }
                }
            }
        },    
        methods: {   
            async createBloodTest() {

                this.loaders.creating = true
                this.validation.ko    = false

                // Costruzione oggetto da inviare all'API
                const OBJ = {
                    form: this.form,
                    id_blood_test_type: this.DB.blood_test_type,
                    notify: {
                        email: this.notify.email_blood_test_u,
                        phone: this.notify.phone_blood_test_u,
                        emailcg: this.notify.emailcg_blood_test_u,
                        phonecg: this.notify.phonecg_blood_test_u                     
                    }
                }

                try {
                    const res = await this.axios.post(`user/appointments/create_blood_test`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.replace(`/user/agenda`)
                    this.$router.go(this.$router.currentRoute)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }

            },       
            async read_blood_test_types() {
                try {

                   const blood_test_types = (
                        await this.axios.get(`user/blood_test_type/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.DB.blood_test_types = blood_test_types;
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            }, 
            updateNoteAppValue(value){
                this.update_note_app_value = value
            },             
            updateTherapyAppNote(value){
                this.update_therapy_app_value = value
            },                           
            update_taking_charge_description(value){
                this.update_taking_charge_description_value = value
            },
            update_medicine_ready_description(value){
                this.update_medicine_ready_description_value = value
            },
            update_preparation_pharma_description(value){
                this.update_preparation_pharma_description_value = value
            },   
            update_prepared_pharma_description(value){
                this.update_prepared_pharma_description_value = value
            }, 
            update_patient_room_therapy_description(value){
                this.update_patient_room_therapy_description_value = value
            }, 
            update_patient_room_blood_test_description(value){
                this.update_patient_room_blood_test_description_value = value
            },                                                          
            openUrl(id_therapy, id_blood_test, id_appointment) {

                const {
                    id
                } = this.$route.params  
                
                if(!this.functionDisabled(10, "read")) {
                    if(id_therapy > 0 && id_blood_test == 0){
                        this.$router.replace(`/user/agenda/modifica/${id}/${id_therapy}`)
                        this.$router.go(this.$router.currentRoute)
                        
                    } else if(id_therapy == 0 && id_blood_test > 0){
                        this.$router.replace(`/user/agenda/modifica/${id_appointment}/${id_blood_test}`)
                        this.$router.go(this.$router.currentRoute)
                        
                    } else {
                        this.$router.replace(`/user/agenda/modifica/${id}`).catch(()=>{});
                    }
                }
                else {
                    this.$toast.open({
                        message: "Non sei abilitato a visualizzare il dettaglio dell'appuntamento",
                        type:   "error"
                    })
                }
            },        
            addTherapyChair(){
                if(this.form.chair_therapies.length == 0){
                    this.form.chair_therapies.push({
                        selected_duration: "",
                        appointment_day_after_date: "",
                        appointment_day_after_time: "",
                        selected_duration_chair: 0,
                        selected_duration_chair_: 0,
                        detail_chair: []
                    })
                }

                this.openTherapy = true
            },
            deleteTherapyChair(counter){
                this.form.chair_therapies.splice(counter,1);  
                this.openTherapy = false 
            },        
            onChangeDateTherapy(event){
                this.DB.selected_duration_chair = 0
                this.DB.selected_duration_chair_ = 0
                this.DB.detail_chair = []
                this.read_chairs_with_appointments()
                this.form.appointment.appointment_day_after_time == this.form.appointment.h_start

                if(event == 1){
                    
                    this.form.appointment.appointment_day_after_date == this.form.appointment.date
                    
                    this.calendar.config.selectedDate = this.form.appointment.date      

                } else if(event == 0){
                    this.form.appointment.appointment_day_after_date == moment(this.form.appointment.date).add(1, "day").format("YYYY-MM-DD")
                    this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date  

                }
            },           
            updateTherapy(){
                this.edit_therapy = 1
            },    
            updateTherapyReset(){
                this.edit_therapy = 0;
            }, 
            
            async deleteBloodtest(id){                 

                const OBJ = {
                    id:  id == 0 ? this.form.appointment.blood_tests.id : id,
                    id_appointment: this.$route.params.id ,
                    form: this.form,
                    notify: {
                        email: this.notify.email_blood_test,
                        phone: this.notify.phone_blood_test,
                        emailcg: this.notify.emailcg_blood_test,
                        phonecg: this.notify.phonecg_blood_test                       
                    }                                         
                } 

                try {

                    const bloodtest = await this.axios.post(`user/appointments/deleteBloodTest`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: bloodtest.data.msg,
                        type:   `success`
                    })

                    this.$router.replace(`/user/agenda`)
                    this.$router.go(this.$router.currentRoute)

                } catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                } 


                this.DB.selected_duration = 0

            },

            async deleteTherapyDate(id){                 

                    const OBJ = {
                        id: id == 0 ? this.form.appointment.appointments_day_after.id : id,
                        id_appointment:     this.$route.params.id ,
                        form: this.form,
                        notify: {
                            email: this.notify.email,
                            phone: this.notify.phone,
                            emailcg: this.notify.emailcg,
                            phonecg: this.notify.phonecg                       
                        }                                         
                    } 

                    try {

                        const newTherapyDate = await this.axios.post(`user/appointments/deleteTherapy`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.$toast.open({
                            message: newTherapyDate.data.msg,
                            type:   `success`
                        })

                        this.$router.replace(`/user/agenda`)
                        this.$router.go(this.$router.currentRoute)

                    } catch(e) {

                        this.$toast.open({
                            message: e.response.data.msg,
                            type:   `error`
                        })
                    } 
                    

                    this.DB.selected_duration = 0

            },
            async saveNewTherapyDate(date, time, id_chair_, id_chair, id_duration, type){

                if(id_chair_ > 0){
                    id_chair = id_chair_

                } 
                
                const OBJ = {
                    date: date == "" ? this.form.appointment.appointment_day_after_date : date,
                    time: date == "" ? this.form.appointment.appointment_day_after_time : time,
                    end: date == "" ? this.appointment_day_after_time_end : time,
                    id: type == 1 ? 0 : this.form.appointment.appointments_day_after.id,
                    id_appointment:     this.$route.params.id,
                    id_chair: id_chair == "" ? this.form.appointment.id_chair : id_chair,
                    id_duration: id_duration == "" ? this.DB.selected_duration : id_duration,
                    note_create: this.appointment_day_after_note_create == "" ? "" : this.appointment_day_after_note_create,
                    notify: {
                        email: this.notify.email_therapy,
                        phone: this.notify.phone_therapy,
                        emailcg: this.notify.emailcg_therapy,
                        phonecg: this.notify.phonecg_therapy                     
                    },
                    form: this.form                                        
                }

                try {

                    const newTherapyDate = await this.axios.post(`user/appointments/newTherapyDate`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: newTherapyDate.data.msg,
                        type:   `success`
                    })

                    this.DB.selected_duration = 0
                    this.chair_selected = ""
                    this.form.appointment.appointment_day_after_date = ""
                    this.form.appointment.appointment_day_after_time = ""

                    this.$router.go(this.$router.currentRoute)                   

                } catch(e) {
                

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                } 
                

                this.DB.selected_duration = 0
                this.chair_selected = ""
                this.form.appointment.appointment_day_after_date = ""
                this.form.appointment.appointment_day_after_time = ""
                
            }, 

            async saveNewNextTherapyDate(date, time, id_chair_, id_chair, id_duration){

                if(id_chair_ > 0){
                    id_chair = id_chair_
                } 

                const OBJ = {
                    date: date == "" ? this.form.appointment.appointment_day_after_date : date,
                    time: date == "" ? this.form.appointment.appointment_day_after_time : time,
                    id: this.form.appointment.appointments_day_after.id,
                    id_appointment:     this.$route.params.id,
                    id_chair: id_chair == "" ? this.form.appointment.id_chair : id_chair,
                    id_duration: id_duration == "" ? this.DB.selected_duration : id_duration                    
                }

                try {

                    const newTherapyDate = await this.axios.post(`user/appointments/newNextTherapyDate`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: newTherapyDate.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)                   

                } catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                } 


                this.DB.selected_duration = 0
            },             

            updateYearNext(){
                this.$refs.vuecal.next();         
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;                    
                },300); 
            },

            updateYearPrev(){
                this.$refs.vuecal.previous(); 
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;
                },300); 
            }, 
            
 
            updateYearNext3(){
                this.$refs.vuecal_chairs_03.next();         
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;                    
                },300); 
            },

            updateYearPrev3(){
                this.$refs.vuecal_chairs_03.previous(); 
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;
                },300); 
            },            

            updateYearNext1(){
                this.$refs.vuecal_chairs_01.next();         
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;                    
                },300); 
            },

            updateYearPrev1(){
                this.$refs.vuecal_chairs_01.previous(); 
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;
                },300); 
            }, 
            
            updateYearNext0(){
                this.$refs.vuecal_chairs.next();         
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;                    
                },300); 
            },

            updateYearPrev0(){
                this.$refs.vuecal_chairs.previous(); 
                setTimeout(()=>{
                    var datefull = document.querySelector(".vuecal__title button");
                    datefull = datefull.innerHTML;
                    var lastChars = datefull.substr(datefull.length - 5);
                    var year = lastChars.replace(')', '');
                    this.yearCal = year;
                },300); 
            },             
 
            setChairsDate(event) {

                const date = event.date
                const chair = event.split

                this.form.appointment.id_chair = chair

                this.read_detail_chair(chair)

                this.DB.selected_duration_chair_ = chair
                

                this.form.appointment.appointment_day_after_date    = moment(date).format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(date).format("HH:mm")

                var h_end_th = this.form.appointment.appointment_day_after_date + ' ' + this.form.appointment.appointment_day_after_time;

                
                var duration = this.duration[0].duration;
                h_end_th = moment(h_end_th).add(duration, 'minutes').toDate(); 
                h_end_th = moment(h_end_th).format("HH:mm"); 
                
                this.appointment_day_after_time_end = h_end_th;    

                this.loaders.updating = false;
            },  
            setChairsDate_(event, counter) {

                const date = event.date
                const chair = event.split

                this.read_detail_chair_(chair, counter)

                this.form.chair_therapies[counter].selected_duration_chair_ = chair


                this.form.chair_therapies[counter].appointment_day_after_date    = moment(date).format("YYYY-MM-DD")
                this.form.chair_therapies[counter].appointment_day_after_time    = moment(date).format("HH:mm")

                this.loaders.updating = false;
            }, 
            setChairsDate_therapy(event) {

                const date = event.date
                const chair = event.split

                this.form.appointment.id_chair = chair

                this.read_detail_chair(chair)

                this.form.appointment.appointment_day_after_date    = moment(date).format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(date).format("HH:mm")

                var h_end_th = this.form.appointment.appointment_day_after_date + ' ' + this.form.appointment.appointment_day_after_time;

                var duration = this.duration[0].duration;
                h_end_th = moment(h_end_th).add(duration, 'minutes').toDate(); 
                h_end_th = moment(h_end_th).format("HH:mm"); 
                
                this.appointment_day_after_time_end = h_end_th;

                this.loaders.updating = false;
            },  

            readChairs() {
                this.read_chairs_with_appointments()
                this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date
            },
            readChairs_(counter) {
                this.read_chairs_with_appointments()
                this.calendar.config.selectedDate_ = this.form.chair_therapies[counter].appointment_day_after_date
            },
            readChairsTime(){
                var h_end_th = this.form.appointment.appointment_day_after_date + ' ' + this.form.appointment.appointment_day_after_time;
                var duration = this.duration[0].duration;
                h_end_th = moment(h_end_th).add(duration, 'minutes').toDate(); 
                h_end_th = moment(h_end_th).format("HH:mm"); 

                this.appointment_day_after_time_end = h_end_th;    
                this.read_chairs_with_appointments()
                this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date
            },   
            
            
            onChangeBloodTestType(event) {

                this.form.appointment.blood_tests.id_blood_test_type = event
            }, 

            onChangeBloodTestTypeU(event) {
                this.DB.blood_test_type = event
            }, 
         
            async onChangeDuration(event, shown) {
                if(shown == 1){
                    this.shownCal = true
                }
                /*const OBJ = {
                    id: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    h_start: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_start : this.form.appointment.date + 'T' + this.form.appointment.h_start + ':00.000Z',
                    h_end: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_end : this.form.appointment.date + this.form.appointment.h_end + ':00.000Z',
                    date: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.date : this.form.appointment.date + '00:00:00.000Z'             
                }*/
                this.read_chairs_with_appointments()
                this.DB.selected_duration = event
                this.form.appointment.duration_chair = event

                //if(this.form.appointment.isTherapyAppointment){
                    this.read_duration()
                //}               

                this.chair_selected = ""
                //this.form.appointment.appointment_day_after_date = ""
                //this.form.appointment.appointment_day_after_time = ""

                try {
                    /*const chairs_unavailables = (
                        await this.axios.post(`user/chairs/read/all_by_duration/exclude/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                    this.DB.chairs = (
                        await this.axios.post(`user/chairs/read/all_by_duration/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data */                   

                    this.DB.chairs = (
                        await this.axios.get(`user/chairs/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data 
                    
                    /*chairs_unavailables.forEach((value) => {   
                        var chair_unavailables_ = value.id_chair
                    
                        this.DB.chairs = this.DB.chairs.filter(d => d.id != chair_unavailables_);
                    })*/                      
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }                     
       
    
            },  

            async onChangeDuration_(event, counter) {
                const OBJ = {
                    id: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    h_start: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_start : this.form.appointment.date + 'T' + this.form.appointment.h_start + ':00.000Z',
                    h_end: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.h_end : this.form.appointment.date + this.form.appointment.h_end + ':00.000Z',
                    date: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.date : this.form.appointment.date + '00:00:00.000Z'             
                }
                this.read_chairs_with_appointments()
                this.form.chair_therapies[counter].selected_duration = event
                this.form.appointment.duration_chair = event

                try {
                    const chairs_unavailables = (
                        await this.axios.post(`user/chairs/read/all_by_duration/exclude/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.DB.chairs = (
                        await this.axios.post(`user/chairs/read/all_by_duration/${event}`, OBJ, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data 
                    
                    chairs_unavailables.forEach((value) => {   
                        var chair_unavailables_ = value.id_chair
                    
                        this.DB.chairs = this.DB.chairs.filter(d => d.id != chair_unavailables_);
                    })                      
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }                     
       
    
            },  



            onChangeChair(event) {
                this.DB.detail_chair = []
                this.DB.selected_duration_chair = 0
                this.DB.selected_duration_chair_ = 0
                this.DB.selected_duration_chair = event.target.value
                this.form.appointment.id_chair = event.target.value
                if(this.form.appointment.isTherapyAppointment){
                    this.read_detail_chair(event.target.value)
                }
                
            }, 

            onChangeChair_(event, counter) {
                this.form.chair_therapies[counter].detail_chair = []
                this.form.chair_therapies[counter].selected_duration_chair = 0
                this.form.chair_therapies[counter].selected_duration_chair_ = 0
                this.form.chair_therapies[counter].selected_duration_chair = event.target.value
            },             
                       
            async read_durations() {
                try {

                    this.DB.durations = (
                        await this.axios.get(`user/chairs_duration/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
            async read_duration() {
                try {

                    this.duration = (
                        await this.axios.get(`user/chairs_duration/read/detailId/${this.DB.selected_duration}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    var h_end_th = this.form.appointment.appointment_day_after_date + ' ' + this.form.appointment.appointment_day_after_time;
                    var duration = this.duration[0].duration;
                    h_end_th = moment(h_end_th).add(duration, 'minutes').toDate(); 
                    h_end_th = moment(h_end_th).format("HH:mm"); 

                    this.appointment_day_after_time_end = h_end_th;                        

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },               
            async read_detail_chair(id) {
                try {

                    this.DB.detail_chair = (
                        await this.axios.get(`user/chairs/read/detail/${id}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.chair_selected = this.DB.detail_chair.title + ' ' + this.DB.detail_chair.number
                    this.chair_selected_id = this.form.appointment.appointment_day_after_id_chair

                    this.chair_selected_02 = this.DB.detail_chair.title + ' ' + this.DB.detail_chair.number
                    this.chair_selected_02_id = this.DB.detail_chair.id
                    

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            }, 
            async read_detail_chair_(id, counter) {
                try {

                    this.form.chair_therapies[counter].detail_chair = (
                        await this.axios.get(`user/chairs/read/detail/${id}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },                            
            async read_chairs() {
                try {
                    this.DB.chairs = (
                        await this.axios.get(`user/chairs/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data                   
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
            async read_chairs_with_appointments() {

                const id = this.$route.params.id

                try {
                    const chairs_ = (
                        await this.axios.post(`user/chairs/read/all_with_appointments`, {id}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.splitDays = []
                    this.events = []
                    var splitDays_obj = {} 
                    var events_obj = {}       
                    for(const chair of chairs_) {

                        splitDays_obj = {
                            id: chair.id, 
                            class: "chair_split", 
                            label: chair.title + " "  + chair.number
                        }

                        if(chair.appointments){

                        
                            for(const appointment of chair.appointments) {
                                if(appointment.appointments_day_after){
                                    for(const appointment_day_after of appointment.appointments_day_after) {
                                        events_obj = {
                                            start: moment(appointment_day_after.h_start).format('YYYY-MM-DD HH:mm'),
                                            end: moment(appointment_day_after.h_end).format('YYYY-MM-DD HH:mm'),
                                            title: chair.title + ' '  + chair.number + ' occupata dalle ore ' + moment(appointment_day_after.h_start).format('HH:mm') + ' alle ore: '  + moment(appointment_day_after.h_end).format('HH:mm'),
                                            content: '',
                                            class: 'chairs_event',
                                            split: chair.id                            
                                        } 
                                        this.events.push(events_obj)   
                                    }      
                                }                                                
                            } 
                        }

                        if(chair.appointments_day_after){

                        
                            for(const appointment_day_after of chair.appointments_day_after) {
                                events_obj = {
                                    start: moment(appointment_day_after.h_start).format('YYYY-MM-DD HH:mm'),
                                    end: moment(appointment_day_after.h_end).format('YYYY-MM-DD HH:mm'),
                                    title: chair.title + ' '  + chair.number + ' occupata dalle ore ' + moment(appointment_day_after.h_start).format('HH:mm') + ' alle ore: '  + moment(appointment_day_after.h_end).format('HH:mm'),
                                    content: '',
                                    class: 'chairs_event',
                                    split: chair.id                            
                                } 
                                this.events.push(events_obj)   
                            } 
                        }                       


                        this.splitDays.push(splitDays_obj)

                        
                    }  
                    
                    
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },              
            async selectChair() {
                
                const OBJ = {
                    id_appointment:     this.$route.params.id,
                    id_chair:           this.form.appointment.id_chair                 
                }               
          

                try {

                    const pause = await this.axios.post(`user/appointments/sentChair`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },             
            
            async readVisitTypes() {

                try {

                    const visit_types = await this.axios.get(`user/visit_types/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.visit_types.DB = visit_types.data  

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.visit_types.DB = []

                }

            },

            async readVisitType(visittypeid) {
                try {

                    const visit_type = await this.axios.get(`user/visit_types/read/detailId/${visittypeid}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.visit_type = visit_type.data[0].duration 
                    this.updateTimeEndAppointment() 
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.visit_type = 0

                }

            }, 


            async readAppointment() {

                // ID appuntamento
                const {
                    id,
                    id_therapy
                } = this.$route.params

                try {

                    const appointment = await this.axios.get(`user/appointments/read/detail/${id}/${id_therapy}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    // Recupero informazioni paziente
                    const {
                        CF,
                        name,
                        surname,
                        phone,
                        email,
                        phonecg,
                        emailcg,                        
                        note
                    } = appointment.data.patient_pathology.patient

                    const date_birth = moment(
                        appointment.data.patient_pathology.patient.date_birth
                    ).format(`YYYY-MM-DD`)

                    // Recupero informazioni appuntamento
                    const {
                        date,
                        h_start,
                        h_end,
                        id_room,
                        id_visit_type,
                        id_patient_pathology,
                        totem_presence,
                        pathologies,
                        room_presence,
                        patient_pause,
                        patient_pause_back,
                        patient_pause_description,
                        appointment_closed,
                        therapy_programId,
                        user,
                        totem_presence_arrival,
                        totem_presence_arrival_full,
                        h_start_full,
                        room_presence_arrival,
                        urgent,
                        pause_start_time,
                        pause_end_time,
                        appointment_medical_initializer,
                        appointments_day_after,
                        blood_tests,
                        patient_room_therapy,
                        patient_room_therapy_description,
                        patient_therapy_back,
                        therapy_start_time,
                        therapy_end_time,
                        room_therapy_called ,
                        pharmacist_therapy_description,
                        sent_pharmacist,
                        sent_pharmacist_start_time,
                        preparation_pharma,
                        preparation_pharma_start_time,
                        prepared_pharma,
                        prepared_pharma_start_time,
                        sent_nurses,
                        nurses_start_time, 
                        nurses_end_time,
                        nurses_description,
                        id_nurse_closed,
                        note_closed_nurses,
                        therapy_nurses_end_time,
                        sent_verify,
                        verify_description,
                        verify_start_time,
                        verify_end_time,
                        taking_charge_description,
                        taking_charge_start_time,
                        preparation_pharma_description,
                        prepared_pharma_description,
                        nurseUser,
                        id_nurse_closed_therapy,
                        id_taking_charge_user,
                        takeChargeUser,
                        id_closer_pharma,
                        closerPharma,
                        id_sent_pharmacy,
                        senderPharma,
                        totem_number,
                        medicine_ready_description,
                        medicine_ready_start_time,
                        id_chair,
                        id_user_sel_chair,
                        user_sel_chair_date,
                        senderChair,
                        duration_chair,
                        chairName,
                        dateChair,
                        dateChairStart,
                        dateChairEnd,
                        appointment_day_after_date,
                        appointment_day_after_time,
                        appointment_therapies,
                        appointment_blood_tests,
                        isTherapyAppointment,
                        isBloodTestAppointment,
                        therapyAppChairName,
                        therapyAppSenderChair,
                        appointment_day_after_time_end,
                        appointment_day_after_id_chair,
                        outside,
                        blood_tests_date,
                        blood_tests_h_start,
                        id_blood_test_type,
                        note_create
                    } = appointment.data
                    

                    if(appointments_day_after){
                        if(moment(appointments_day_after.date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){ 
                            this.show_therapy_start = 1
                        }                       
                    }

                    this.form.user = {
                        CF,
                        name,
                        surname,
                        date_birth,
                        phone,
                        email,
                        phonecg,
                        emailcg,  
                        outside,                 
                        note: !note ? "Nessuna nota sul paziente ricercato." : note                    
                    } 

                    this.old.date                                      = appointment.data.date
                    this.old.h_start                                   = appointment.data.h_start                   

                    this.form.user.number                              = appointment.data.rooms.title
                    this.form.patients_pathologies.id_therapy          = appointment.data.patient_pathology.id_therapy
                    this.form.form_edit_therapy.id_therapy             = appointment.data.patient_pathology.id_therapy
                    this.form.patients_pathologies.therapy_cronologies = appointment.data.patient_pathology.therapy_cronologies
                    
                    if(appointment.data.patient_pathology.patient.patients_pathologies){
                        
                        this.patients_pathologies = appointment.data.patient_pathology.patient.patients_pathologies
                    }
                    

                    this.selected_duration = appointment.data.duration_chair

                    this.form.appointment = {
                        date,
                        h_start,
                        h_end,
                        id_room,
                        id_visit_type,
                        id_patient_pathology,
                        totem_presence,
                        pathologies,
                        room_presence,
                        patient_pause,
                        patient_pause_back,
                        patient_pause_description,
                        appointment_closed,
                        therapy_programId,
                        user,
                        totem_presence_arrival,
                        totem_presence_arrival_full,
                        h_start_full,
                        room_presence_arrival,
                        urgent,
                        pause_start_time,
                        pause_end_time,
                        appointments_day_after,
                        blood_tests,
                        appointment_medical_initializer,
                        patient_room_therapy,
                        patient_room_therapy_description,  
                        patient_therapy_back,
                        therapy_start_time,
                        therapy_end_time,
                        room_therapy_called,
                        pharmacist_therapy_description,
                        sent_pharmacist,
                        sent_pharmacist_start_time,
                        preparation_pharma,
                        preparation_pharma_start_time,
                        prepared_pharma,
                        prepared_pharma_start_time,
                        sent_nurses,
                        nurses_start_time, 
                        nurses_end_time,
                        nurses_description,
                        id_nurse_closed,
                        note_closed_nurses,
                        therapy_nurses_end_time,
                        sent_verify,
                        verify_description,
                        verify_start_time,
                        verify_end_time,
                        taking_charge_description,
                        taking_charge_start_time,
                        preparation_pharma_description,
                        prepared_pharma_description,
                        nurseUser,
                        id_nurse_closed_therapy,
                        id_taking_charge_user,
                        takeChargeUser,
                        id_closer_pharma,
                        closerPharma,
                        id_sent_pharmacy,
                        senderPharma,
                        totem_number,
                        medicine_ready_description, 
                        medicine_ready_start_time,
                        id_chair,
                        id_user_sel_chair,
                        user_sel_chair_date,
                        senderChair,
                        duration_chair,
                        chairName,
                        dateChair,
                        dateChairStart,
                        dateChairEnd,
                        appointment_day_after_date,
                        appointment_day_after_time,
                        appointment_therapies,
                        appointment_blood_tests,
                        isTherapyAppointment,
                        isBloodTestAppointment,
                        therapyAppChairName,
                        therapyAppSenderChair,
                        appointment_day_after_time_end,
                        appointment_day_after_id_chair,
                        outside,
                        note_create
                    }


                    if(appointment_closed){
                        this.form.closing.note = appointment_closed.note
                    }
                    
                            
                    this.form.appointment.blood_tests.date = blood_tests_date
                    this.form.appointment.blood_tests.h_start = blood_tests_h_start
                    this.form.appointment.blood_tests.id_blood_test_type = id_blood_test_type
                    
                    if(!this.form.appointment.isBloodTestAppointment){ 
                        this.chair_selected_id = this.form.appointment.appointment_day_after_id_chair
                    }

                    
                        
                    if(appointments_day_after){
                        //this.DB.selected_duration = appointments_day_after.duration
                        if(therapyAppChairName != "undefined undefined"){
                            this.chair_selected = therapyAppChairName
                        } else {
                            this.chair_selected = ""
                        }
                        this.appointment_day_after_time_end = appointment_day_after_time_end                        
                    }

                    if(this.form.appointment.isTherapyAppointment && !this.form.appointment.isBloodTestAppointment){
                        this.read_duration()
                    }                     

                    if(!this.form.appointment.isBloodTestAppointment){
                        this.readRoom(this.form.appointment.id_room);

                        this.readVisitType(this.form.appointment.id_visit_type);
                    }   
                    
                    

                    // Se il campo è null sostituiscilo con stringa vuota
                    if(!patient_pause_description) this.form.appointment.patient_pause_description = ""
                    if(!patient_room_therapy_description) this.form.appointment.patient_room_therapy_description = ""
                    if(!pharmacist_therapy_description) this.form.appointment.pharmacist_therapy_description = "" 
                    if(!nurses_description) this.form.appointment.nurses_description = "" 
                    if(!verify_description) this.form.appointment.verify_description = "" 
                    if(!taking_charge_description) this.form.appointment.taking_charge_description = "" 
                    if(!preparation_pharma_description) this.form.appointment.preparation_pharma_description = "" 
                    if(!prepared_pharma_description) this.form.appointment.prepared_pharma_description = ""
                    if(!medicine_ready_description) this.form.appointment.medicine_ready_description = "" 
                    if(!note_closed_nurses) this.form.appointment.note_closed_nurses = ""
                    if(!note_create) this.form.appointment.note_create = "" 

                    if(!this.form.appointment.appointments_day_after.medicine_ready_description){
                        this.form.appointment.appointments_day_after.medicine_ready_description = "" 
                    }

                    if(!this.form.appointment.appointments_day_after.taking_charge_description){
                        this.form.appointment.appointments_day_after.taking_charge_description = "" 
                    }

                    if(!this.form.appointment.appointments_day_after.preparation_pharma_description){
                        this.form.appointment.appointments_day_after.preparation_pharma_description = "" 
                    } 

                    if(!this.form.appointment.appointments_day_after.prepared_pharma_description){
                        this.form.appointment.appointments_day_after.prepared_pharma_description = "" 
                    }  

                    if(!this.form.appointment.appointments_day_after.patient_room_therapy_description){
                        this.form.appointment.appointments_day_after.patient_room_therapy_description = "" 
                    } 
                    
                    // Associamo titolo terapia impostata per ipotetica modifica della terapia
                    this.form.form_edit_therapy.title_old_therapy = appointment.data.patient_pathology.therapy ? appointment.data.patient_pathology.therapy.title : null
                    this.form.form_edit_therapy.therapy_on_select = appointment.data.patient_pathology.therapy ? appointment.data.patient_pathology.therapy.title : null
                    
                    if(!this.form.appointment.isBloodTestAppointment){ 
                        this.read_chairs_with_appointments()
                        this.calendar.config.selectedDate = this.form.appointment.appointment_day_after_date    
                    }   
                                   

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                }

            },
            async readRooms() {
                try {

                    const rooms = await this.axios.get(`user/rooms/read/actives/appointments/update`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.rooms.DB = rooms.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.rooms.DB = []

                }

            },
            async readRoom(roomid) {

                this.loaders.reading.rooms = true
                
                try {

                    const room = await this.axios.get(`user/rooms/read/detailId/${roomid}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.loaders.reading.rooms = false
                    this.room.DB = room.data.availibilities  
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                    this.loaders.reading.rooms = false
                    this.room.DB = []

                }

            }, 
            async updateNoteApp(note_create) {
                this.deleteTherapy = 0
                this.loaders.updating = true
                this.validation.ko    = false

                const OBJ = {
                    id: this.$route.params.id,
                    note_create: note_create
                }                

                try {
                    const res = await this.axios.post(`user/appointments/update_note`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.go(-1)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.updating = false

                }
                
            },              
            async updateTherapyApp(note_create, id) {
                this.deleteTherapy = 0
                this.loaders.updating = true
                this.validation.ko    = false

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    id: id,
                    note_create: note_create
                }                

                try {
                    const res = await this.axios.post(`user/appointments/updateTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.go(-1)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.updating = false

                }
                
            },            
                   
            async updateAppointment() {
                this.deleteTherapy = 0
                this.loaders.updating = true
                this.validation.ko    = false

                // assegnazione ID appuntamento
                this.form.id = this.$route.params.id

                // Costruzione oggetto da inviare all'API
                const OBJ = {

                    form: this.form,

                    old: this.old,

                    notify: {
                        email: this.notify.email,
                        phone: this.notify.phone,
                        emailcg: this.notify.emailcg,
                        phonecg: this.notify.phonecg                       
                    }

                }

                try {
                    const res = await this.axios.post(`user/appointments/update`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.go(-1)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.updating = false

                }
                
            },

            async updateBloodTest(){

                const OBJ = {
                    date:               this.form.appointment.blood_tests.date,
                    h_start:            this.form.appointment.blood_tests.h_start,
                    id:                 this.form.appointment.blood_tests.id,
                    id_blood_test_type: this.form.appointment.blood_tests.id_blood_test_type,
                    id_appointment:     this.$route.params.id,
                    note_create:        this.form.appointment.blood_tests.note_create,
                    notify: {
                        email:          this.notify.email_blood_test,
                        phone:          this.notify.phone_blood_test,
                        emailcg:        this.notify.emailcg_blood_test,
                        phonecg:        this.notify.phonecg_blood_test                     
                    },

                    form: this.form                                        
                }

                try {

                    const bloodtest = await this.axios.post(`user/appointments/updateBloodtest`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: bloodtest.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)                   

                } catch(e) {


                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })
                } 

            }, 

            
            async deleteAppointment() {

                this.loaders.deleting = true

                const OBJ = {

                    id: this.$route.params.id,

                    notify: {
                        email: this.notify.email,
                        phone: this.notify.phone,
                        emailcg: this.notify.emailcg,
                        phonecg: this.notify.phonecg                       
                    }

                }

                try {

                    const res = await this.axios.post(`user/appointments/delete`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }

            },
            async readTherapies() {

                try {

                    this.therapies.DB = (
                        await this.axios.get(`user/therapies/read/actives`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: "Errore di connessione con il server",
                        type: "error"
                    })

                    this.$router.go("/user/agenda")
                }
                finally {
                    this.loaders.reading.therapy = false
                }

            },
            async check_if_patient_is_present(requester) {

                /*
                    * Requester, ovvero il richiedente del modale, può essere
                    * invite_patient
                    * back_from_pause_patient
                */
                const id_room = this.actual_room.id
                const patient_present = (
                    await this.axios.post(`user/appointments/read_if_patient_is_present`, {id_room}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                ).data

                const {
                    presence,
                    patient,
                    id_appointment,
                    patient_finish_pause
                } = patient_present

                // Nel caso in cui il  paziente è in visita ha effettuato ed è rientrato dalla pausa
                if(patient_finish_pause) {
                    // Impostiamo nominativo paziente e id appuntamento nel data
                    this.patient_room_presence = {
                        patient: `${patient.surname} ${patient.name}`,
                        id_appointment,
                        requester
                    }
                    // Mostriamo il modale di errore
                    this.$bvModal.show('modal_alert_patient_presence_with_pause')
                }
                else if(presence) {

                    // Impostiamo nominativo paziente e id appuntamento nel data
                    this.patient_room_presence = {
                        patient: `${patient.surname} ${patient.name}`,
                        id_appointment,
                        requester
                    }
                    // Mostriamo il modale di domanda
                    this.$bvModal.show('modal_alert_patient_presence')
                }
                else {
                    requester == "invite_patient" ? this.invitePatient() : this.backPatient()
                }

            },  
            async check_if_patient_is_present_therapy(requester) {

                /*
                    * Requester, ovvero il richiedente del modale, può essere
                    * invite_patient
                    * back_from_pause_patient
                */
                /*const id_patient_pathology = this.form.appointment.id_patient_pathology
                const idapp = this.$route.params.id
                const patient_present = (
                    await this.axios.post(`user/appointments/read_if_patient_is_present_therapy`, {id_patient_pathology, idapp}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                ).data*/


                /*const {
                    presence,
                    patient,
                    id_appointment,
                    patient_finish_therapy
                } = patient_present*/

                // Nel caso in cui il  paziente è in visita ha effettuato ed è rientrato dalla pausa
                /*if(patient_finish_therapy) {                   
                    // Impostiamo nominativo paziente e id appuntamento nel data
                    this.patient_room_presence = {
                        patient: `${patient.surname} ${patient.name}`,
                        id_appointment,
                        requester
                    }
                    // Mostriamo il modale di errore
                    this.$bvModal.show('modal_alert_patient_presence_with_therapy')
                }
                else if(presence) {
                    console.log('F');

                    // Impostiamo nominativo paziente e id appuntamento nel data
                    this.patient_room_presence = {
                        patient: `${patient.surname} ${patient.name}`,
                        id_appointment,
                        requester
                    }
                    // Mostriamo il modale di domanda
                    this.$bvModal.show('modal_alert_patient_presence_therapy')
                }
                else {
                    requester == "invite_patient" ? this.invitePatientTherapy() : this.backPatientTherapy()
                }*/
                requester == "invite_patient" ? this.invitePatientTherapy() : this.backPatientTherapy()

            },                                
            async invitePatient(reset_other_patient, force_close_modal) {

                let OBJ = {

                }

                if(reset_other_patient) {

                    // Impostiamo nell'oggetto la richiesta di reset con parametri
                    OBJ.reset_other_patient = {
                        ...this.patient_room_presence
                    }
                    // Reset del data
                    this.patient_room_presence = false
                    // Nascondiamo il modale
                    this.$bvModal.hide('modal_alert_patient_presence_therapy')

                }
                if(force_close_modal) {
                    // Nascondiamo il modale
                    this.$bvModal.hide(force_close_modal)
                }

                OBJ.id = this.$route.params.id
                OBJ.CF = this.form.user.CF
                OBJ.urgent = this.form.appointment.urgent

                try {

                    const invitation = await this.axios.post(`user/appointments/invitePatient`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.room_presence = true

                    this.$toast.open({
                        message: invitation.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },
            async pausePatient() {

                const {
                    id
                } = this.$route.params
                const {
                    patient_pause_description
                } = this.form.appointment
                const {
                    CF
                } = this.form.user

                try {

                    const pause = await this.axios.post(`user/appointments/pausePatient`, {id, patient_pause_description, CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.patient_pause    = true
                    this.form.appointment.pause_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },
            async bloodtestNurseEnd(id_blood_test, description) {

                const {
                    id,
                } = this.$route.params

                const {
                    CF
                } = this.form.user


                try {

                    const pause = await this.axios.post(`user/appointments/bloodTestNurseEnd`, {id, CF, description, id_blood_test}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },  

            async therapyNurseEnd(id_day_after, description) {

                const {
                    id,
                } = this.$route.params

                var id_therapy = this.$route.params

                if(!id_therapy){
                    id_therapy = 0
                }

                const {
                    CF
                } = this.form.user

                var patient_room_therapy_description

                if(this.form.appointment.appointments_day_after.patient_room_therapy_description){
                    patient_room_therapy_description = this.form.appointment.appointments_day_after.patient_room_therapy_description
                } else {
                    patient_room_therapy_description = description
                }
             

                try {

                    const pause = await this.axios.post(`user/appointments/therapyNurseEnd`, {id, id_therapy, CF, patient_room_therapy_description, id_day_after}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.therapy_nurses_end_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },    
                  
            async inviteForBloodTestRoom(id_blood_test, description, urgent) {

                const OBJ = {
                    id_appointment:                     this.$route.params.id,
                    id:                                 this.$route.params,
                    patient_room_blood_test_description:description ? description : this.form.appointment.patient_room_blood_test_description,
                    CF:                                 this.form.user,
                    blood_test:                         this.form.appointment.blood_tests,
                    id_blood_test:                      id_blood_test,
                    urgent:                             urgent          
                } 
                        

                try {

                    const pause = await this.axios.post(`user/appointments/inviteForBloodTestRoom`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },                           
            async pausePatientTherapy(id_day_after, description, urgent) {

                const OBJ = {
                    id_appointment:                     this.$route.params.id,
                    id_therapy:                         this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    id_chair:                           this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id_chair : this.form.appointment.id_chair,
                    id:                                 this.$route.params,
                    patient_room_therapy_description:   description ? description : this.form.appointment.patient_room_therapy_description,
                    CF:                                 this.form.user,
                    day_after:                          this.form.appointment.appointments_day_after,
                    chair:                              this.chair_selected_02_id,
                    id_day_after:                       id_day_after,
                    urgent:                             urgent           
                } 
                          

                try {

                    const pause = await this.axios.post(`user/appointments/pausePatientTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.patient_room_therapy    = true
                    this.form.appointment.therapy_start_time      = moment().format("HH:mm")
                    this.show_therapy_start                       = 0

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },  

            async backPatient(reset_other_patient, force_close_modal) {

                let OBJ = {

                }

                if(reset_other_patient) {

                    // Impostiamo nell'oggetto la richiesta di reset con parametri
                    OBJ.reset_other_patient = {
                        ...this.patient_room_presence
                    }
                    // Reset del data
                    this.patient_room_presence = false
                    // Nascondiamo il modale
                    this.$bvModal.hide('modal_alert_patient_presence')

                }
                if(force_close_modal) {
                    // Nascondiamo il modale
                    this.$bvModal.hide(force_close_modal)
                }

                OBJ.id = this.$route.params.id
                OBJ.CF = this.form.user.CF

                try {

                    const pause = await this.axios.post(`user/appointments/backPatient`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.patient_pause        = false
                    this.form.appointment.patient_pause_back   = false
                    this.form.appointment.pause_end_time     = moment().format("HH:mm")
                    //this.form.appointment.patient_pause_description = ""

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },
            async backPatientTherapy(reset_other_patient, force_close_modal) {

                let OBJ = {

                }

                if(reset_other_patient) {

                    // Impostiamo nell'oggetto la richiesta di reset con parametri
                    OBJ.reset_other_patient = {
                        ...this.patient_room_presence
                    }
                    // Reset del data
                    this.patient_room_presence = false
                    // Nascondiamo il modale
                    this.$bvModal.hide('modal_alert_patient_presence_therapy')

                }
                if(force_close_modal) {
                    // Nascondiamo il modale
                    this.$bvModal.hide(force_close_modal)
                }

                OBJ.id = this.$route.params.id
                OBJ.CF = this.form.user.CF

                try {

                    const pause = await this.axios.post(`user/appointments/backPatientTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.patient_room_therapy   = false
                    this.form.appointment.patient_therapy_back   = false
                    this.form.appointment.room_therapy_called    = false
                    this.form.appointment.therapy_end_time       = moment().format("HH:mm")
                    //this.form.appointment.patient_pause_description = ""

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            }, 
            async sentVerify() {
                
                const OBJ = {
                    id_appointment: this.$route.params.id,
                    note:           this.form.closing.note,
                    verify_description: this.form.appointment.verify_description,
                    CF: this.form.user  
                }               
          

                try {

                    const pause = await this.axios.post(`user/appointments/sentVerify`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.sent_verify            = true
                    this.form.appointment.verify_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
            async medicineReady(id_day_after, description) {
                
                const OBJ = {
                    id_appointment: this.$route.params.id,
                    id_therapy: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    note:           this.form.closing.note,
                    medicine_ready_description: description ? description : this.form.appointment.medicine_ready_description,
                    CF: this.form.user,
                    id_day_after: id_day_after   
                }               
          

                try {

                    const medicineReady = await this.axios.post(`user/appointments/medicineReady`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.medicine_ready_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: medicineReady.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  

            async takingCharge(id_day_after, description) {

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    id_therapy: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    note:           this.form.closing.note,
                    taking_charge_description: description ? description : this.form.appointment.taking_charge_description,
                    CF: this.form.user,
                    id_day_after: id_day_after  
                }                                      

                try {

                    const pause = await this.axios.post(`user/appointments/takingCharge`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.taking_charge_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
           
            async pharmacyTherapy() {
                
                const OBJ = {
                    id_appointment: this.$route.params.id,
                    note:           this.form.closing.note,
                    pharmacist_therapy_description: this.form.appointment.pharmacist_therapy_description,
                    CF: this.form.user  
                }               
          

                try {

                    const pause = await this.axios.post(`user/appointments/pharmacyTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.sent_pharmacist            = true
                    this.form.appointment.sent_pharmacist_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  
            async sentNurses() {

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    note:           this.form.closing.note,
                    nurses_description: this.form.appointment.nurses_description,
                    CF: this.form.user,
                    outside: this.form.appointment.outside                   
                }               

                try {

                    const pause = await this.axios.post(`user/appointments/sentNurses`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.sent_nurses            = true
                    this.form.appointment.nurses_start_time      = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.replace(`/user/agenda`);

                    //this.$router.go(-1)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  

            
            async preparationPharmacyTherapy(id_day_after, description) {
                             
                const OBJ = {
                    id_appointment: this.$route.params.id,
                    id_therapy: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    note:           this.form.closing.note,
                    preparation_pharma_description: description != "" ? description : this.form.appointment.preparation_pharma_description,
                    CF: this.form.user,
                    id_day_after: id_day_after  
                }                                      
          

                try {

                    const pause = await this.axios.post(`user/appointments/preparationPharmacyTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.preparation_pharma            = true
                    this.form.appointment.preparation_pharma_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },  

            async preparedPharmacyTherapy(id_day_after, description) {

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    id_therapy: this.form.appointment.appointments_day_after ? this.form.appointment.appointments_day_after.id : 0,
                    note:           this.form.closing.note,
                    prepared_pharma_description: description ? description : this.form.appointment.prepared_pharma_description,
                    CF: this.form.user,
                    id_day_after: id_day_after
                }                   
                             
                try {

                    const pause = await this.axios.post(`user/appointments/preparedPharmacyTherapy`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.prepared_pharma            = true
                    this.form.appointment.prepared_pharma_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(this.$router.currentRoute)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },             
           
            /*async preparationPharmacyTherapy() {

                const {
                    id
                } = this.$route.params

                const {
                    CF
                } = this.form.user

                try {

                    const pause = await this.axios.post(`user/appointments/preparationPharmacyTherapy`, {id, CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.preparation_pharma            = true
                    this.form.appointment.preparation_pharma_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },                                
            async preparedPharmacyTherapy() {

                const {
                    id
                } = this.$route.params

                const {
                    CF
                } = this.form.user

                try {

                    const pause = await this.axios.post(`user/appointments/preparedPharmacyTherapy`, {id, CF}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.appointment.prepared_pharma            = true
                    this.form.appointment.prepared_pharma_start_time = moment().format("HH:mm")

                    this.$toast.open({
                        message: pause.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }


            },                                
            */

            /*async read_patient_pathology() {

                this.loaders.reading.therapy = true

                try {

                    const {patient_pathology} = (await this.axios.get(`user/patients_pathologies/read_therapy/${this.form.appointment.id_patient_pathology}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })).data

                    if((patient_pathology.therapy && this.form.patients_pathologies.id_therapy == patient_pathology.therapy.id) || (!this.form.patients_pathologies.id_therapy && !patient_pathology.therapy)) {

                        this.$toast.open({
                            message: "Non sono presenti dei cambiamenti per la terapia",
                            type:    "warning"
                        })

                    }
                    else {

                        this.form.patients_pathologies.id_therapy = patient_pathology.therapy ? patient_pathology.therapy.id : null

                        this.$toast.open({
                            message: "Aggiornamento terapia caricato con successo",
                            type:    "success"
                        })
                    }

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading.therapy = false
                }
            },*/
            async termina_visita_infermieristica() {
                
                this.loaders.closing_nurses = true

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    note_closed_nurses:           this.form.appointment.note_closed_nurses
                }

                try {

                    const res = await this.axios.post(`user/appointments/endVisitNurses`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.go(this.$router.currentRoute)
                    
                }
                catch(e) {

                    const msg = e.response.data.msg

                    this.validation.ko = msg

                    this.$toast.open({
                        message: msg,
                        type:   'error'
                    })

                    this.loaders.closing_nurses = false

                }

            },          
           
            async termina_visita() {
                
                this.loaders.closing = true

                const OBJ = {
                    id_appointment: this.$route.params.id,
                    note:           this.form.closing.note
                }

                try {

                    const res = await this.axios.post(`user/appointments/endVisit`, OBJ, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.go(this.$router.currentRoute)
                    
                }
                catch(e) {

                    const msg = e.response.data.msg

                    this.validation.ko = msg

                    this.$toast.open({
                        message: msg,
                        type:   'error'
                    })

                    this.loaders.closing = false

                }

            },
            async send_notify_SMS() {

                this.loaders.sending.SMS = true

                const {
                    id
                } = this.$route.params

                try {

                    const notify = await this.axios.post(`user/appointments/send/notify/SMS/remember_appointment`, {id, phone: this.form.user.phone}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: notify.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.sending.SMS = false
                    this.$bvModal.hide("modal_send_SMS")
                }

            },
            async send_notify_SMSCG() {

                this.loaders.sending.SMSCG = true

                const {
                    id
                } = this.$route.params

                try {

                    const notify = await this.axios.post(`user/appointments/send/notify/SMS/remember_appointment_CG`, {id, phonecg: this.form.user.phonecg}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: notify.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.sending.SMSCG = false
                    this.$bvModal.hide("modal_send_SMSCG")
                }

            },            
            async send_notify_mail() {

                this.loaders.sending.mail = true

                const {
                    id
                } = this.$route.params

                try {

                    const notify = await this.axios.post(`user/appointments/send/notify/email/remember_appointment`, {id, email: this.form.user.email}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: notify.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.sending.mail = false
                    this.$bvModal.hide("modal_send_mail")
                }

            },
            async send_notify_mailCG() {

                this.loaders.sending.mailCG = true

                const {
                    id
                } = this.$route.params

                try {

                    const notify = await this.axios.post(`user/appointments/send/notify/email/remember_appointment_CG`, {id, emailcg: this.form.user.emailcg}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: notify.data.msg,
                        type:   `success`
                    })

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.sending.mailCG = false
                    this.$bvModal.hide("modal_send_mailCG")
                }

            },           
            async update_therapy() {

                this.loaders.update_therapy       = true
                this.validation.ko_update_therapy = false
                this.validation.ok_update_therapy = false

                try {

                    // Popoliamo l'oggetto
                   this.form.form_edit_therapy.id_patient_pathology = this.form.appointment.id_patient_pathology
                   this.form.form_edit_therapy.id_therapy           = this.form.patients_pathologies.id_therapy
                   this.form.form_edit_therapy.modifier_user        = this.$store.state.auth.username

                    const therapy_updated = await this.axios.post(`user/patients_pathologies/update_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.form_edit_therapy.id = therapy_updated.data.therapy_cronology.id

                    this.form.form_edit_therapy.date = new Date()


                    const OBJ = {...this.form.form_edit_therapy}
                    
                    // Mostriamo nella tabella la modifica
                    this.form.patients_pathologies.therapy_cronologies.push(
                        OBJ
                    )

                    // Impostiamo la nuova terapia scelta
                    this.form.form_edit_therapy.title_old_therapy = this.form.form_edit_therapy.therapy_on_select
                    

                    const {
                        message
                    } = therapy_updated.data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validation.ok_update_therapy = message

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_update_therapy = message

                }
                finally {
                    this.loaders.update_therapy = false
                }

            },

            async delete_therapy(id_cronology) {

                this.loaders.delete_therapy       = true
                this.validation.ko_delete_therapy = false
                this.validation.ok_delete_therapy = false          

                try {
                    this.form.appointment.deleteTherapy = 1
                    this.form.form_edit_therapy.id_therapy = id_cronology

                    const therapy_deleted = await this.axios.post(`user/patients_pathologies/delete_therapy`, this.form.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    let updateCronologies = this.form.patients_pathologies.therapy_cronologies.filter((el) => el.id !== id_cronology);
                    this.form.patients_pathologies.therapy_cronologies = updateCronologies;    
                    this.deleteRow = 1  

                    const {
                        message
                    } = therapy_deleted.data


                    this.validation.ok_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_delete_therapy = message
                    this.form.appointment.deleteTherapy = 0

                }
                finally {
                    this.loaders.delete_therapy = false

                }

            },


            go_to_appointment(id) {
                this.$bvModal.hide('modal_alert_patient_presence')
                this.$router.replace(`${id}`).catch(()=>{});
            },
            clear_patient_room_presence(id_modal_close) {

                this.patient_room_presence = false

                if(id_modal_close) this.close_modal(id_modal_close)

            },
            close_modal(id) {
                this.$bvModal.hide(id)
            },
            time_expire() {

                setInterval(
                    () => {

                        if(this.form.appointment.totem_presence_arrival) {

                            const h_arriv = moment(
                                moment().format(`YYYY-MM-DD ${this.form.appointment.totem_presence_arrival}`)
                            )

                            const h_now = moment(
                                moment().format("YYYY-MM-DD HH:mm")
                            )

                            const duration = moment.duration(
                                h_now.diff(h_arriv)
                            )

                            const attesa_minuti = duration.asMinutes()

                            if(attesa_minuti >= 60) {
                                this.time_delay = {
                                    ago: parseInt(duration.asHours()) < 0 ? 0 : parseInt(duration.asHours()),
                                    type: "ore"
                                }
                            }
                            else {
                                this.time_delay = {
                                    ago: attesa_minuti < 0 ? 0 : attesa_minuti,
                                    type: "minuti"
                                }
                            }

                        }

                    }, 1000
                )

            },            
            updateDayOfAppointment(dateSelected) {
                this.form.appointment.date = moment(dateSelected).format("YYYY-MM-DD")                  
            },
            updateTimeEndAppointment() {

                if(this.classCal == 1){
                    if(this.check_if_present_appointment()) {

                        const message = "L'appuntamento che stai creando va in conflitto con un altro appuntamento!"
                        this.validation.conflict = message
                        //bottone aggiungi disabilitato
                        this.disabled = 1    
                    }  else {

                        if(this.visit_type > 0){
                            this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                        } else {
                            this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")
                        }

                        this.disabled = 0   
                        this.validation.conflict = false                        
                    }  
                } else {
                    if(this.check_room_hours_available()){
                        const message = "La stanza non è disponibile in questa fascia oraria!"

                        this.$toast.open({
                            message,
                            type: "warning"
                        })
                        this.disabled = 1

                        this.validation.conflict = message
                        
                    } else if(this.check_if_present_appointment()) {

                        const message = "L'appuntamento che stai creando va in conflitto con un altro appuntamento!"

                        this.validation.conflict = message
                        //bottone aggiungi disabilitato
                        this.disabled = 1
                    } else {
                        if(this.visit_type > 0){
                            this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.visit_type, "minutes").format("HH:mm")
                        } else {
                            this.form.appointment.h_end = moment(this.form.appointment.h_start, "HH:mm").add(this.getAppointmentDuration(), "minutes").format("HH:mm")
                        }
                    }
                    this.validation.conflict = false
                    this.disabled = 0                    
                }   
            },
            updateCheckRoomMaxVisits() {

                this.check_if_present_appointment_patient();
                
                if(this.check_room_max_visits_minutes()) {
                    if(this.functionSpecialEnabled(6)){
                            this.$confirm("E' stato raggiunto il numero massimo di visite per questa stanza! Sei sicuro di voler aggiungere l'appuntamento?", "", "warning").then(() => {
                            this.validation.conflict = false
                            this.disabled = 0
                        });
                    }

                    
                    const message = "E' stato raggiunto il numero massimo di visite per questa stanza!"

                    this.$toast.open({
                        message,
                        type: "warning"
                    })

                    this.validation.conflict = message
                    //bottone aggiungi disabilitato
                    //this.disabled = 1                       

                } else {
                    this.validation.conflict = false

                    //bottone abilitato
                    this.disabled = 0
                }

            },
            functionSpecialEnabled(id_function) {

                // Se sono presenti abilitazioni all'utilizzo delle funzioni software
                if(store.getters.softwareSpecialFunctions) {
                    //sFunction == software function
                    for(let sFunction of store.getters.softwareSpecialFunctions) {
                        // Se la funzione software corrisponde all'ID passato
                        if(sFunction.id_software_special_function == id_function && sFunction.abilitation) {
                            return true
                        }
                    }
                }

                return false

            },
            setAppointmentParams(data_selezionata) {
                // form.appointment.date form.appointment.h_start form.appointment.h_end
                this.form.appointment.date    = moment(data_selezionata).format("YYYY-MM-DD")
                this.form.appointment.h_start = moment(data_selezionata).format("HH:mm")
                this.form.appointment.appointment_day_after_date     = moment(data_selezionata).add(1, "day").format("YYYY-MM-DD")
                this.form.appointment.appointment_day_after_time    = moment(data_selezionata).format("HH:mm")         
                //this.form.appointment.h_end   = moment(data_selezionata, "HH:mm").add(this.getAppointmentDuration(), 'minutes').format("HH:mm")
                this.updateTimeEndAppointment()
                this.updateCheckRoomMaxVisits()
                this.loaders.updating = false;
            },
            getAppointmentDuration() {
                return this.patients_pathologies.find(
                    patient_pathology_arr => patient_pathology_arr.id == this.form.appointment.id_patient_pathology
                ).sessions_duration
            },
            update_therapies(id_patient_pathology_selected) {
                // Impostiamo se è stata settata l'id della terapia associata tra paziente -> patologia in pathients_pathologies
                for(const patient_pathology of this.patients_pathologies) {
                    if(patient_pathology.id == id_patient_pathology_selected) {
                        this.form.patients_pathologies.id_therapy = patient_pathology.id_therapy
                    }
                }
            },
            change_title_new_therapy(id_therapy) {


                if(!id_therapy) {
                    this.form.form_edit_therapy.therapy_on_select = null
                }
                else {

                    // Ciclo delle terapie per ricavare il titolo
                    for(const therapy of this.therapies.DB) {
                        if(therapy.id == id_therapy) {
                            this.form.form_edit_therapy.therapy_on_select = therapy.title
                        }
                    }

                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            controlla_data() {

                const data_inserita = this.$refs.data_appointment.value

                var date = new Date(data_inserita);

                const day = date.getDay();            

                if(data_inserita == '' || !data_inserita || moment(data_inserita).isSameOrBefore(moment().format("YYYY-MM-DD"))) {
                    
                    this.$toast.open({
                        message: "La data inserita deve partire da domani",
                        type: "error"
                    })

                    this.$refs.data_appointment.value = this.form.appointment.date
                    this.loaders.updating = true;

                } else if(day == 6 || day == 0){
                    this.$toast.open({
                        message: "Non è consentito inserire un appuntamento di sabato o di domenica!",
                        type: "error"
                    })  
                    this.loaders.updating = true;          
                } else {
                    this.form.appointment.date = this.$refs.data_appointment.value
                    this.loaders.updating = false;
                }

            },
            check_room_hours_available() {
                this.rooms.DB.find(
                    room => {
                        
                        if(room.id == this.form.appointment.id_room) {

                            for(const room of room.availibilities) {  
                                objroomRoomAvailable.push(room);
                            }
                        }

                    }
                )  
                
                var h_start = this.form.appointment.h_start; 
                var date = this.form.appointment.date;

                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  
                           
                var data;
                var from;
                var to;
                var frompm;
                var topm;   

                var check = 0;

                for (let index = 0; index < objroomRoomAvailable.length; ++index) {
                    startDate = new Date(objroomRoomAvailable[index].dal);
                    endDate = new Date(objroomRoomAvailable[index].al);
                    
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dayweek = dt.getDay() - 1;
                         if(dayweek == '1'){
                            from = objroomRoomAvailable[index].monday_1.am.dal;
                            to = objroomRoomAvailable[index].monday_1.am.al;
                            frompm = objroomRoomAvailable[index].monday_1.pm.dal;
                            topm = objroomRoomAvailable[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroomRoomAvailable[index].tuesday_2.am.dal;
                            to = objroomRoomAvailable[index].tuesday_2.am.al;       
                            frompm = objroomRoomAvailable[index].tuesday_2.pm.dal;
                            topm = objroomRoomAvailable[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroomRoomAvailable[index].wednesday_3.am.dal;
                            to = objroomRoomAvailable[index].wednesday_3.am.al;     
                            frompm = objroomRoomAvailable[index].wednesday_3.pm.dal;
                            topm = objroomRoomAvailable[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroomRoomAvailable[index].thursday_4.am.dal;
                            to = objroomRoomAvailable[index].thursday_4.am.al; 
                            frompm = objroomRoomAvailable[index].thursday_4.pm.dal;
                            topm = objroomRoomAvailable[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroomRoomAvailable[index].friday_5.am.dal;
                            to = objroomRoomAvailable[index].friday_5.am.al;         
                            frompm = objroomRoomAvailable[index].friday_5.pm.dal;
                            topm = objroomRoomAvailable[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            from = objroomRoomAvailable[index].saturday_6.am.dal;
                            to = objroomRoomAvailable[index].saturday_6.am.al;   
                            frompm = objroomRoomAvailable[index].saturday_6.pm.dal;
                            topm = objroomRoomAvailable[index].saturday_6.pm.al;                                                        
                        } 
                                          
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arrRoomAvailable.push(data);
  
  
                    } 
                    
                    let dayF = ('0' + startDate.getDate()).slice(-2);       
                    let monthF = ('0' + (startDate.getMonth()+1)).slice(-2);        
                    let yearF = startDate.getFullYear();  

                    let dayFe = ('0' + endDate.getDate()).slice(-2);       
                    let monthFe = ('0' + (endDate.getMonth()+1)).slice(-2);        
                    let yearFe = endDate.getFullYear();      
                    let startDateF = yearF + monthF + dayF;            
                    let endDateF = yearFe + monthFe + dayFe;   
                    
                    let dateF = date.replace('-', '');
                    dateF = dateF.replace('-', '');

                    if(dateF < startDateF){
                        check = 1
                    }
                    if(dateF > endDateF){
                        check = 1
                    }                       
                }

                for (let index = 0; index < arrRoomAvailable.length; ++index) {

                    var element = Object.keys(arrRoomAvailable[index]);
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();                   
                
                    var fromMor = String(arrRoomAvailable[index][element].am.from);                   
                    var toMor = String(arrRoomAvailable[index][element].am.to);    

                    var fromMorS = fromMor.replace(':', '');              
                    var toMorS = toMor.replace(':', '');              

                    var fromAft = String(arrRoomAvailable[index][element].pm.from);                   
                    var toAft = String(arrRoomAvailable[index][element].pm.to);                

                    var fromAftS = fromAft.replace(':', '');              
                    var toAftS = toAft.replace(':', '');                               

                    var check_h_start = h_start.replace(':', '');                       
                    
                    element = String(element);

                    if(date == element){
                        if(check_h_start >= fromMorS && check_h_start <= toMorS){
                            if(!(check_h_start >= fromMorS && check_h_start <= toMorS) || day == 0){
                                check = 1;
                            } else {                         
                                check = 0;
                            } 
                        } else {
                            if(!(check_h_start >= fromAftS && check_h_start <= toAftS) || day == 0){
                                check = 1;
                            } else {                         
                                check = 0;
                            } 
                        }

                    } 

                }
                if(check == 1) {
                    this.disabled = 1
                    return true
                } else {
                    this.disabled = 0
                }                            
      
            },
            check_room_max_visits_minutes() {
                this.rooms.DB.find(
                    room => {
                        
                        if(room.id == this.form.appointment.id_room) {

                            for(const room of room.availibilities) {  
                                objroomMaxVisit.push(room);
                            }
                        }

                    }
                )  

                var date = this.form.appointment.date;
                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  
                           
                var data;
                var from;
                var to;
                var frompm;
                var topm;   


                for (let index = 0; index < objroomMaxVisit.length; ++index) {
                    startDate = new Date(objroomMaxVisit[index].dal);
                    endDate = new Date(objroomMaxVisit[index].al);
                    
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dayweek = dt.getDay() - 1;
                         if(dayweek == '1'){
                            from = objroomMaxVisit[index].monday_1.am.dal;
                            to = objroomMaxVisit[index].monday_1.am.al;
                            frompm = objroomMaxVisit[index].monday_1.pm.dal;
                            topm = objroomMaxVisit[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroomMaxVisit[index].tuesday_2.am.dal;
                            to = objroomMaxVisit[index].tuesday_2.am.al;       
                            frompm = objroomMaxVisit[index].tuesday_2.pm.dal;
                            topm = objroomMaxVisit[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroomMaxVisit[index].wednesday_3.am.dal;
                            to = objroomMaxVisit[index].wednesday_3.am.al;     
                            frompm = objroomMaxVisit[index].wednesday_3.pm.dal;
                            topm = objroomMaxVisit[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroomMaxVisit[index].thursday_4.am.dal;
                            to = objroomMaxVisit[index].thursday_4.am.al; 
                            frompm = objroomMaxVisit[index].thursday_4.pm.dal;
                            topm = objroomMaxVisit[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroomMaxVisit[index].friday_5.am.dal;
                            to = objroomMaxVisit[index].friday_5.am.al;         
                            frompm = objroomMaxVisit[index].friday_5.pm.dal;
                            topm = objroomMaxVisit[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            from = objroomMaxVisit[index].saturday_6.am.dal;
                            to = objroomMaxVisit[index].saturday_6.am.al;   
                            frompm = objroomMaxVisit[index].saturday_6.pm.dal;
                            topm = objroomMaxVisit[index].saturday_6.pm.al;                                                        
                        } 
                                          
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arrMaxVisit.push(data);
  
  
                    }  
                                         
                }

                for (let index = 0; index < arrMaxVisit.length; ++index) {

                    var element = Object.keys(arrMaxVisit[index]);    
                
                    var fromMor = String(arrMaxVisit[index][element].am.from);                   
                    var toMor = String(arrMaxVisit[index][element].am.to);    


                    //var fromAft = String(arrMaxVisit[index][element].pm.from);                   
                    //var toAft = String(arrMaxVisit[index][element].pm.to);                
                     
                    
                    element = String(element);
                    var elementNew = new Date(date);

                    if(date == element){
                        //max visits

                        let dayMaxVisit = ('0' + elementNew.getDate()).slice(-2);       
                        let monthMaxVisit = ('0' + (elementNew.getMonth()+1)).slice(-2);        
                        let yearMaxVisit = elementNew.getFullYear();  
                        let hourMaxVisit = fromMor.split(':');
                        let hourMaxVisit2 = toMor.split(':');
                        
                        var startTimeMaxVisit = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit , hourMaxVisit[0], hourMaxVisit[1]);
                        var endTimeMaxVisit = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit, hourMaxVisit2[0], hourMaxVisit2[1]);

                        var totalMilisecondsMaxVisit = endTimeMaxVisit - startTimeMaxVisit;
                        var totalSecondsMaxVisit = totalMilisecondsMaxVisit/1000;
                        var totalMinutsMaxVisit = totalSecondsMaxVisit/60; 

                        var minutes;
                        for(const appointment of this.getEventsByRoomSelected(false)) {   
                            // Parametri appuntamento
                            const date_a    = moment(appointment.date).format("YYYY-MM-DD")
                            const h_start_a = moment(appointment.h_start).format("HH:mm")
                            const h_end_a   = moment(appointment.h_end).format("HH:mm")

                            var elementNewAppointment = new Date(date_a);

                            let dayAppointment = ('0' + elementNewAppointment.getDate()).slice(-2);       
                            let monthAppointment = ('0' + (elementNewAppointment.getMonth()+1)).slice(-2);        
                            let yearAppointment = elementNewAppointment.getFullYear();  
                            let hourAppointment = h_start_a.split(':');
                            let hourAppointment2 = h_end_a.split(':');
                            
                            var startTimeAppointment = new Date(yearAppointment, monthAppointment , dayAppointment , hourAppointment[0], hourAppointment[1]);
                            var endTimeAppointment = new Date(yearAppointment, monthAppointment , dayAppointment, hourAppointment2[0], hourAppointment2[1]);

                            var totalMilisecondsAppointment = endTimeAppointment - startTimeAppointment;
                            var totalSecondsAppointment = totalMilisecondsAppointment/1000;
                            var totalMinutsAppointment = totalSecondsAppointment/60; 
                            
                            minutes = totalMinutsMaxVisit - totalMinutsAppointment;           
                        }

                        var hourSelected =  this.form.appointment.h_start.split(':');  
                        var hourSelected2 =  this.form.appointment.h_end.split(':');  

                        var startTimeSelected = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit , hourSelected[0], hourSelected[1]);
                        var endTimeSelected = new Date(yearMaxVisit, monthMaxVisit , dayMaxVisit, hourSelected2[0], hourSelected2[1]);

                        var totalMilisecondsSelected = endTimeSelected - startTimeSelected;
                        var totalSecondsSelected = totalMilisecondsSelected/1000;
                        var totalMinutsSelected = totalSecondsSelected/60; 
                            
                        minutes = minutes - totalMinutsSelected;                        
                        if(minutes <= 0){
                            this.disabled = 1
                            return true;
                        } 
                    } 
                }                               
      
            },            
            check_if_present_appointment() {

               // Parametri selezionati
               var {
                    id_patient_pathology,
                    h_start,
                    h_end,
                    date
                } = this.form.appointment

                for(const appointment of this.getEventsByRoomSelected(false)) {   
                    if(id_patient_pathology != appointment.id_patient_pathology){               
                        // Parametri appuntamento
                        var date_a    = moment(appointment.date).format("YYYY-MM-DD")
                        var h_start_a = moment(appointment.h_start).format("HH:mm")
                        var h_end_a   = moment(appointment.h_end).format("HH:mm")
                        
                        date = date.replaceAll('-', '');
                        date_a = date_a.replaceAll('-', '');
                        h_start = h_start.replaceAll(':', '');
                        h_start_a = h_start_a.replaceAll(':', '');
                        h_end = h_end.replaceAll(':', '');
                        h_end_a = h_end_a.replaceAll(':', '');

                        const conflitto = (
                            date_a == date
                        ) &&
                        (
                            h_end_a > h_start && h_start_a < h_end
                        )

                        if(conflitto) return true
                    }    
                }

            },

            async check_if_present_appointment_patient(){
                try {
                    const CF = this.form.user.CF.toUpperCase()

                    let patient = await this.axios.get(`user/patients/read/detail/${CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    if(patient.data.patients_pathologies) { 

                        // Popoliamo array degli appuntamenti estrapolandoli dalle terapie seguite
                        for(let patient_pathology of patient.data.patients_pathologies) {  
                            for(let appointment of patient_pathology.appointment) {
                                this.appointments_patient.DB.push(appointment)
                            }                          
                        }

                    }

                    if(this.check_dates_patient()) {
                        const message = "Il paziente ha già un appuntamento nella data selezionata"

                        this.validation.conflict = message
                        this.disabled = 1
                    } else {
                        this.validation.conflict = false
                        this.disabled = 0                                
                    }                     
                } catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })


                }
            },

            check_dates_patient(){
                // Parametri selezionati
                var {
                    date
                } = this.form.appointment


                for(const appointment of this.appointments_patient.DB) {   
                    // Parametri appuntamento
                    var date_a    = moment(appointment.date).format("YYYY-MM-DD")

                    date = date.replaceAll('-', '');
                    date_a = date_a.replaceAll('-', '');

                    const conflitto = (
                        date_a == date
                    )          

                    if(conflitto) return true
                }    

            },
          

            check_max_visits_for_room() {
                var flag = 0 
                var check = 0

                for(const appointment of this.getEventsByRoomSelected(false)) {   

                    // Parametri appuntamento
                    const date_a    = moment(appointment.date).format("YYYY-MM-DD")
                    let date_sel = this.form.appointment.date 

                    if(date_a == date_sel){                       
                        this.rooms.DB.find(
                            room => {                               
                                if(room.id == this.form.appointment.id_room) {
                                    flag++
                                    let max_visits = room.max_visits;      
                                    if(flag >= max_visits){   
                                        check = 1                           
                                        flag = 0                                       
                                    } else {
                                        check = 0  
                                    }                                
                                }

                            }
                        )    
                    }   

                    if(check == 1) return true
                    
                }


            },                                                      
            format_event_for_calendar(appointment) {           
                var title_therapy;
                for(const therapy of this.therapies.DB) {
                    if(therapy.id == appointment.patient_pathology.id_therapy) {
                        title_therapy = therapy.title
                    } else if(!appointment.patient_pathology.id_therapy){
                        title_therapy = 'Nessuna terapia'
                    }
                }
       
                return {
                    start: `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_start).format('HH:mm')}`,
                    end:   `${moment(appointment.date).format('YYYY-MM-DD')} ${moment(appointment.h_end).format('HH:mm')}`,
                    title:  `
                        <span
                            class= "f-size-1-em"
                            title= "${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')}\n${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name}\n Terapia: ${title_therapy}">
                            ${ moment(appointment.h_start).format('HH:mm')} - ${moment(appointment.h_end).format('HH:mm')} - ${appointment.patient_pathology.patient.surname} ${appointment.patient_pathology.patient.name} - Terapia:  ${title_therapy}
                        </span>                        
                        `,
                    class: 'color-event'
                }
            },          
            easterDateLLongre(Y) {
                let M=3, G= Y % 19+1, C= ~~(Y/100)+1, L=~~((3*C)/4)-12,
                E=(11*G+20+ ~~((8*C+5)/25)-5-L)%30, D;
                E<0 && (E+=30);
                (E==25 && G>11 || E==24) && E++;
                (D=44-E)<21 && (D+=30);
                (D+=7-(~~((5*Y)/4)-L-10+D)%7)>31 && (D-=31,M=4);

                if(D == 31){
                    D = 1;
                    M = M+1
                } else {
                    D = D+1;
                }  

                if(M < '10'){ 
                    M = '0' + M;
                } 
  
                if(D < '10'){ 
                    D = '0' + D;
                }                 
                return Y + '-' + M + '-' + D;
            }, 
            format_room_for_calendar_holidays(arrHoliday) {
                listHoliday = []
                for (let index = 0; index < arrHoliday.length; ++index) {
                    const element = Object.keys(arrHoliday[index]);
                    
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();
                    listHoliday.push({
                        [day] : {
                            date: element,                       
                            from: {'am': 8 * 60, 'pm': 13 * 60},
                            to: {'am': 13 * 60, 'pm': 20 * 60},
                            class: 'doctor-3',
                            label: 'Festivo'                               
                        }                                     
                    });                                                                   
                }  


                list2Holiday = Object.assign({}, listHoliday);  
                return list2Holiday;
                    
            },                               
           format_room_for_calendar(room) {
                arr = [];
                objroom.push(room);

                var startDate; //YYYY-MM-DD
                var endDate; //YYYY-MM-DD  

                var data;
                var from = '00:00';
                var to = '00:00';
                var frompm = '00:00';
                var topm = '00:00';               
                for (let index = 0; index < objroom.length; ++index) {
                    startDate = new Date(objroom[index].dal);
                    endDate = new Date(objroom[index].al);
                  
                    var dt = new Date(startDate);                  
                    while (dt <= endDate) {
                        let day = ('0' + dt.getDate()).slice(-2);       
                        let month = ('0' + (dt.getMonth()+1)).slice(-2);        
                        let year = dt.getFullYear();  
                        let format4 = year + "-" + month + "-" + day;

                        dt.setDate(dt.getDate() + 1);
                        var dateFormat = new Date(format4);
                        var dayweek = dateFormat.getDay();

                        if(dayweek == '1'){
                            from = objroom[index].monday_1.am.dal;
                            to = objroom[index].monday_1.am.al;
                            frompm = objroom[index].monday_1.pm.dal;
                            topm = objroom[index].monday_1.pm.al;                            
                        } 
                        if(dayweek == '2'){
                            from = objroom[index].tuesday_2.am.dal;
                            to = objroom[index].tuesday_2.am.al;       
                            frompm = objroom[index].tuesday_2.pm.dal;
                            topm = objroom[index].tuesday_2.pm.al;                                                 
                        } 
                        if(dayweek == '3'){
                            from = objroom[index].wednesday_3.am.dal;
                            to = objroom[index].wednesday_3.am.al;     
                            frompm = objroom[index].wednesday_3.pm.dal;
                            topm = objroom[index].wednesday_3.pm.al;                                                     
                        } 
                        if(dayweek == '4'){
                            from = objroom[index].thursday_4.am.dal;
                            to = objroom[index].thursday_4.am.al; 
                            frompm = objroom[index].thursday_4.pm.dal;
                            topm = objroom[index].thursday_4.pm.al;                                                            
                        } 
                        if(dayweek == '5'){
                            from = objroom[index].friday_5.am.dal;
                            to = objroom[index].friday_5.am.al;         
                            frompm = objroom[index].friday_5.pm.dal;
                            topm = objroom[index].friday_5.pm.al;                                                   
                        } 
                        if(dayweek == '6'){
                            
                            from = objroom[index].saturday_6.am.dal;
                            to = objroom[index].saturday_6.am.al;   
                            frompm = objroom[index].saturday_6.pm.dal;
                            topm = objroom[index].saturday_6.pm.al; 
                                    
                        } 
                        data = { [format4] : {am: { from: [from], to: [to]}, pm: { from: [frompm], to: [topm]}}};
                        arr.push(data);                        
                    }                                                 
                }   
                
                for (let index = 0; index < arr.length; ++index) {
                    const element = Object.keys(arr[index]);
                    const dateNew = new Date(element);
                    var day = dateNew.getDay();

                    
                    var fromMor = String(arr[index][element].am.from);                   
                    var toMor = String(arr[index][element].am.to);

                    var fromMorS = fromMor.split(':');                  
                    var fromMorH = fromMorS[0];                    
                    var fromMorM = fromMorS[1];
                    var minutesMor = 0;

                    if(fromMorM != '00'){
                        if(fromMorM >= '10' && fromMorM <= '25'){ 
                            minutesMor = '2';
                        } else if(fromMorM >= '26' && fromMorM <= '35'){
                            minutesMor = '5';
                        } else if(fromMorM >= '36' && fromMorM <= '59'){
                            minutesMor = '7';
                        }     
                    } 
                    var fromMorF = parseInt(fromMorH) + '.'+ minutesMor;

                    var toMorS = toMor.split(':');                  
                    var toMorH = toMorS[0];                    
                    var toMorM = toMorS[1];
                    var tominutesMor = 0;

                    if(toMorM != '00'){
                        if(toMorM >= '10' && toMorM <= '25'){ 
                            tominutesMor = '2';
                        } else if(toMorM >= '26' && toMorM <= '35'){
                            tominutesMor = '5';
                        } else if(toMorM >= '36' && toMorM <= '59'){
                            tominutesMor = '7';
                        }     
                    } 

                    var toMorF = parseInt(toMorH) + '.'+ tominutesMor;   

                    //afternoon
                    var fromAft = String(arr[index][element].pm.from);                   
                    var toAft = String(arr[index][element].pm.to);

                    var fromAftS = fromAft.split(':');                  
                    var fromAftH = fromAftS[0];                    
                    var fromAftM = fromAftS[1];
                    var minutesAft = 0;

                    if(fromAftM != '00'){
                        if(fromAftM >= '10' && fromAftM <= '25'){ 
                            minutesAft = '2';
                        } else if(fromAftM >= '26' && fromAftM <= '35'){
                            minutesAft = '5';
                        } else if(fromAftM >= '36' && fromAftM <= '59'){
                            minutesAft = '7';
                        }     
                    } 
                    var fromAftF = (parseInt(fromAftH) + '.'+ minutesAft);

                    

                    var toAftS = toAft.split(':');   
             
                    var toAftH = toAftS[0];                    
                    var toAftM = toAftS[1];
                    var tominutesAft = 0;

                    if(toAftM != '00'){
                        if(toAftM >= '10' && toAftM <= '25'){ 
                            tominutesAft = '2';
                        } else if(toAftM >= '26' && toAftM <= '35'){
                            tominutesAft = '5';
                        } else if(toAftM >= '36' && toAftM <= '59'){
                            tominutesAft = '7';
                        }     
                    } 

                    var toAftF = parseInt(toAftH) + '.'+ tominutesAft;   
                    

                    if(day == 0){
                        list.push({
                            [7] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    } 
                    if(day == 6){
                        list.push({
                            [6] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                              
                            }                                                                 
                        }); 
                    }                 
                                    
                    let year = this.yearCal

                    let easter = this.easterDateLLongre(year);

                    var days_holiday = [year + "-01-01", year + "-01-06", year + "-04-25", year + "-05-01", year + "-06-02", year + "-06-29", year + "-08-15", year + "-11-01", year + "-12-08", year + "-12-25", year + "-12-26", easter];
                    
                    let dayHol = ('0' + dateNew.getDate()).slice(-2);       
                    let monthHol = ('0' + (dateNew.getMonth()+1)).slice(-2);        
                    let yearHol = dateNew.getFullYear();  
                    let format4Hol = yearHol + "-" + monthHol + "-" + dayHol;

                    if(days_holiday.includes(format4Hol)){
                        list.push({
                            [day] : {
                                date: element,                       
                                from: {'am': 8 * 60, 'pm': 13 * 60},
                                to: {'am': 13 * 60, 'pm': 20 * 60},
                                class: 'doctor-3',
                                label: 'Non disponibile'                               
                            }                                     
                        }); 
                    } else {
                        if(fromMorH != '' || fromAftH != ''){               
                            list.push({
                                [day] : {
                                    date: element,                       
                                    from: {'am': fromMorF * 60, 'pm': fromAftF * 60},
                                    to: {'am': toMorF * 60, 'pm': toAftF * 60},
                                    class: 'doctor-1',
                                    label: 'Disponibile'                               
                                }                                     
                            }); 
                        } 
                    }                                        

                                                            
                }  
                
                list2 = Object.assign({}, list);  
                return list2;
                      
            },
            getEventsByRoomSelected(for_calendar) {
                
                let appointments = []
  
                this.rooms.DB.find(
                    room => {
                        
                        if(room.id == this.form.appointment.id_room) {
                            for(const appointment of room.appointments) {  
                                appointments.push(
                                    for_calendar ? this.format_event_for_calendar(appointment) : appointment
                                )

                            }
                        }

                    }
                )

                return appointments

            } ,
        },
        mounted() {
            this.readAppointment() 
            if(!this.form.appointment.isBloodTestAppointment && !this.form.appointment.isTherapyAppointment){          
                this.readRooms()
                this.readVisitTypes()
            }    
            this.time_expire()
            
            // Lettura delle terapie attive
            this.readTherapies()
            if(!this.form.appointment.isTherapyAppointment){          
                this.read_durations()  
                this.read_chairs()
            } 

            if(!this.form.appointment.isTherapyAppointment){          
                this.read_blood_test_types()      
            }  
             
        },      
        beforeDestroy() {
            //console.log(this);
            this.pathologies.DB = [];
            this.therapies.DB = [];
            this.room.DB = [];
            this.rooms.DB = [];
        },       
        computed: { 
              
            getRoomAvailibilities() {   
                               
                let year = this.yearCal

                let easter = this.easterDateLLongre(year);
                var myString = []
                var days_holiday = {
                    "0": year + "-01-01",
                    "1": year + "-01-06",
                    "2": year + "-04-25",
                    "3": year + "-05-01",
                    "4": year + "-06-02",
                    "5": year + "-06-29",
                    "6": year + "-08-15",
                    "7": year + "-11-01",
                    "8": year + "-12-08",
                    "9": year + "-12-25",
                    "10": year + "-12-26",
                    "11": easter 
                };  

                days_holiday = JSON.parse(JSON.stringify(days_holiday));
                myString = []
                availibilities = []                       
                Object.keys(days_holiday).forEach(key => {
                    days_holiday_obj = {
                        [days_holiday[key]] : {
                            'am':{'to':['20:00'],'from':['08:00']},'pm':{'to':['20:00'],'from':['08:00']}
                        }
                    } 
                    myString.push(days_holiday_obj);                        
                }); 
                availibilities.push(
                    this.format_room_for_calendar_holidays(myString)
                ) 
            
                if(this.room.DB.length !== 0){  
                    var room = JSON.parse(JSON.stringify(this.room.DB));
                    for(const roomAv of room) {   
                        //console.log('ggg ' + roomAv);                                                               
                        availibilities.push(
                            this.format_room_for_calendar(roomAv)
                        )                        
                    }     
                }

                newObj = availibilities.reduce((a, b) => Object.assign(a, b), {}) 
                return newObj;   

            },           
            therapies_by_pathology() {

                let therapies = []

                if(!this.form.appointment.isTherapyAppointment && !this.form.appointment.isBloodTestAppointment){
                    for(const patient_pathology of this.patients_pathologies) {
                        if(patient_pathology.id == this.form.appointment.id_patient_pathology) therapies = patient_pathology.pathology.pathologies_therapies
                    }
                }


                return therapies

            },
            actual_room() {
                return this.rooms.DB.find(
                    room => room.id == this.form.appointment.id_room
                )
            },        
            day_of_appointment() {
                return moment(this.form.appointment.date).format("YYYYMMDD") == moment().format("YYYYMMDD")
            },
            caregiver_phone() {

                if(this.form.user.phonecg != null){
                    return true
                } else {
                    return false
                }
 
            }, 
            caregiver_email() {

                if(this.form.user.emailcg != null){
                    return true
                } else {
                    return false
                }

            },                        
            appointment_expired() {
                return !moment(this.form.appointment.date).isSameOrAfter(moment().format("YYYY-MM-DD"))
            },
            time_difference() {

                const h_start = moment(
                    moment().format(`YYYY-MM-DD ${this.form.appointment.h_start}`)
                )
                const h_arriv = moment(
                    moment().format(`YYYY-MM-DD ${this.form.appointment.totem_presence_arrival}`)
                )
                
                const duration = (
                    moment.duration(h_arriv.diff(h_start))
                ).asMinutes()

                if(duration < 0) {
                    return `${duration*-1} minuti di anticipo`
                }

                if(duration < 60) {
                    return `${duration} minuti di ritardo`
                }

                const ore = parseInt(moment.duration(h_arriv.diff(h_start)).asHours())

                return `Oltre ${ore} ${ore < 2 ? "ora" : "ore"} di ritardo`

            },
            time_finish_duration() {

                // Test funzione annidata

                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.appointment_closed.date
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.room_presence_arrival
                                )
                            )
                        )
                    ).asMinutes()
                )

            },
            time_finish_waiting() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.room_presence_arrival
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.totem_presence_arrival_full
                                )
                            )
                        )
                    ).asMinutes()
                )

            },
            time_finish_waiting_room() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.room_presence_arrival
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.h_start_full
                                )
                            )
                        )
                    ).asMinutes()
                )
            },
            therapy_time_finish_waiting() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.appointments_day_after.room_presence_arrival
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.appointments_day_after.totem_presence_arrival
                                )
                            )
                        )
                    ).asMinutes()
                )

            },

            therapy_time_finish_waiting_room() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.appointments_day_after.room_presence_arrival
                                
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.appointments_day_after.h_start
                                )
                            )
                        )
                    ).asMinutes()
                )
            },                               
            bloodtest_time_finish_waiting() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.blood_tests.room_presence_arrival
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.blood_tests.totem_presence_arrival
                                )
                            )
                        )
                    ).asMinutes()
                )

            },

            bloodtest_time_finish_waiting_room() {

                // Test funzione annidata
                // return parse int
                return parseInt(
                    (
                        // Acquisisci valore durata in formato minuti
                        moment.duration(
                            // Moment standardizzazione di data appuntamento terminato
                            moment(
                                this.form.appointment.blood_tests.room_presence_arrival
                                
                                // Calcola la differenza
                            ).diff(
                                // Moment standardizzazione di data chiamata in stanza
                                moment(
                                    this.form.appointment.blood_tests.date + " " + this.form.appointment.blood_tests.h_start
                                    
                                )
                            )
                        )
                    ).asMinutes()
                )
            }  
        }, 
        watch: {
            form: {
                deep: true,
                handler: function (newVal) {  

                    if(newVal.appointment.appointment_day_after_date){
                        this.readChairs()
                    }

                    if(newVal.appointment.appointment_day_after_time){
                        this.readChairsTime()
                    }                    
                }
            },
            DB: {
                deep: true,
                handler: function (newVal) {  

                    if(newVal.selected_duration){
                        this.readChairsTime()
                    }
                }
            },              
            getRoomAvailibilities: {
                deep: true,
                handler: function (newVal) {            
                    if(Object.keys(newVal).length == 12){
                        this.classCal = 1
                        newVal = []
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                         
                        availibilities = [];
                        newObj = {};   
                        days_holiday_obj = {} ; 
                        listHoliday = [];
                        list2Holiday = {}; 
                        objroomRoomAvailable = [];
                        arrRoomAvailable = [];  
                        objroomMaxVisit = [];
                        arrMaxVisit = [];                                                                                          
                    } else {
                        this.classCal = 2
                        newVal = null
                        list = [];
                        list2 = {}; 
                        arr = new Array();  
                        objroom = new Array();                         
                        availibilities = [];
                        newObj = {};   
                        days_holiday_obj = {} ; 
                        listHoliday = [];
                        list2Holiday = {}; 
                        objroomRoomAvailable = [];
                        arrRoomAvailable = [];
                        objroomMaxVisit = [];
                        arrMaxVisit = [];                                              
                    }
                }
            }
        }               
    }

</script>