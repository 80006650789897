<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi un nuovo paziente</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Tramite il codice fiscale verrà effettuata automaticamente una ricerca nei sistemi <b>ASUR</b>
                        </small>
                    </p>

                    <form @submit.prevent="createPatient" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="CF">Codice fiscale</label>

                                    <input type="text" class="form-control f-family-monospace text-uppercase" id="CF" v-model.trim="$v.form.patient.CF.$model" maxlength="16" v-bind:class="{'is-invalid': (!$v.form.patient.CF.required || !$v.form.patient.CF.minLength) && $v.form.patient.CF.$dirty || validation.ASUR.ko || validation.oncoga.ko, 'is-valid': validation.ASUR.ok || validation.oncoga.ok}" @input="ricerca_oncoga" :disabled="loaders.searching.ASUR || loaders.searching.oncoga">
                                    
                                    <small class="form-text text-muted" v-show="loaders.searching.ASUR">
                                        Ricerca tramite ASUR in corso...
                                    </small>
                                    <small class="form-text text-muted" v-show="loaders.searching.oncoga">
                                        Ricerca tramite sistema Oncoga in corso...
                                    </small>
                                    <p v-if="validation.ASUR.ok" class="m-0 text-success">
                                        <small>
                                            {{validation.ASUR.ok}}
                                        </small>
                                    </p>
                                    <p v-if="validation.ASUR.ko" class="m-0 text-danger">
                                        <small>
                                            {{validation.ASUR.ko}}
                                        </small>
                                    </p>
                                    <p v-if="validation.oncoga.ok" class="m-0 text-success">
                                        <small>
                                            {{validation.oncoga.ok}}
                                        </small>
                                    </p>
                                    <p v-if="validation.oncoga.ko" class="m-0 text-danger">
                                        <small>
                                            {{validation.oncoga.ko}}
                                        </small>
                                    </p>
                                    <p v-if="!$v.form.patient.CF.required && $v.form.patient.CF.$dirty" class="m-0 text-danger">
                                        <small>Codice fiscale richiesto</small>
                                    </p>
                                    <p v-if="!$v.form.patient.CF.minLength && $v.form.patient.CF.$dirty" class="m-0 text-danger">
                                        <small>
                                            Lunghezza minima di 16 caratteri
                                        </small>
                                    </p>
                                    <p v-if="!$v.form.patient.CF.maxLength && $v.form.patient.CF.$dirty" class="m-0 text-danger">
                                        <small>
                                            Lunghezza massima di 16 caratteri
                                        </small>
                                    </p>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="name">Nome</label>

                                    <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.patient.name.$model" v-bind:class="{'is-invalid': !$v.form.patient.name.required && $v.form.patient.name.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.form.patient.name.required && $v.form.patient.name.$dirty" class="m-0 text-danger">
                                        <small>Nome richiesto</small>
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="surname">Cognome</label>

                                    <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.patient.surname.$model" v-bind:class="{'is-invalid': !$v.form.patient.surname.required && $v.form.patient.surname.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.form.patient.surname.required && $v.form.patient.surname.$dirty" class="m-0 text-danger">
                                        <small>Cognome richiesto</small>
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="date_birth">Data di nascita</label>

                                    <input type="date" class="form-control" id="date_birth" v-model.trim="$v.form.patient.date_birth.$model" v-bind:class="{'is-invalid': !$v.form.patient.date_birth.required && $v.form.patient.date_birth.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.form.patient.date_birth.required && $v.form.patient.date_birth.$dirty" class="m-0 text-danger">
                                        <small>Data di nascita richiesta</small>
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="sex">Sesso</label>

                                    <select class="form-control" id="sex" v-model.trim="$v.form.patient.sex.$model" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                        <option value="M">
                                            UOMO
                                        </option>
                                        <option value="F">
                                            DONNA
                                        </option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="address">Indirizzo</label>
                                    <input type="text" class="form-control" id="address" v-model.trim="$v.form.patient.address.$model" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                </div>
                            </div>
                        </div>

                        
                        <hr class="w-25 ml-0">
                        

                        <p class="font-weight-bold m-0">Recapiti</p>

                        <p class="text-muted mb-3">
                            <small>
                                Il numero di cellulare e l'email verranno utilizzate per l'invio di informazioni al paziente
                            </small>
                        </p>

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="phone">Numero di cellulare</label>

                                    <input type="phone" class="form-control f-family-monospace" id="phone" v-model="form.patient.phone" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="confirmPhone">Conferma numero di cellulare</label>

                                    <input type="phone" class="form-control f-family-monospace" id="confirmPhone" v-model.trim="$v.utilities.confirm.phone.$model" v-bind:class="{'is-invalid': !$v.utilities.confirm.phone.equalsPhone && $v.utilities.confirm.phone.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.utilities.confirm.phone.equalsPhone && $v.utilities.confirm.phone.$dirty" class="m-0 text-danger">
                                        <small>Numeri di cellulare differenti</small>
                                    </p>

                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="email">Indirizzo email</label>

                                    <input type="email" class="form-control text-lowercase" id="email" v-model="form.patient.email" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="confirmEmail">Conferma indirizzo email</label>

                                    <input type="email" class="form-control text-lowercase" id="confirmEmail" v-model.trim="$v.utilities.confirm.email.$model" v-bind:class="{'is-invalid': !$v.utilities.confirm.email.equalsEmail && $v.utilities.confirm.email.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.utilities.confirm.email.equalsEmail && $v.utilities.confirm.email.$dirty" class="m-0 text-danger">
                                        <small>Email differenti</small>
                                    </p>

                                </div>
                            </div>                            
                        </div>

                        <hr class="w-25 ml-0">
                        

                        <p class="font-weight-bold m-0">Caregiver</p>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group AO-type_lookup">

                                    <label for="namecg">Nome</label>

                                    <input type="text" class="form-control text-uppercase" id="namecg" v-model.trim="$v.form.patient.namecg.$model" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="surnamecg">Cognome</label>

                                    <input type="text" class="form-control text-uppercase" id="surnamecg" v-model.trim="$v.form.patient.surnamecg.$model" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                                                       
                                </div>
                            </div>                           
                        </div>
                        <p class="text-muted mb-3">
                            <small>
                                Il numero di cellulare e l'email verranno utilizzate per l'invio di informazioni al caregiver
                            </small>
                        </p>

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="phone">Numero di cellulare</label>

                                    <input type="phone" class="form-control f-family-monospace" id="phonecg" v-model="form.patient.phonecg" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="confirmPhone">Conferma numero di cellulare</label>

                                    <input type="phone" class="form-control f-family-monospace" id="confirmPhonecg" v-model.trim="$v.utilities.confirm.phonecg.$model" v-bind:class="{'is-invalid': !$v.utilities.confirm.phonecg.equalsPhone && $v.utilities.confirm.phonecg.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.utilities.confirm.phonecg.equalsPhone && $v.utilities.confirm.phonecg.$dirty" class="m-0 text-danger">
                                        <small>Numeri di cellulare differenti</small>
                                    </p>

                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="email">Indirizzo email</label>

                                    <input type="email" class="form-control text-lowercase" id="emailcg" v-model="form.patient.emailcg" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="confirmEmail">Conferma indirizzo email</label>

                                    <input type="email" class="form-control text-lowercase" id="confirmEmailcg" v-model.trim="$v.utilities.confirm.emailcg.$model" v-bind:class="{'is-invalid': !$v.utilities.confirm.emailcg.equalsEmail && $v.utilities.confirm.emailcg.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                    
                                    <p v-if="!$v.utilities.confirm.emailcg.equalsEmail && $v.utilities.confirm.emailcg.$dirty" class="m-0 text-danger">
                                        <small>Email differenti</small>
                                    </p>

                                </div>
                            </div>                            
                        </div>
                       

                        <hr class="w-25 ml-0">

                        <p class="font-weight-bold m-0">Patologia</p>

                        <p class="text-muted mb-3">
                            <small>
                                Associa una patologia al paziente
                            </small>
                        </p>

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="title_pathology_to_search">
                                        Cerca patologia
                                    </label>

                                    <input
                                        type    = "text"
                                        class   = "form-control text-uppercase"
                                        id      = "title_pathology_to_search"
                                        v-model = "form.search.pathology"
                                        @input  = "form.patient_pathology.id_pathology = null"
                                        :class  = "{
                                            'is-valid':   form.patient_pathology.id_pathology,
                                            'is-invalid': form.search.pathology && !form.patient_pathology.id_pathology
                                        }"
                                    >

                                    <div class="valid-feedback">
                                        Patologia selezionata correttamente
                                    </div>
                                    <div class="invalid-feedback">
                                        Seleziona una patologia nella tabella che segue
                                    </div>

                                    <small 
                                        class   = "form-text text-muted" 
                                        v-show  = "
                                            !form.search.pathology
                                        "
                                    >
                                        Ricerca una patologia e selezionala nella tabella che seguirà
                                    </small>

                                </div>
                            </div>
                        </div>

                        <div 
                            v-if    = "
                                form.search.pathology                &&
                                form.search.pathology.length > 0     && 
                                !form.patient_pathology.id_pathology
                            "
                            class   = "table-responsive"
                        >
                            <table class="table_AO">
                                <thead>
                                    <tr>
                                        <th>
                                            Identificativo
                                        </th>
                                        <th>
                                            Nome
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for   = "pathology of orderedPathologies"
                                        :key    = "`pathology_to_select_${pathology.id}`"
                                        @click  = "update_pathology(pathology)"
                                    >
                                        <td width="10%" class="f-family-monospace font-weight-bold">
                                            {{pathology.id_pathology}}
                                        </td>
                                        <td width="90%">
                                            {{pathology.title}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!--<div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="pathologies">Seleziona una patologia</label>

                                    <select class="form-control" id="pathologies" v-model="form.patient_pathology.id_pathology" aria-describedby="pathology_help" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">
                                        <option v-for="pathology of DB.pathologies" :key="`pathology_${pathology.id}`" :value="pathology.id">
                                            {{pathology.title}}
                                        </option>
                                    </select>

                                    <p id="pathology_help" class="form-text text-muted mb-0">
                                        <small>
                                            Seleziona una patologia da associare al paziente
                                        </small>
                                    </p>                                    

                                </div>
                            </div>
                        </div>-->

                        <hr class="w-25 ml-0">

                        <p class="font-weight-bold m-0">
                            Appuntamento
                        </p>

                        <p class="text-muted mb-3">
                            <small>
                                Imposta la durata per ogni appuntamento che verrà fissato a questo paziente con la patologia selezionata
                            </small>
                        </p>

                        <div class="row">
                            <div class="col-lg-2">

                                <div class="form-group">

                                    <label for="sessions_duration">Durata degli appuntamenti</label>
                                    <input type="number" class="form-control" id="sessions_duration" min="20" v-model.trim="$v.form.patient_pathology.sessions_duration.$model" v-bind:class="{'is-invalid': (!$v.form.patient_pathology.sessions_duration.required || !$v.form.patient_pathology.sessions_duration.minValue) && $v.form.patient_pathology.sessions_duration.$dirty}" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko">

                                    <p v-show="!$v.form.patient_pathology.sessions_duration.$dirty" class="text-muted">
                                        <small>
                                            Il valore impostato è in <b>minuti</b>
                                        </small>
                                    </p>
                                    <p class="text-danger m-0" v-show="(!$v.form.patient_pathology.sessions_duration.required || !$v.form.patient_pathology.sessions_duration.minValue) && $v.form.patient_pathology.sessions_duration.$dirty">
                                        <small>
                                            Impostare almeno 20 minuti
                                        </small>
                                    </p>
                                    <p class="text-danger m-0" v-show="!$v.form.patient_pathology.sessions_duration.required && $v.form.patient_pathology.sessions_duration.$dirty">
                                        <small>
                                            Durata dell'appuntamento richiesta
                                        </small>
                                    </p>

                                </div>

                            </div>
                        </div>

                        <hr class="w-25 ml-0">

                        <p class="font-weight-bold mb-0">Note sul paziente</p>

                        <p class="text-muted mb-3">
                            <small>
                                Aggiungi delle note e delle informazioni utili nei confronti di questo paziente
                            </small>
                        </p>

                        <div class="form-group">

                            <textarea class="form-control" id="note" rows="3" maxlength="1000" v-model.trim="$v.form.patient.note.$model" :disabled="!$v.form.patient.CF.minLength || !$v.form.patient.CF.required || validation.oncoga.ko" v-bind:class="{'is-invalid': !$v.form.patient.note.maxLength}"></textarea>

                            <div class="invalid-feedback">
                                Le note devono essere inferiori a 1000 caratteri
                            </div>

                        </div>

                        <div v-show="validation.ko" class="alert alert-danger text-center">
                            {{validation.ko}}
                        </div>

                        <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                
                            <span v-show="!loaders.creating">
                                Aggiungi
                            </span>

                            <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        minValue
    } from "vuelidate/lib/validators"

    import moment from "moment"

    import {
        orderBy,
        filter
    } from 'lodash'

    export default {
        data() {
            return {
                DB: {
                    pathologies: []
                },
                form: {
                    patient: {
                        CF:         "",
                        name:       null,
                        surname:    null,
                        date_birth: null,
                        phone:      null,
                        email:      null,
                        sex:        null,
                        address:    null,
                        note:       "",
                        namecg:     null,
                        surnamecg:  null,  
                        phonecg:    null,
                        emailcg:    null                                            
                    },
                    patient_pathology: {
                        id_pathology:      null,
                        sessions_duration: 20
                    },
                    search: {
                        pathology: null
                    }
                },
                utilities: {
                    confirm: {
                        phone: null,
                        email: null,
                        phonecg: null,
                        emailcg: null                       
                    }
                },
                validation: {
                    ko: false,
                    ASUR: {
                        ko: false,
                        ok: false
                    },
                    oncoga: {
                        ko: false,
                        ok: false
                    }
                },
                loaders: {
                    creating: false,
                    searching: {
                        ASUR:   false,
                        oncoga: false
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    patient: {
                        CF: {
                            required,
                            minLength: minLength(16),
                            maxLength: maxLength(16)
                        },
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        date_birth: {
                            required
                        },
                        phone: {
                            //required
                        },
                        email: {
                            //required
                        },
                        sex: {

                        },
                        address: {

                        },
                        note: {
                            maxLength: maxLength(1000)
                        },
                        namecg: {

                        },
                        surnamecg: {

                        },    
                        phonecg: {
      
                        },
                        emailcg: {
                 
                        }                                          
                    },
                    patient_pathology: {
                        /*sessions_quantity: {
                            required,
                            minValue: minValue(1)
                        },*/
                        sessions_duration: {
                            required,
                            minValue: minValue(20)
                        }
                    }
                },
                utilities: {
                    confirm: {
                        phone: {
                            //required,
                            equalsPhone: () => {
                                return this.form.patient.phone == this.utilities.confirm.phone
                            }
                        },
                        email: {
                            //required,
                            equalsEmail: () => {
                                return this.form.patient.email == this.utilities.confirm.email
                            }
                        },
                        phonecg: {
                            //required,
                            equalsPhone: () => {
                                return this.form.patient.phonecg == this.utilities.confirm.phonecg
                            }
                        },
                        emailcg: {
                            //required,
                            equalsEmail: () => {
                                return this.form.patient.emailcg == this.utilities.confirm.emailcg
                            }
                        }                       
                    }
                }
            }
        },
        methods: {
            async readPathologies() {

                try {

                    const pathologies = await this.axios.get(`user/pathologies/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.pathologies = pathologies.data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg + ', ' : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async createPatient() {

                this.loaders.creating = true
                this.validation.ko    = false

                try {
                    const res = await this.axios.post(`user/patients/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })
                    this.$router.push(`/user/pazienti`)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async ricerca_asur() {

                try {

                    // Attiviamo il loader
                    this.loaders.searching.ASUR = true

                    // Uppercase
                    const CF = this.form.patient.CF.toUpperCase()

                    const {
                        patient,
                        message
                    } = (
                        await this.axios.post(`user/patients/search/asur`, {CF}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    if(patient.decesso != ""){
                        const message = "Paziente deceduto"

                        this.$toast.open({
                            message,
                            type: "alert"
                        })

                        this.validation.oncoga.ko = message

                        return false
                    }

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validation.ASUR.ok = message


                    //console.log("Paziente", patient)

                    this.form.patient.name       = patient.nome
                    this.form.patient.surname    = patient.cognome
                    this.form.patient.sex        = patient.sesso
                    this.form.patient.date_birth = moment(patient.data_nascita).format("YYYY-MM-DD")
                    this.form.patient.address    = patient.indirizzo + " " + patient.civico + ", " + patient.cap + " " + patient.comune
                    this.form.patient.phone      = patient.telefono
                    this.utilities.confirm.phone = patient.telefono
                    
                    patient.note ? this.form.patient.note = patient.note : this.form.patient.note = ""

                    

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ASUR.ko = message
                }
                finally {
                    this.loaders.searching.ASUR = false
                }


            },
            async ricerca_oncoga() {

                // Azzeriamo messaggi di errore ad ogni input
                this.validation.ASUR.ok   = false
                this.validation.ASUR.ko   = false
                this.validation.oncoga.ok = false
                this.validation.oncoga.ko = false

                // Azzeriamo la form principale
                for(const key in this.form.patient) {
                    if(key != "CF") {
                        this.form.patient[key] = null
                    }
                }

                // Azzeriamo info patologia
                this.form.patient_pathology.id_pathology      = null
                this.form.patient_pathology.sessions_duration = 20

                // Azzeriamo info contatti (mod. conferma)
                this.utilities.confirm.phone = null
                this.utilities.confirm.email = null

                this.utilities.confirm.phonecg = null
                this.utilities.confirm.emailcg = null               

                // Se la lunghezza del CF è corretta
                if(this.form.patient.CF.length == 16) {

                    // Attiviamo il loader
                    this.loaders.searching.oncoga = true

                    // Uppercase
                    const CF = this.form.patient.CF.toUpperCase()

                    try {

                        const patients = await this.axios.get(`user/patients/read/detail/${CF}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        if(patients.length == 0){
                            this.ricerca_asur() 
                        } else {
                            const message = "Paziente già presente nel sistema Oncoga"

                            this.$toast.open({
                                message,
                                type: "alert"
                            })

                            this.validation.oncoga.ko = message
                        }


                        

                    }
                    catch(e) {

                        /*const message = "Paziente ancora non presente nel sistema Oncoga"

                        this.$toast.open({
                            message,
                            type: "success"
                        })

                        this.validation.oncoga.ok = message*/

                        // Effettua la ricerca ASUR
                        this.ricerca_asur()

                    }
                    finally {
                        this.loaders.searching.oncoga = false
                    }
                }
            },
            update_pathology(pathology) {

                // Prendiamo i parametri passati per valore
                const {
                    title,
                    id
                } = pathology

                // Popoliamo il campo input di ricerca
                this.form.search.pathology = title

                // Impostiamo il valore nell'oggetto del paziente da salvare
                this.form.patient_pathology.id_pathology = id

            },
        },
        mounted() {
            this.readPathologies()
        },
        computed: {
            orderedPathologies() {
                return filter(
                    orderBy(
                        this.DB.pathologies,
                        'id_pathology'
                    ),
                    o => {
                        return (
                            o.title.toUpperCase().includes(
                                this.form.search.pathology.toUpperCase()
                            )
                        )
                    }
                )
            }
        }
    }
</script>